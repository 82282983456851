import React, { useEffect, useState } from 'react';
import './session-deo-listing-style.scss';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from '@mui/material';
import moment from 'moment/moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import Popover from '@mui/material/Popover';
import SessionDeoListingHook from 'app/modules/deo-work-flow/hooks/sessionDeoListingHook';
import { useAppSelector } from 'app/config/store';

const Transition = React.forwardRef<unknown, any>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FileDashBoard = () => {
  const { states, handlers } = SessionDeoListingHook();
  const emptyRowCount = 7;
  const [visibleColumns, setVisibleColumns] = useState(8);
  const {
    deoSessionEntities,
    page,
    searchCreteria,
    rowsPerPage,
    invalidRecordCount,
    validRecordCount,
    payloadStatus,
    fileID,
    fileNAME,
    pharmacyNAME,
    pharmacyNUMBER,
    isFile,
    anchorEl,
    open,
    openSubmitFilePopup,
    deleteLoader,
  } = states;
  const {
    handleChange,
    handlePageChange,
    handleRowsChanges,
    handleSearch,
    handleDelete,
    handleFileDelete,
    handleClickOpen,
    showUploadRecordToast,
    fileSubmitRedirect,
    handleCloseSubmitPopup,
    handleClick,
    handleClosed,
    handleClose,
    handleRedirect,
  } = handlers;

  const [columnVisibility, setColumnVisibility] = useState({
    patientName: true,
    dob: true,
    patientAddress: true,
    nhsNo: true,
    medicines: true,
    primaryContact: true,
    secondaryContact: true,
  });

  const handleColumnToggle = columnName => event => {
    const isChecked = event.target.checked;
    setColumnVisibility(prevVisibility => ({
      ...prevVisibility,
      [columnName]: isChecked,
    }));
    if (!isChecked) {
      setVisibleColumns(prevVisibleColumns => prevVisibleColumns - 1);
    } else {
      setVisibleColumns(prevVisibleColumns => prevVisibleColumns + 1);
    }
  };

  const calculateColumnWidth = () => {
    const numVisibleColumns = Object.values(columnVisibility).filter(Boolean).length;
    return `${100 / numVisibleColumns}%`;
  };

  const cellStyle = {
    backgroundColor: '#F5F6FA',
    fontSize: 13,
    borderBottom: '0.1px solid #dadce1',
    fontWeight: 'bold',
    borderTop: '0.1px solid #dadce1',
    fontFamily: 'Roboto, sans-serif',
    border: 'none',
  };

  const formatDate = date => {
    if (date) {
      // Check if the saved date is in 'YYYY-MM-DD' format
      const parsedDate = moment(date, 'YYYY-MM-DD', true);
      if (parsedDate.isValid()) {
        // Format to 'DD-MM-YYYY' to match the pattern you're showing
        return parsedDate.format('DD-MM-YYYY');
      }
      // If the date format isn't recognized, fall back to the original format
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment().format('DD-MM-YYYY');
    }
  };

  return (
    <Grid lg={12} xs={12} container style={{ marginTop: '2%', justifyContent: 'center' }}>
      <Grid lg={11.5} xs={11.5} container style={{ justifyContent: 'center' }}>
        <Grid lg={12} xs={12} style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', marginBottom: 20 }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {fileID && (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                    <h1 className="greetingName">{fileNAME}</h1>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          handleClickOpen(localStorage.getItem('fileId'), true);
                        }}
                        style={{
                          backgroundColor: '',
                          height: 25,
                          width: 25,
                          // border: '1px solid lightgray',
                          padding: '10',
                          display: 'flex',
                          borderRadius: 100,
                          // justifyContent: 'space-around',
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                        }}
                      >
                        <img
                          src={'../../../../content/assets/Delete.png'} // Use the image URL from the array
                          alt="logo"
                          style={{ height: '22px', marginRight: 0, marginLeft: 0 }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <h1 className="greeting">
                    The file contains <span style={{ backgroundColor: 'yellow', borderRadius: 100 }}>{invalidRecordCount}</span> &nbsp;
                    invalid records
                  </h1>
                </>
              )}
            </div>

            <div className="buttonsdiv">
              {fileID && invalidRecordCount >= 1 && (
                <>
                  <div className="button">
                    <FormControlLabel control={<Checkbox onChange={handleChange} style={{ marginLeft: 10 }} />} label="" />
                    <h1 className="button_content_text">See Invalid Records</h1>
                  </div>
                </>
              )}
              {fileID && invalidRecordCount === 0 && (
                <>
                  <div
                    onClick={showUploadRecordToast}
                    className="buttontwo"
                    style={{
                      cursor: 'pointer',
                      color: 'white',
                      backgroundColor: `${invalidRecordCount === 0 ? '#21AD64' : 'grey'}`,
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                  >
                    <h1 className="buttontwo_content_text" style={{ color: `${invalidRecordCount === 0 ? 'white' : ''}` }}>
                      Submit File
                    </h1>
                  </div>
                </>
              )}
            </div>
          </div>
        </Grid>
        <div
          style={{
            backgroundColor: 'white',
            justifyContent: 'center',
            flex: 1,
            alignItems: 'center',
            width: '98%',
            height: '80vh',
            boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.1), 0px -3px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          <FormControl sx={{ width: '100%', height: '10%' }}>
            <OutlinedInput
              style={{ borderRadius: 50, height: '100%', width: '100%' }}
              placeholder="Search here"
              onChange={handleSearch}
              startAdornment={
                <InputAdornment position="start">
                  <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                    <img
                      src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                      alt="logo"
                      style={{ height: '15px' }}
                    />
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{ style: { fontSize: 13 } }}
              classes={{ notchedOutline: 'white-outline' }}
            />
          </FormControl>

          <TableContainer sx={{ height: '78%' }}>
            <Table stickyHeader aria-label="sticky table" style={{ height: '100%' }}>
              <TableHead sx={{ border: 'none' }}>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell sx={{ ...cellStyle, width: '8%' }}></TableCell>
                  {columnVisibility.patientName && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Patient Name</TableCell>}
                  {columnVisibility.dob && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>DOB</TableCell>}
                  {columnVisibility.patientAddress && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Patient Address</TableCell>
                  )}
                  {columnVisibility.nhsNo && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>NHS No.</TableCell>}
                  {columnVisibility.medicines && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Medicine(s) to be reviewed</TableCell>
                  )}
                  {columnVisibility.primaryContact && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Primary Contact</TableCell>
                  )}
                  {columnVisibility.secondaryContact && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Secondary Contact</TableCell>
                  )}
                  <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>
                    <MoreVertSharpIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                    />
                  </TableCell>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosed}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    disableScrollLock={true} // Add this line to prevent scroll from hiding
                  >
                    <div style={{ padding: '10px' }}>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox disabled checked={columnVisibility.patientName} onChange={handleColumnToggle('patientName')} />
                          }
                          label="Patient Name"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.dob}
                              onChange={handleColumnToggle('dob')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="DOB"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.patientAddress}
                              onChange={handleColumnToggle('patientAddress')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Patient Address"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.nhsNo} onChange={handleColumnToggle('nhsNo')} />}
                          label="NHS No."
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.medicines} onChange={handleColumnToggle('medicines')} />}
                          label="Medicine(s) to be reviewed"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.primaryContact}
                              onChange={handleColumnToggle('primaryContact')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Primary Contact"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.secondaryContact}
                              onChange={handleColumnToggle('secondaryContact')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Secondary Contact"
                        />
                      </div>
                    </div>
                  </Popover>
                </TableRow>
              </TableHead>
              <TableBody>
                {deoSessionEntities &&
                  deoSessionEntities.results &&
                  deoSessionEntities.results.length > 0 &&
                  deoSessionEntities.results.map((item, index) => (
                    <TableRow
                      key={index}
                      hover
                      role="complementary"
                      style={{ height: 50, backgroundColor: `${item && item.isValid ? '' : '#FAE1FB'}` }}
                    >
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          padding: '30px',
                        }}
                      >
                        <div style={{ width: '55px', justifyContent: 'space-between', display: 'flex', alignSelf: 'center' }}>
                          {' '}
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                handleRedirect(item.id);
                              }}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Edit.png'}
                                alt="logo"
                                style={{ height: '22px', marginRight: 0, marginLeft: 0 }}
                              />{' '}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                handleClickOpen(item.id, false);
                              }}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Delete.png'}
                                alt="logo"
                                style={{ height: '22px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                      {columnVisibility.patientName && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.firstName}
                        </TableCell>
                      )}
                      {columnVisibility.dob && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item.patient['dateofBirth'] ? formatDate(item.patient['dateofBirth']) : formatDate(moment())}
                        </TableCell>
                      )}
                      {columnVisibility.patientAddress && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.address}
                        </TableCell>
                      )}
                      {columnVisibility.nhsNo && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.nhsNumber}
                        </TableCell>
                      )}
                      {columnVisibility.medicines && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item &&
                            item.prescription &&
                            item.prescription.prescriptionMedicines &&
                            item.prescription.prescriptionMedicines.length > 0 &&
                            item.prescription.prescriptionMedicines.map(pm => pm.medicine.name).join(', ')}
                        </TableCell>
                      )}
                      {columnVisibility.primaryContact && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.telephone}
                        </TableCell>
                      )}
                      {columnVisibility.secondaryContact && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.secondary_telephone}
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {/* Content of the second TableCell */}
                      </TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: visibleColumns }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ height: '12%', fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px' }}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={deoSessionEntities ? deoSessionEntities['count'] : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsChanges}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div data-page={page} style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}>
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <div className="confirm-dialog">
            <DialogTitle className="alert-dialog-title">Delete</DialogTitle>
            <DialogContent>
              <DialogContentText className="alert-dialog-description">Are you sure you want to delete.</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button className="alert-dialog-font1" onClick={handleClose}>
                No
              </Button>
              <Button className="alert-dialog-font2" onClick={isFile ? handleFileDelete : handleDelete} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <Dialog
          open={openSubmitFilePopup}
          onClose={handleCloseSubmitPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className="confirm-dialog2">
            <DialogTitle className="alert-dialog-title">Confirm Upload</DialogTitle>
            <DialogContent>
              <DialogContentText className="alert-dialog-description">
                You have uploaded the file ({fileNAME}) that have {validRecordCount} records as per selection you are uploading data for
                pharmacy {pharmacyNAME} with GPHC number {pharmacyNUMBER}. please make sure you are uploading the correct file on the
                correct pharmacy.
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button className="alert-dialog-text1" onClick={handleCloseSubmitPopup}>
                <img src="../../../content/assets/Group236.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
                <span style={{ textTransform: 'capitalize' }}>cancel</span>
              </Button>

              <Button
                className="alert-dialog-text2"
                onClick={() => {
                  fileSubmitRedirect(fileID);
                }}
                autoFocus
              >
                <img src="../../../content/assets/circle-tick-svgrepo-com.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
                <span style={{ textTransform: 'capitalize' }}>yes, confirm</span>
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </Grid>
      {deleteLoader && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 1300,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};
export default FileDashBoard;
