import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { IRootState, useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import { isNull, isSet } from 'lodash';

export const Login = () => {
  const role =
    localStorage.getItem('role') === null || localStorage.getItem('role') === '' || localStorage.getItem('role') === undefined
      ? ''
      : JSON.parse(localStorage.getItem('role'));
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isAuthenticated, isPharmacist, loginError, showModalLogin, isDEO, isLeadDEO, isManagerDEO, isAdmin, isClient } = useAppSelector(
    state => state.authentication
  );
  const handleLogin = (username, password, rememberMe = false) => {
    dispatch(login(username, password, rememberMe));
  };

  if (isAuthenticated && !role) {
    return <Navigate to={'/role'} />;
  }

  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }
  return <LoginModal handleLogin={handleLogin} />;
};

export default Login;
