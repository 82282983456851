import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  FormControlLabel,
  Checkbox,
  Popover,
} from '@mui/material';
import RecordHook from '../hooks/recordHook';
import { DateFilter, SelectFilter, ActionsFilter } from '../filter/filter';
import moment from 'moment';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { useAppSelector } from 'app/config/store';
import Paper from '@mui/material/Paper';

export const Record = props => {
  const { states, handlers } = RecordHook();
  const loadingDownload = useAppSelector(state => state.deo.loadingDeletedRecordDownload);
  const [visibleColumns, setVisibleColumns] = useState(9);
  const { searchCreteria, loading, emptyRowCount, deoRecordSessions, anchorEl, open, selectedPharmacies } = states;
  const {
    handleRowsChange,
    handlePageChange,
    handleDelete,
    handleClosed,
    handleClick,
    handleClose,
    handleClickOpen,
    handleSearchChange,
    handleSelectPharmacy,
    handleSelectBranch,
    handleSelectService,
    handleSelectStatus,
    handleSelectMonth,
    handledateFrom,
    handledateTill,
    getDownloadData,
    handleRestoreDeletedRecords,
  } = handlers;

  const [columnVisibility, setColumnVisibility] = useState({
    id: true,
    patientName: true,
    dob: true,
    phone: true,
    patientAddress: true,
    medicines: true,
    dateofEngagement: true,
    pharmacy: true,
    status: true,
  });

  const handleColumnToggle = columnName => event => {
    setColumnVisibility({ ...columnVisibility, [columnName]: event.target.checked });

    if (!event.target.checked) {
      setVisibleColumns(prevVisibleColumns => prevVisibleColumns - 1);
    } else {
      setVisibleColumns(prevVisibleColumns => prevVisibleColumns + 1);
    }
  };

  const calculateColumnWidth = () => {
    const numVisibleColumns = Object.values(columnVisibility).filter(Boolean).length;
    return `${100 / numVisibleColumns}%`;
  };

  const cellStyle = {
    backgroundColor: '#F5F6FA',
    fontSize: 13,
    fontWeight: 'bold',
    fontFamily: 'Roboto, sans-serif',
    border: 'none',
    borderTop: '0.1px solid #dadce1',
    borderBottom: '0.1px solid #dadce1',
  };

  const RecordActions = (
    item: { pharmacyFileMetaData: { deleted_at: any }; deleted_at: any; id: any },
    handleRestoreDeletedRecord: {
      (id: any): Promise<void>;
      (arg0: any): any;
    },
    handleClickOpenPop: { (id: any): void; (arg0: any): void }
  ) => {
    const canRestore =
      item?.pharmacyFileMetaData === null || (item?.pharmacyFileMetaData?.deleted_at === null && item?.deleted_at !== null);
    const restoreTooltip =
      item?.pharmacyFileMetaData === null
        ? 'Restore'
        : item?.pharmacyFileMetaData?.deleted_at === null && item?.deleted_at !== null
        ? 'Restore'
        : 'This record belongs to the file. Please restore the file.';

    return (
      <div style={{ justifyContent: 'space-between', display: 'flex', alignSelf: 'center', width: '53px' }}>
        <Tooltip title={restoreTooltip}>
          <IconButton
            onClick={() => canRestore && handleRestoreDeletedRecord(item.id)}
            style={{
              backgroundColor: '',
              marginLeft: 0,
              height: 25,
              width: 25,
              padding: '10px',
              display: 'flex',
              borderRadius: 100,
              cursor: canRestore ? 'pointer' : 'not-allowed',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/restore.png'} alt="restore" style={{ height: '22px', marginRight: 0, marginLeft: 0 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => handleClickOpenPop(item.id)}
            style={{
              backgroundColor: '',
              marginLeft: 0,
              height: 25,
              width: 25,
              padding: '10px',
              display: 'flex',
              borderRadius: 100,
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/Delete.png'} alt="delete" style={{ height: '22px', marginRight: 0, marginLeft: 0 }} />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  return (
    <Grid
      lg={9.5}
      xs={8}
      container
      style={{ backgroundColor: 'transparent', marginTop: 20, marginLeft: 20, maxHeight: '100%', marginBottom: 20 }}
    >
      <Grid lg={11.8} xs={12} style={{ display: 'flex', marginTop: '2%', marginBottom: '1%', backgroundColor: '' }}>
        <SelectFilter
          handleSelectPharmacy={handleSelectPharmacy}
          handleSelectBranch={handleSelectBranch}
          handleSelectService={handleSelectService}
          handleSelectStatus={handleSelectStatus}
          handleSelectMonth={handleSelectMonth}
          searchCreteria={searchCreteria}
          selectedPharmacies={selectedPharmacies}
          isOverview={true}
        />
        <DateFilter handledateFrom={handledateFrom} handledateTill={handledateTill} searchCreteria={searchCreteria} />

        <ActionsFilter getDownloadData={getDownloadData} loadingDownload={loadingDownload} />
      </Grid>

      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>
        <Paper sx={{ width: '100%', paddingTop: '15px', border: 'none' }}>
          <TableContainer sx={{ height: '54vh', border: 'none' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
              <TableHead>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell sx={{ width: '8%', ...cellStyle }}></TableCell>
                  {columnVisibility.id && <TableCell sx={{ width: '8%', ...cellStyle }}>ID</TableCell>}
                  {columnVisibility.patientName && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Patient Name</TableCell>}
                  {columnVisibility.dob && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Date of Birth</TableCell>}
                  {columnVisibility.phone && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Phone</TableCell>}
                  {columnVisibility.patientAddress && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Address</TableCell>}
                  {columnVisibility.medicines && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Medicine(s) to be reviewed</TableCell>
                  )}
                  {columnVisibility.dateofEngagement && (
                    <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Date of Engagement</TableCell>
                  )}
                  {columnVisibility.pharmacy && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Store</TableCell>}
                  {columnVisibility.status && <TableCell sx={{ width: calculateColumnWidth(), ...cellStyle }}>Status</TableCell>}
                  <TableCell sx={{ ...cellStyle, width: calculateColumnWidth() }}>
                    <MoreVertSharpIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={handleClick}
                    />
                  </TableCell>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClosed}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    disableScrollLock={true} // Add this line to prevent scroll from hiding
                  >
                    <div style={{ padding: '10px' }}>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.id} onChange={handleColumnToggle('id')} />}
                          label="ID"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox disabled checked={columnVisibility.patientName} onChange={handleColumnToggle('patientName')} />
                          }
                          label="Patient Name"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.dob}
                              onChange={handleColumnToggle('dob')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Date of Birth"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.phone}
                              onChange={handleColumnToggle('phone')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Phone"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={columnVisibility.patientAddress}
                              onChange={handleColumnToggle('patientAddress')}
                              sx={{
                                '&.MuiCheckbox-root.Mui-checked': {
                                  color: '#21AD64',
                                },
                              }}
                            />
                          }
                          label="Patient Address"
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.medicines} onChange={handleColumnToggle('medicines')} />}
                          label="Medicines(s) to be reviewed"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              disabled
                              checked={columnVisibility.dateofEngagement}
                              onChange={handleColumnToggle('dateofEngagement')}
                            />
                          }
                          label="Date of Engagement"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.pharmacy} onChange={handleColumnToggle('pharmacy')} />}
                          label="Store"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                      <div>
                        <FormControlLabel
                          control={<Checkbox disabled checked={columnVisibility.status} onChange={handleColumnToggle('status')} />}
                          label="Status"
                          style={{ cursor: 'not-allowed' }}
                        />
                      </div>
                    </div>
                  </Popover>
                </TableRow>
              </TableHead>
              <TableBody>
                {deoRecordSessions &&
                  deoRecordSessions?.results &&
                  deoRecordSessions.results.map((item, index) => (
                    <TableRow key={index} hover role="complementary" style={{ height: 50 }}>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {RecordActions(item, handleRestoreDeletedRecords, handleClickOpen)}
                        {/*<div style={{ justifyContent: 'space-between', display: 'flex', alignSelf: 'center', width: '53px' }}>*/}
                        {/*  {' '}*/}
                        {/*  <Tooltip*/}
                        {/*    title={*/}
                        {/*      item?.pharmacyFileMetaData === null ?  'Restore' : (item?.pharmacyFileMetaData?.delated_at === null && item?.deleted_at !== null) ?  'Restore' : 'This record belongs to the file. Please restore the file.'*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    {item?.pharmacyFileMetaData === null ? (*/}
                        {/*      <IconButton*/}
                        {/*        onClick={() => handleRestoreDeletedRecords(item.id)}*/}
                        {/*        style={{*/}
                        {/*          backgroundColor: '',*/}
                        {/*          marginLeft: 0,*/}
                        {/*          height: 25,*/}
                        {/*          width: 25,*/}
                        {/*          padding: '10px',*/}
                        {/*          display: 'flex',*/}
                        {/*          borderRadius: 100,*/}
                        {/*          cursor: 'pointer',*/}
                        {/*          transition: 'background-color 0.3s',*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <img*/}
                        {/*          src={'../../../../content/assets/restore.png'}*/}
                        {/*          alt="logo"*/}
                        {/*          style={{ height: '22px', marginRight: 0, marginLeft: 0 }}*/}
                        {/*        />*/}
                        {/*      </IconButton>*/}
                        {/*    )  : (item?.pharmacyFileMetaData?.delated_at === null && item?.deleted_at !== null) ? (*/}
                        {/*      <IconButton*/}
                        {/*      onClick={() => handleRestoreDeletedRecords(item.id)}*/}
                        {/*      style={{*/}
                        {/*        backgroundColor: '',*/}
                        {/*        marginLeft: 0,*/}
                        {/*        height: 25,*/}
                        {/*        width: 25,*/}
                        {/*        padding: '10px',*/}
                        {/*        display: 'flex',*/}
                        {/*        borderRadius: 100,*/}
                        {/*        cursor: 'pointer',*/}
                        {/*        transition: 'background-color 0.3s',*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      <img*/}
                        {/*        src={'../../../../content/assets/restore.png'}*/}
                        {/*        alt="logo"*/}
                        {/*        style={{ height: '22px', marginRight: 0, marginLeft: 0 }}*/}
                        {/*      />*/}
                        {/*    </IconButton>*/}
                        {/*    ) :  (*/}
                        {/*      <IconButton*/}
                        {/*        style={{*/}
                        {/*          backgroundColor: '',*/}
                        {/*          marginLeft: 0,*/}
                        {/*          height: 25,*/}
                        {/*          width: 25,*/}
                        {/*          padding: '10px',*/}
                        {/*          display: 'flex',*/}
                        {/*          borderRadius: 100,*/}
                        {/*          cursor: 'pointer',*/}
                        {/*          transition: 'background-color 0.3s',*/}
                        {/*        }}*/}
                        {/*      >*/}
                        {/*        <img*/}
                        {/*          src={'../../../../content/assets/restore.png'}*/}
                        {/*          alt="logo"*/}
                        {/*          style={{ height: '22px', marginRight: 0, marginLeft: 0 }}*/}
                        {/*        />*/}
                        {/*      </IconButton>*/}
                        {/*    )}*/}
                        {/*  </Tooltip>*/}
                        {/*  <Tooltip title="Delete">*/}
                        {/*    <IconButton*/}
                        {/*      onClick={() => {*/}
                        {/*        handleClickOpen(item.id);*/}
                        {/*      }}*/}
                        {/*      style={{*/}
                        {/*        backgroundColor: '',*/}
                        {/*        marginLeft: 0,*/}
                        {/*        height: 25,*/}
                        {/*        width: 25,*/}
                        {/*        padding: '10px',*/}
                        {/*        display: 'flex',*/}
                        {/*        borderRadius: 100,*/}
                        {/*        cursor: 'pointer',*/}
                        {/*        transition: 'background-color 0.3s',*/}
                        {/*      }}*/}
                        {/*    >*/}
                        {/*      <img*/}
                        {/*        src={'../../../../content/assets/Delete.png'}*/}
                        {/*        alt="logo"*/}
                        {/*        style={{ height: '22px', marginRight: 0, marginLeft: 0 }}*/}
                        {/*      />*/}
                        {/*    </IconButton>*/}
                        {/*  </Tooltip>*/}
                        {/*</div>*/}
                      </TableCell>
                      {columnVisibility.id && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {Number(index) + Number(1)}
                        </TableCell>
                      )}
                      {columnVisibility.patientName && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item?.patient?.firstName}
                        </TableCell>
                      )}
                      {columnVisibility.dob && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item.patient['dateofBirth']
                            ? moment(item.patient['dateofBirth']).format('DD-MM-YYYY')
                            : moment().format('DD-MM-YYYY')}
                        </TableCell>
                      )}
                      {columnVisibility.phone && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item?.patient?.telephone}
                        </TableCell>
                      )}
                      {columnVisibility.patientAddress && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.patient && item.patient.address}
                        </TableCell>
                      )}
                      {columnVisibility.medicines && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item && item.prescription && item?.prescription?.prescriptionMedicines.map(pm => pm.medicine.name).join(', ')}
                        </TableCell>
                      )}
                      {columnVisibility.dateofEngagement && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {moment(item?.nextSession).format('DD-MM-YYYY') || moment().format('DD-MM-YYYY')}
                        </TableCell>
                      )}
                      {columnVisibility.pharmacy && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item?.pharmacy?.name}
                        </TableCell>
                      )}
                      {columnVisibility.status && (
                        <TableCell
                          style={{
                            backgroundColor: '',
                            color: '',
                            fontSize: 12,
                            fontFamily: 'Roboto, sans-serif',
                          }}
                        >
                          {item?.sessionStatus?.name === 'completed' ? (
                            <span style={{ color: 'rgb(33, 173, 100)' }}>Completed</span>
                          ) : item?.sessionStatus?.name === 'not_reacheable' ? (
                            <span style={{ color: '#FFCC60' }}>No Answered</span>
                          ) : item?.sessionStatus?.name === 'no_consent' ? (
                            <span style={{ color: 'rgb(212,52,52)' }}>No Consent</span>
                          ) : item?.sessionStatus?.name === 'in_eligible' ? (
                            <span style={{ color: '' }}>Ineligible Condition</span>
                          ) : item?.sessionStatus?.name === 'incorrect_number' ? (
                            <span style={{ color: 'rgb(212,52,52)' }}>Incorrect Number</span>
                          ) : item?.sessionStatus?.name === 'patient_left' ? (
                            <span style={{ color: '' }}>Patient Left Pharmacy</span>
                          ) : item?.sessionStatus?.name === 'intervention_not_delivered' ? (
                            <span style={{ color: '' }}>Intervention Not Delivered</span>
                          ) : item?.sessionStatus?.name === 'in_progress' && item?.prevSessionType.name === 'intervention' ? (
                            <span style={{ color: '' }}>Pending Intervention</span>
                          ) : item?.sessionStatus?.name === 'in_progress' && item?.prevSessionType.name === 'follow_up' ? (
                            <span style={{ color: '' }}>Pending Follow-ups</span>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      )}
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: visibleColumns }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px', height: '12%' }}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={deoRecordSessions ? deoRecordSessions['count'] : 0}
            rowsPerPage={deoRecordSessions?.pageRequest?.size}
            page={deoRecordSessions?.pageRequest?.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsChange}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div
                data-page={deoRecordSessions?.pageRequest?.page}
                style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}
              >
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </Paper>
      </Grid>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Lighter color for backdrop
            backdropFilter: 'blur(0.5px)', // Apply the blur effect
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
            border: 'none', // Remove the border
            borderRadius: 8, // Optionally, adjust the border radius
          },
        }}
      >
        <div
          style={{
            width: '450px',
            height: '240px',
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
            padding: '30px',
          }}
        >
          <DialogTitle style={{ color: '#3c6498', fontWeight: 600, display: 'flex', justifyContent: 'center' }}>Confirm Delete</DialogTitle>{' '}
          <DialogContent>
            <DialogContentText style={{ textAlign: 'center', color: '#393939' }}>Are you sure you want to delete?</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={handleClose}
              style={{
                color: '#000',
                backgroundColor: '#f4f4f4',
                width: '170px',
                height: '40px',
                border: '1px solid #d8d5d5',
                cursor: 'pointer',
              }}
            >
              <img src="../../../content/assets/Group236.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
              <span style={{ textTransform: 'capitalize' }}>cancel</span>
            </Button>

            <Button
              onClick={handleDelete}
              style={{
                color: '#FFF',
                backgroundColor: '#D43434',
                width: '170px',
                cursor: 'pointer',
                height: '40px',
                border: '1px solid #c13232',
              }}
              autoFocus
            >
              <img src="../../../content/assets/circle-tick-svgrepo-com.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
              <span style={{ textTransform: 'capitalize' }}>yes, delete</span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 1300,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};
export default Record;
