import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IService } from 'app/shared/model/service.model';
import { getServiceEntities as getServices } from 'app/entities/service/service.reducer';
import { ISessionConsultationMethod } from 'app/shared/model/session-consultation-method.model';
import { getEntities as getSessionConsultationMethods } from 'app/entities/session-consultation-method/session-consultation-method.reducer';
import { ISessionStatus } from 'app/shared/model/session-status.model';
import { getEntities as getSessionStatuses } from 'app/entities/session-status/session-status.reducer';
import { ISessionType } from 'app/shared/model/session-type.model';
import { getEntities as getSessionTypes } from 'app/entities/session-type/session-type.reducer';
import { IPharmacy } from 'app/shared/model/pharmacy.model';
import { getEntities as getPharmacies } from 'app/entities/pharmacy/pharmacy.reducer';
import { IGP } from 'app/shared/model/gp.model';
import { getEntities as getGPs } from 'app/entities/gp/gp.reducer';
import { IPrescription } from 'app/shared/model/prescription.model';
import { getEntities as getPrescriptions } from 'app/entities/prescription/prescription.reducer';
import { ISessionPreviouslyDeliveredNoType } from 'app/shared/model/session-previously-delivered-no-type.model';
import { getEntities as getSessionPreviouslyDeliveredNoTypes } from 'app/entities/session-previously-delivered-no-type/session-previously-delivered-no-type.reducer';
import { ISessionMedicineUsedAsPrescribedNoType } from 'app/shared/model/session-medicine-used-as-prescribed-no-type.model';
import { getEntities as getSessionMedicineUsedAsPrescribedNoTypes } from 'app/entities/session-medicine-used-as-prescribed-no-type/session-medicine-used-as-prescribed-no-type.reducer';
import { IPatient } from 'app/shared/model/patient.model';
import { getEntities as getPatients } from 'app/entities/patient/patient.reducer';
import { ISession } from 'app/shared/model/session.model';
import { getEntity, updateEntity, createEntity, reset } from './session.reducer';

export const SessionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const services = useAppSelector(state => state.service.entities);
  const sessionConsultationMethods = useAppSelector(state => state.sessionConsultationMethod.entities);
  const sessionStatuses = useAppSelector(state => state.sessionStatus.entities);
  const sessionTypes = useAppSelector(state => state.sessionType.entities);
  const pharmacies = useAppSelector(state => state.pharmacy.entities);
  const gPS = useAppSelector(state => state.gP.entities);
  const prescriptions = useAppSelector(state => state.prescription.entities);
  const sessionPreviouslyDeliveredNoTypes = useAppSelector(state => state.sessionPreviouslyDeliveredNoType.entities);
  const sessionMedicineUsedAsPrescribedNoTypes = useAppSelector(state => state.sessionMedicineUsedAsPrescribedNoType.entities);
  const patients = useAppSelector(state => state.patient.entities);
  const sessionEntity = useAppSelector(state => state.session.entity);
  const loading = useAppSelector(state => state.session.loading);
  const updating = useAppSelector(state => state.session.updating);
  const updateSuccess = useAppSelector(state => state.session.updateSuccess);

  const handleClose = () => {
    navigate('/session');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getServices({}));
    dispatch(getSessionConsultationMethods({}));
    dispatch(getSessionStatuses({}));
    dispatch(getSessionTypes({}));
    dispatch(getPharmacies({}));
    dispatch(getGPs({}));
    dispatch(getPrescriptions({}));
    dispatch(getSessionPreviouslyDeliveredNoTypes({}));
    dispatch(getSessionMedicineUsedAsPrescribedNoTypes({}));
    dispatch(getPatients({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...sessionEntity,
      ...values,
      service: services.find(it => it.id.toString() === values.service.toString()),
      sessionConsultationMethod: sessionConsultationMethods.find(it => it.id.toString() === values.sessionConsultationMethod.toString()),
      sessionStatus: sessionStatuses.find(it => it.id.toString() === values.sessionStatus.toString()),
      sessionType: sessionTypes.find(it => it.id.toString() === values.sessionType.toString()),
      pharmacy: pharmacies.find(it => it.id.toString() === values.pharmacy.toString()),
      gP: gPS.find(it => it.id.toString() === values.gP.toString()),
      prescription: prescriptions.find(it => it.id.toString() === values.prescription.toString()),
      sessionPreviouslyDeliveredNoType: sessionPreviouslyDeliveredNoTypes.find(
        it => it.id.toString() === values.sessionPreviouslyDeliveredNoType.toString()
      ),
      sessionMedicineUsedAsPrescribedNoType: sessionMedicineUsedAsPrescribedNoTypes.find(
        it => it.id.toString() === values.sessionMedicineUsedAsPrescribedNoType.toString()
      ),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...sessionEntity,
          service: sessionEntity?.service?.id,
          sessionConsultationMethod: sessionEntity?.sessionConsultationMethod?.id,
          sessionStatus: sessionEntity?.sessionStatus?.id,
          sessionType: sessionEntity?.sessionType?.id,
          pharmacy: sessionEntity?.pharmacy?.id,
          gP: sessionEntity?.gP?.id,
          prescription: sessionEntity?.prescription?.id,
          sessionPreviouslyDeliveredNoType: sessionEntity?.sessionPreviouslyDeliveredNoType?.id,
          sessionMedicineUsedAsPrescribedNoType: sessionEntity?.sessionMedicineUsedAsPrescribedNoType?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="pharmacySolutionsApp.session.home.createOrEditLabel" data-cy="SessionCreateUpdateHeading">
            <Translate contentKey="pharmacySolutionsApp.session.home.createOrEditLabel">Create or edit a Session</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="session-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('pharmacySolutionsApp.session.consent')}
                id="session-consent"
                name="consent"
                data-cy="consent"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('pharmacySolutionsApp.session.previouslyDelivered')}
                id="session-previouslyDelivered"
                name="previouslyDelivered"
                data-cy="previouslyDelivered"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('pharmacySolutionsApp.session.medicineUsedAsPrescribed')}
                id="session-medicineUsedAsPrescribed"
                name="medicineUsedAsPrescribed"
                data-cy="medicineUsedAsPrescribed"
                check
                type="checkbox"
              />
              <ValidatedField
                id="session-service"
                name="service"
                data-cy="service"
                label={translate('pharmacySolutionsApp.session.service')}
                type="select"
              >
                <option value="" key="0" />
                {services
                  ? services.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-sessionConsultationMethod"
                name="sessionConsultationMethod"
                data-cy="sessionConsultationMethod"
                label={translate('pharmacySolutionsApp.session.sessionConsultationMethod')}
                type="select"
              >
                <option value="" key="0" />
                {sessionConsultationMethods
                  ? sessionConsultationMethods.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-sessionStatus"
                name="sessionStatus"
                data-cy="sessionStatus"
                label={translate('pharmacySolutionsApp.session.sessionStatus')}
                type="select"
              >
                <option value="" key="0" />
                {sessionStatuses
                  ? sessionStatuses.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-sessionType"
                name="sessionType"
                data-cy="sessionType"
                label={translate('pharmacySolutionsApp.session.sessionType')}
                type="select"
              >
                <option value="" key="0" />
                {sessionTypes
                  ? sessionTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-pharmacy"
                name="pharmacy"
                data-cy="pharmacy"
                label={translate('pharmacySolutionsApp.session.pharmacy')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacies
                  ? pharmacies.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="session-gP" name="gP" data-cy="gP" label={translate('pharmacySolutionsApp.session.gP')} type="select">
                <option value="" key="0" />
                {gPS
                  ? gPS.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-prescription"
                name="prescription"
                data-cy="prescription"
                label={translate('pharmacySolutionsApp.session.prescription')}
                type="select"
              >
                <option value="" key="0" />
                {prescriptions
                  ? prescriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-sessionPreviouslyDeliveredNoType"
                name="sessionPreviouslyDeliveredNoType"
                data-cy="sessionPreviouslyDeliveredNoType"
                label={translate('pharmacySolutionsApp.session.sessionPreviouslyDeliveredNoType')}
                type="select"
              >
                <option value="" key="0" />
                {sessionPreviouslyDeliveredNoTypes
                  ? sessionPreviouslyDeliveredNoTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-sessionMedicineUsedAsPrescribedNoType"
                name="sessionMedicineUsedAsPrescribedNoType"
                data-cy="sessionMedicineUsedAsPrescribedNoType"
                label={translate('pharmacySolutionsApp.session.sessionMedicineUsedAsPrescribedNoType')}
                type="select"
              >
                <option value="" key="0" />
                {sessionMedicineUsedAsPrescribedNoTypes
                  ? sessionMedicineUsedAsPrescribedNoTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/session" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SessionUpdate;
