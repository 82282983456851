import { IService } from 'app/shared/model/service.model';
import { ISessionConsultationMethod } from 'app/shared/model/session-consultation-method.model';
import { ISessionStatus } from 'app/shared/model/session-status.model';
import { ISessionType } from 'app/shared/model/session-type.model';
import { IPharmacy } from 'app/shared/model/pharmacy.model';
import { IGP } from 'app/shared/model/gp.model';
import { IPrescription } from 'app/shared/model/prescription.model';
import { ISessionPreviouslyDeliveredNoType } from 'app/shared/model/session-previously-delivered-no-type.model';
import { ISessionMedicineUsedAsPrescribedNoType } from 'app/shared/model/session-medicine-used-as-prescribed-no-type.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ISessionPatientReportedIssue } from 'app/shared/model/session-patient-reported-issue.model';
import { ISessionPatientProvidedInformation } from 'app/shared/model/session-patient-provided-information.model';
import { ISessionAdviceToPatient } from 'app/shared/model/session-advice-to-patient.model';
import { ISessionPatientAgreedAction } from 'app/shared/model/session-patient-agreed-action.model';
import { ISessionPharmacistAgreedAction } from 'app/shared/model/session-pharmacist-agreed-action.model';
import { ISessionHealthyLivingAdvice } from 'app/shared/model/session-healthy-living-advice.model';

export interface ISession {
  id?: number;
  consent?: boolean;
  previouslyDelivered?: boolean | null;
  medicineUsedAsPrescribed?: boolean | null;
  service?: IService | null;
  sessionConsultationMethod?: ISessionConsultationMethod | null;
  sessionStatus?: ISessionStatus | null;
  sessionType?: ISessionType | null;
  prevSessionType?: ISessionType | null;
  pharmacy?: IPharmacy | null;
  gP?: IGP | null;
  prescription?: IPrescription | null;
  sessionPreviouslyDeliveredNoType?: ISessionPreviouslyDeliveredNoType | null;
  sessionMedicineUsedAsPrescribedNoType?: ISessionMedicineUsedAsPrescribedNoType | null;
  patient?: IPatient | null;
  sessionPatientReportedIssues?: ISessionPatientReportedIssue[] | null;
  sessionPatientProvidedInformations?: ISessionPatientProvidedInformation[] | null;
  sessionAdviceToPatients?: ISessionAdviceToPatient[] | null;
  sessionPatientAgreedActions?: ISessionPatientAgreedAction[] | null;
  sessionPharmacistAgreedActions?: ISessionPharmacistAgreedAction[] | null;
  sessionHealthyLivingAdvices?: ISessionHealthyLivingAdvice[] | null;
}

export const defaultValue: Readonly<ISession> = {
  consent: false,
  previouslyDelivered: false,
  medicineUsedAsPrescribed: false,
};
