import { ISession } from 'app/shared/model/session.model';
import { IPrescription } from 'app/shared/model/prescription.model';

export interface IPatient {
  id?: number;
  firstName?: string;
  lastName?: string;
  patientGender?: string;
  nhsNumber?: string;
  postCode?: string;
  telephone?: string;
  address?: string;
  session?: ISession | null;
  prescription?: IPrescription | null;
}

export const defaultValue: Readonly<IPatient> = {};
