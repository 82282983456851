import { FormControl, FormHelperText, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import { Formik, Form } from 'formik';
import './editPharmacy.scss';
import EditPharmacyHook from '../hooks/editPharmacyHook';
import { useNavigate } from 'react-router-dom';

export const EditPharmacy = () => {
  const { states, handlers } = EditPharmacyHook();
  const navigate = useNavigate();
  const { pharmacyDetails, initialStateServicePharmacistform, validationSchemaServicePharmacist } = states;

  const { updatePharmacySubmit } = handlers;
  const onSubmit = useCallback(params => {
    const data = {
      ...params,
    };
    updatePharmacySubmit(data);
  }, []);
  const handleRedirect = () => {
    navigate('/operator/dashboard', { state: { activeItem: 'ManagePharmacy' } });
  };
  return (
    <Grid lg={12} container style={{ marginTop: '6%', justifyContent: 'center', alignSelf: 'center', marginBottom: '2%' }}>
      <Grid lg={12} container sx={{ backgroundColor: '', justifyContent: 'center', marginTop: '3%', height: '100%', width: '100%' }}>
        <Grid
          lg={6}
          container
          style={{ backgroundColor: 'white', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        >
          <Formik
            enableReinitialize
            initialValues={initialStateServicePharmacistform}
            validationSchema={validationSchemaServicePharmacist}
            onSubmit={onSubmit}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => {
              return (
                <Form onSubmit={handleSubmit} noValidate>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '8%' }}>
                    <FormControl style={{ marginTop: '3%', marginBottom: '2%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Pharmacy Name"
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '16px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        error={touched.name && Boolean(errors.name)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.name && <FormHelperText error={true}>{(errors as any).name}</FormHelperText>}
                    </FormControl>
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FormControl style={{ marginTop: '1%', marginBottom: '2%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Contact No."
                        value={values.contact_number}
                        name="contact_number"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/phone.png'} alt="logo" style={{ height: '16px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        error={touched.contact_number && Boolean(errors.contact_number)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.contact_number && <FormHelperText error={true}>{(errors as any).contact_number}</FormHelperText>}
                    </FormControl>

                    <FormControl style={{ marginTop: '1%', marginBottom: '2%', marginLeft: '2%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="GHPC No."
                        value={values.gphc_umber}
                        name="gphc_umber"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/hash.png'} alt="logo" style={{ height: '16px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        error={touched.gphc_umber && Boolean(errors.gphc_umber)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.gphc_umber && <FormHelperText error={true}>{(errors as any).gphc_umber}</FormHelperText>}
                    </FormControl>
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FormControl style={{ marginTop: '1%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Manager Name"
                        value={values.manager_name}
                        name="manager_name"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/profile.png'} alt="logo" style={{ height: '16px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        error={touched.manager_name && Boolean(errors.manager_name)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.manager_name && <FormHelperText error={true}>{(errors as any).manager_name}</FormHelperText>}
                    </FormControl>

                    <FormControl style={{ marginTop: '1%', marginLeft: '2%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Pharmacy Address"
                        value={values.address}
                        name="address"
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton color="inherit" style={{ height: 20 }}>
                              <img src={'../../../../content/assets/location.png'} alt="logo" style={{ height: '16px' }} />
                            </IconButton>
                          </InputAdornment>
                        }
                        error={touched.address && Boolean(errors.address)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.address && <FormHelperText error={true}>{(errors as any).address}</FormHelperText>}
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FormControl style={{ marginTop: '1%', width: '50%' }}>
                      <OutlinedInput
                        type={'number'}
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Intervention Start After"
                        value={values.rule}
                        name="rule"
                        onChange={handleChange}
                        error={touched.rule && Boolean(errors.rule)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.rule && <FormHelperText error={true}>{(errors as any).rule}</FormHelperText>}
                    </FormControl>

                    <FormControl style={{ marginTop: '1%', marginLeft: '2%', width: '50%' }}>
                      <OutlinedInput
                        type={'number'}
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Pharmacy target"
                        value={values.target}
                        name="target"
                        onChange={handleChange}
                        error={touched.target && Boolean(errors.target)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.target && <FormHelperText error={true}>{(errors as any).target}</FormHelperText>}
                    </FormControl>
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FormControl style={{ marginTop: '1%', width: '100%' }}>
                      <OutlinedInput
                        type={'number'}
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Cut of Date"
                        value={values.cut_of_date}
                        name="cut_of_date"
                        onChange={handleChange}
                        error={touched.cut_of_date && Boolean(errors.cut_of_date)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.cut_of_date && <FormHelperText error={true}>{(errors as any).cut_of_date}</FormHelperText>}
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FormControl style={{ marginTop: '1%', width: '100%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="NHS Email"
                        value={values.nhsEmail}
                        name="nhsEmail"
                        onChange={handleChange}
                        error={touched.nhsEmail && Boolean(errors.nhsEmail)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.nhsEmail && <FormHelperText error={true}>{(errors as any).nhsEmail}</FormHelperText>}
                    </FormControl>

                    <FormControl style={{ marginTop: '1%', width: '100%', marginLeft: '2%' }}>
                      <OutlinedInput
                        style={{ width: '100%' }}
                        className="outline"
                        placeholder="Business Email"
                        value={values.businessEmail}
                        name="businessEmail"
                        onChange={handleChange}
                        error={touched.businessEmail && Boolean(errors.businessEmail)}
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }}
                      />
                      {touched.businessEmail && <FormHelperText error={true}>{(errors as any).businessEmail}</FormHelperText>}
                    </FormControl>
                  </div>

                  <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <FormControl style={{ marginTop: '2%', marginBottom: '2%', width: '100%', marginRight: '2%' }}>
                      <TextField
                        type="time"
                        variant="outlined"
                        label="Start Time" // This acts as the placeholder in the top left
                        value={values.startTime}
                        name={`startTime`}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }} // Ensures the label is always visible
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }} // Style for the input
                        error={(touched as any).startTime && (errors as any).startTime}
                        fullWidth
                      />
                      {(touched as any)?.startTime && <FormHelperText error={true}>{(errors as any)?.startTime}</FormHelperText>}
                    </FormControl>

                    <FormControl style={{ marginTop: '2%', marginBottom: '2%', width: '100%' }}>
                      <TextField
                        type="time"
                        variant="outlined"
                        label="End Time" // This acts as the placeholder in the top left
                        value={values.endTime}
                        name={`endTime`}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }} // Ensures the label is always visible
                        inputProps={{ style: { fontSize: 13, fontFamily: 'Roboto, sans-serif' } }} // Style for the input
                        error={(touched as any).endTime && (errors as any).endTime}
                        fullWidth
                      />
                      {(touched as any)?.endTime && <FormHelperText error={true}>{(errors as any)?.endTime}</FormHelperText>}
                    </FormControl>
                  </div>
                  <Grid
                    lg={12}
                    xs={8}
                    style={{
                      display: 'flex',
                      marginTop: 30,
                      marginBottom: '8%',
                      backgroundColor: '',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconButton
                      style={{
                        backgroundColor: '#EFEFEF',
                        marginLeft: '3%',
                        height: '100%',
                        width: '30%',
                        border: '1px solid lightgray',
                        display: 'flex',
                        borderRadius: 3,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                      onClick={handleRedirect}
                    >
                      <img
                        src={'../../../../content/assets/Group236.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          fontWeight: 400,
                          marginLeft: 10,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          color: 'black',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Cancel
                      </h1>
                    </IconButton>

                    <IconButton
                      style={{
                        backgroundColor: '#21AD64',
                        marginLeft: '2%',
                        height: '100%',
                        width: '30%',
                        border: '1px solid lightgray',
                        padding: '10px',
                        display: 'flex',
                        borderRadius: 3,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                      type="submit"
                    >
                      <img
                        src={'../../../../content/assets/circle-tick-svgrepo-com.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          fontWeight: 400,
                          marginLeft: 10,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Submit
                      </h1>
                    </IconButton>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditPharmacy;
