import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionPatientReportedIssue from './session-patient-reported-issue';
import SessionPatientReportedIssueDetail from './session-patient-reported-issue-detail';
import SessionPatientReportedIssueUpdate from './session-patient-reported-issue-update';
import SessionPatientReportedIssueDeleteDialog from './session-patient-reported-issue-delete-dialog';

const SessionPatientReportedIssueRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionPatientReportedIssue />} />
    <Route path="new" element={<SessionPatientReportedIssueUpdate />} />
    <Route path=":id">
      <Route index element={<SessionPatientReportedIssueDetail />} />
      <Route path="edit" element={<SessionPatientReportedIssueUpdate />} />
      <Route path="delete" element={<SessionPatientReportedIssueDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionPatientReportedIssueRoutes;
