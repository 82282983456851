import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { getPharmacyById } from 'app/modules/deo-work-flow/deo-reducer';
import { IUpdateDeoPharmacy } from 'app/modules/deo-work-flow/interface';
import { updatePharmacy } from 'app/modules/deo-work-flow/deo-reducer';
import { toast } from 'react-toastify';

const initialStateServicePharmacist = {
  contact_number: '',
  gphc_umber: '',
  manager_name: '',
  name: '',
  address: '',
  rule: '',
  cut_of_date: '',
  target: '',
  startTime: '',
  endTime: '',
  nhsEmail: '',
  businessEmail: '',
};

const EditPharmacyHook = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const pharmacyDetails = useAppSelector(state => state.deo.pharmacyDetails);
  const [initialStateServicePharmacistform, setInitialStateServicePharmacist] = useState<any>(initialStateServicePharmacist);

  const validationSchemaServicePharmacist = useCallback(() => {
    return yup.object().shape({
      name: yup.string().required('Name is a required field.'),
      contact_number: yup.string().required('Contact Number is a required field.'),
      gphc_umber: yup.string().required('GHPC Number is a required field.').length(7, 'Length should be 7 characters'),
      manager_name: yup.string().required('Manager name is a required field.'),
      address: yup.string().required('Address is a required field.'),
      rule: yup
        .number()
        .typeError('Intervention start after must be a number')
        .required('Intervention start after is a required field')
        .min(1, 'Intervention start after must be at least 1'),
      target: yup
        .number()
        .typeError('Pharmacy target must be a number')
        .required('Pharmacy target is a required field')
        .min(1, 'Pharmacy target must be at least 1'),
      cut_of_date: yup
        .number()
        .typeError('Cut of date must be a number')
        .required('Cut of date is a required field')
        .min(10, 'Cut of date must be at least 10'),
      startTime: yup.string().required('Working Hours is a required field'),
      endTime: yup.string().required('Working Hours is a required field'),
      nhsEmail: yup.string().required('Nhs Email is a required field'),
      businessEmail: yup.string().required('Business Email is a required field'),
    });
  }, []);

  const updateInitialStateServicePharmacist = (name, contact_number, gphc_umber, address, manager_name) => {};

  const populatePharmacyForm = useCallback(async () => {
    const res: any = await dispatch(getPharmacyById(Number(id)));
    if (res.type === 'manage_pharmacy/get_pharmacy_by_id/fulfilled') {
      const pharmacyObj = res.payload.data;

      setInitialStateServicePharmacist({
        name: pharmacyObj.name,
        address: pharmacyObj.address,
        contact_number: pharmacyObj.contact_number,
        gphc_umber: pharmacyObj.gphc_umber,
        manager_name: pharmacyObj.manager_name,
        rule: pharmacyObj.rule,
        cut_of_date: pharmacyObj.cut_of_date,
        target: pharmacyObj.target,
        startTime: pharmacyObj.startTime,
        endTime: pharmacyObj.endTime,
        nhsEmail: pharmacyObj.nhsEmail,
        businessEmail: pharmacyObj.businessEmail,
      });
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      populatePharmacyForm();
    }
  }, [id]);
  const navigateToDashboard = () => {
    navigate('/operator/dashboard', { state: { activeItem: 'ManagePharmacy' } });
  };
  const updatePharmacySubmit = useCallback(
    async data => {
      const params: IUpdateDeoPharmacy = {
        ...data,
        id: Number(id),
      };
      const res = await dispatch(updatePharmacy(params));
      if (res.type === 'manage_pharmacy/update_pharmacy/fulfilled') {
        navigateToDashboard();
        toast.success('Pharmacy updated successfully');
      }
    },
    [id]
  );

  const states = {
    pharmacyDetails,
    initialStateServicePharmacistform,
    validationSchemaServicePharmacist,
    updateInitialStateServicePharmacist,
  };

  const handlers = {
    updatePharmacySubmit,
  };

  return {
    states,
    handlers,
  };
};

export default EditPharmacyHook;
