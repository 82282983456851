import { IPrescription } from 'app/shared/model/prescription.model';
import { IMedicine } from 'app/shared/model/medicine.model';

export interface IPrescriptionMedicine {
  id?: number;
  prescription?: IPrescription | null;
  medicine?: IMedicine | null;
}

export const defaultValue: Readonly<IPrescriptionMedicine> = {};
