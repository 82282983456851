import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionMedicineUsedAsPrescribedNoType from './session-medicine-used-as-prescribed-no-type';
import SessionMedicineUsedAsPrescribedNoTypeDetail from './session-medicine-used-as-prescribed-no-type-detail';
import SessionMedicineUsedAsPrescribedNoTypeUpdate from './session-medicine-used-as-prescribed-no-type-update';
import SessionMedicineUsedAsPrescribedNoTypeDeleteDialog from './session-medicine-used-as-prescribed-no-type-delete-dialog';

const SessionMedicineUsedAsPrescribedNoTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionMedicineUsedAsPrescribedNoType />} />
    <Route path="new" element={<SessionMedicineUsedAsPrescribedNoTypeUpdate />} />
    <Route path=":id">
      <Route index element={<SessionMedicineUsedAsPrescribedNoTypeDetail />} />
      <Route path="edit" element={<SessionMedicineUsedAsPrescribedNoTypeUpdate />} />
      <Route path="delete" element={<SessionMedicineUsedAsPrescribedNoTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionMedicineUsedAsPrescribedNoTypeRoutes;
