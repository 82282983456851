import { useState } from 'react';
import { IListingPharmacy, IOverdueOverviewListingPharmacy } from '../interface';
import { useAppSelector } from 'app/config/store';
import moment from 'moment/moment';

const intialState: IOverdueOverviewListingPharmacy = {
  pharmacy: '',
  from: '',
  to: '',
  pharmacist: '',
  branch: '',
  group: '',
  status: '',
};

const FilterHook = () => {
  const [searchCreteria] = useState<IOverdueOverviewListingPharmacy>(intialState);
  const allPharmacies = useAppSelector(state => state.admin.allPharmacies);
  const allSessionStatuses = useAppSelector(state => state.admin.allSessionStatuses);
  const allServicesPharmacist = useAppSelector(state => state.admin.allServicePharmacist);
  const allGroupNames = useAppSelector(state => state.admin.allGroupNames);

  const states = {
    searchCreteria,
    allPharmacies,
    allServicesPharmacist,
    allSessionStatuses,
    allGroupNames,
  };
  const handlers = {};

  return {
    states,
    handlers,
  };
};

export default FilterHook;
