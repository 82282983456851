import appUser from 'app/entities/app-user/app-user.reducer';
import userRole from 'app/entities/user-role/user-role.reducer';
import pharmacy from 'app/entities/pharmacy/pharmacy.reducer';
import patient from 'app/entities/patient/patient.reducer';
import gP from 'app/entities/gp/gp.reducer';
import service from 'app/entities/service/service.reducer';
import sessionType from 'app/entities/session-type/session-type.reducer';
import sessionStatus from 'app/entities/session-status/session-status.reducer';
import session from 'app/entities/session/session.reducer';
import admin from 'app/modules/admin-work-flow/admin-reducer';
import deo from 'app/modules/deo-work-flow/deo-reducer';
import client from 'app/modules/contractor-workflow/client-reducer';

import sessionPreviouslyDeliveredNoType from 'app/entities/session-previously-delivered-no-type/session-previously-delivered-no-type.reducer';
import sessionConsultationMethod from 'app/entities/session-consultation-method/session-consultation-method.reducer';
import medicine from 'app/entities/medicine/medicine.reducer';
import prescription from 'app/entities/prescription/prescription.reducer';
import prescriptionMedicine from 'app/entities/prescription-medicine/prescription-medicine.reducer';
import sessionMedicineUsedAsPrescribedNoType from 'app/entities/session-medicine-used-as-prescribed-no-type/session-medicine-used-as-prescribed-no-type.reducer';
import patientReportedIssueType from 'app/entities/patient-reported-issue-type/patient-reported-issue-type.reducer';
import sessionPatientReportedIssue from 'app/entities/session-patient-reported-issue/session-patient-reported-issue.reducer';
import patientProvidedInformationType from 'app/entities/patient-provided-information-type/patient-provided-information-type.reducer';
import sessionPatientProvidedInformation from 'app/entities/session-patient-provided-information/session-patient-provided-information.reducer';
import adviceToPatientType from 'app/entities/advice-to-patient-type/advice-to-patient-type.reducer';
import sessionAdviceToPatient from 'app/entities/session-advice-to-patient/session-advice-to-patient.reducer';
import patientAgreedActionType from 'app/entities/patient-agreed-action-type/patient-agreed-action-type.reducer';
import sessionPatientAgreedAction from 'app/entities/session-patient-agreed-action/session-patient-agreed-action.reducer';
import pharmacistAgreedActionType from 'app/entities/pharmacist-agreed-action-type/pharmacist-agreed-action-type.reducer';
import sessionPharmacistAgreedAction from 'app/entities/session-pharmacist-agreed-action/session-pharmacist-agreed-action.reducer';
import healthyLivingAdviceType from 'app/entities/healthy-living-advice-type/healthy-living-advice-type.reducer';
import sessionHealthyLivingAdvice from 'app/entities/session-healthy-living-advice/session-healthy-living-advice.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  client,
  admin,
  deo,
  appUser,
  userRole,
  pharmacy,
  patient,
  gP,
  service,
  sessionType,
  sessionStatus,
  session,
  sessionPreviouslyDeliveredNoType,
  sessionConsultationMethod,
  medicine,
  prescription,
  prescriptionMedicine,
  sessionMedicineUsedAsPrescribedNoType,
  patientReportedIssueType,
  sessionPatientReportedIssue,
  patientProvidedInformationType,
  sessionPatientProvidedInformation,
  adviceToPatientType,
  sessionAdviceToPatient,
  patientAgreedActionType,
  sessionPatientAgreedAction,
  pharmacistAgreedActionType,
  sessionPharmacistAgreedAction,
  healthyLivingAdviceType,
  sessionHealthyLivingAdvice,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
