import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './session-patient-agreed-action.reducer';

export const SessionPatientAgreedActionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const sessionPatientAgreedActionEntity = useAppSelector(state => state.sessionPatientAgreedAction.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sessionPatientAgreedActionDetailsHeading">
          <Translate contentKey="pharmacySolutionsApp.sessionPatientAgreedAction.detail.title">SessionPatientAgreedAction</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{sessionPatientAgreedActionEntity.id}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="pharmacySolutionsApp.sessionPatientAgreedAction.description">Description</Translate>
            </span>
          </dt>
          <dd>{sessionPatientAgreedActionEntity.description}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.sessionPatientAgreedAction.patientAgreedActionType">
              Patient Agreed Action Type
            </Translate>
          </dt>
          <dd>
            {sessionPatientAgreedActionEntity.patientAgreedActionType ? sessionPatientAgreedActionEntity.patientAgreedActionType.id : ''}
          </dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.sessionPatientAgreedAction.session">Session</Translate>
          </dt>
          <dd>{sessionPatientAgreedActionEntity.session ? sessionPatientAgreedActionEntity.session.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/session-patient-agreed-action" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/session-patient-agreed-action/${sessionPatientAgreedActionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SessionPatientAgreedActionDetail;
