import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Pharmacy from './pharmacy';
import PharmacyDetail from './pharmacy-detail';
import PharmacyUpdate from './pharmacy-update';
import PharmacyDeleteDialog from './pharmacy-delete-dialog';

const PharmacyRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Pharmacy />} />
    <Route path="new" element={<PharmacyUpdate />} />
    <Route path=":id">
      <Route index element={<PharmacyDetail />} />
      <Route path="edit" element={<PharmacyUpdate />} />
      <Route path="delete" element={<PharmacyDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PharmacyRoutes;
