/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import Paper from '@mui/material/Paper';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Tooltip,
  Select as MuiSelect,
  Box, // Rename Mui Select to avoid conflict
} from '@mui/material'; // Import Mui Select as MuiSelect

import Select from 'react-select';
import RichDataTable from '../admin-work-flow/rich-data-table/RichDataTable';
import useOverviewScreen from '../admin-work-flow/hooks/useOverviewScreen';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useClientDashboard from './hooks/useClientDashboard';
import { generalMonthArray } from 'app/shared/util/utits';

export const Contractor = props => {
  const { states, handlers } = useClientDashboard();
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const {
    countTotalIntervention,
    countTotalFollowUp,
    countTotal,
    pageNo,
    limit,
    totalCount,
    allUserPharmacy,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    allSessionPharmacyListing,
  } = states;

  const {
    downloadPharmacyInExcelFile,
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    customFilterOption,
    handleSelectMonth,
  } = handlers;

  const gridData = [
    { icon: '../../../../content/assets/check.png', heading: 'NMS Completed', heading2: totalCount !== undefined ? totalCount : 0 },
  ];

  const colourStyles = {
    menuList: styles => ({
      ...styles,
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 100,
      width: 'max-content',
      minWidth: '100%',
    }),
  };

  return (
    <Grid
      lg={9.5}
      xs={8}
      container
      style={{ backgroundColor: 'transparent', position: 'sticky', top: 0, marginTop: 40, marginLeft: 20, height: '87%' }}
    >
      <Grid lg={11.6} xs={11.9} container style={{ flexDirection: 'row', display: 'flex', marginLeft: 10 }}>
        {/*<Grid lg={12} xs={12} style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>*/}
        {/*  {gridData.map((item, index) => (*/}
        {/*    <Grid key={index} lg={11.9} xs={11.9} style={{ height: 130, justifyContent: 'center', backgroundColor: 'white' }}>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          height: 100,*/}
        {/*          display: 'flex',*/}
        {/*          flexDirection: 'column',*/}
        {/*          alignItems: 'center',*/}
        {/*          justifyContent: 'center',*/}
        {/*          marginTop: 20,*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <img src={item.icon} alt="logo" style={{ height: '44px' }} />*/}
        {/*        <h1 style={{ fontSize: 13, color: '#707070', marginTop: 10 }}>{item.heading}</h1>*/}
        {/*        <h1 style={{ fontSize: 17, marginTop: 5 }}>{item.heading2}</h1>*/}
        {/*      </div>*/}
        {/*    </Grid>*/}
        {/*  ))}*/}
        {/*</Grid>*/}

        <div style={{ width: '100%', display: 'flex', marginBottom: '20px' }}>
          <div style={{ width: '95%', display: 'flex' }}>
            <div style={{ display: 'flex', width: '40%' }}>
              <Tooltip title="Date From">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    className="clock"
                    onChange={newValue => handledateFrom(newValue)}
                    format="DD/MM/YYYY"
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    sx={{ marginTop: 0, marginLeft: 0, border: '', width: '95%', height: '80%' }}
                  />
                </LocalizationProvider>
              </Tooltip>
              <Tooltip title="Date Till">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock"
                    onChange={newValue => handledateTill(newValue)}
                    format="DD/MM/YYYY"
                    sx={{ marginTop: 0, marginLeft: '2%', border: '', width: '95%', height: '80%' }}
                  />
                </LocalizationProvider>
              </Tooltip>
              <MuiSelect
                value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                onChange={handleSelectMonth}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{
                  height: '40px',
                  width: '40%',
                  fontSize: '12px',
                  backgroundColor: '#EFEFEF',
                  color: '#393939',
                  marginRight: '10px',
                  marginLeft: '10px',
                }}
              >
                {generalMonthArray.map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <div style={{ display: 'flex', marginTop: '5px' }}>
              <span> Completed NMS : {totalCount !== undefined ? totalCount : 0}</span>
            </div>
          </div>

          <Tooltip title="Refresh">
            <IconButton
              onClick={handleRefresh}
              style={{
                backgroundColor: '#7CB6F8',
                marginLeft: 5,
                height: 40,
                width: 40,
                padding: '0px',
                justifyContent: 'center',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Download">
            <IconButton
              onClick={downloadPharmacyInExcelFile}
              style={{
                backgroundColor: '#7CB6F8',
                marginLeft: 5,
                height: 40,
                width: 40,
                padding: '0px',
                borderRadius: 5,
                justifyContent: 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
            </IconButton>
          </Tooltip>
        </div>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
          <FormControl style={{ minWidth: '100%' }}>
            <Select
              isClearable
              name="pharmacies"
              className="basic-single"
              placeholder={'Search pharmacy by name, branch name, post code'}
              classNamePrefix="select"
              value={searchCreteria.pharmacy || ''}
              options={allUserPharmacy}
              styles={colourStyles}
              onChange={handleRole}
              filterOption={customFilterOption}
            />
          </FormControl>
        </div>
        <Grid sx={{ width: '100%', paddingTop: '3px', border: 'none', marginTop: '-1%', backgroundColor: 'white' }}>
          <Grid lg={12} item style={{ height: 30, marginTop: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 26 }}>
              <FormControl sx={{ width: '100ch' }}>
                <OutlinedInput
                  style={{ borderRadius: 'none', height: 30, width: '100%' }}
                  placeholder="Search here"
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                        <img src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} alt="logo" style={{ height: '15px' }} />
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ style: { fontSize: 13 } }}
                  classes={{ notchedOutline: 'white-outline' }}
                />
              </FormControl>
            </div>
          </Grid>

          <RichDataTable
            limit={limit}
            page={pageNo}
            count={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['', 'patient.fullName', 'patient.telephone', 'patient.address', 'pharmacy.name', 'pharmacy.branch_name']}
            dataSource={allSessionPharmacyListing}
            headers={['', 'Patient Name', 'Phone', 'Address', 'Pharmacy', 'Branch Name']}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contractor;

interface Styles {
  container: React.CSSProperties;
}

const styles: Styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 0,
  },
};
