import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  DialogContent,
  DialogTitle,
  Dialog,
  DialogActions,
} from '@mui/material';
import './login.scss';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ArrowCircleRight, ArrowRight, Keyboard, KeyboardArrowRight, Visibility, VisibilityOff } from '@mui/icons-material';

export interface ILoginModalProps {
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
}

const LoginModal = (props: ILoginModalProps) => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [values, setValues] = useState({ username: '', password: '', rememberMe: false });
  const [showPassword, setShowPassword] = useState(false);
  const [fieldError, setFieldError] = useState({
    username: false,
    password: false,
  });
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });

  const handleLogin = async () => {
    try {
      props.handleLogin(values.username, values.password, values.rememberMe);
      navigate('/login/otp', { state: { username: values.username } });
    } catch (error) {
      console.log(error);
    }
  };

  const setFormValues = e => {
    setValues(oldValues => ({ ...oldValues, [e.target.name]: e.target.value }));
    if (e.target.name === 'username' || e.target.name === 'password') {
      checkSingleFieldValidations(e);
    }
  };
  const checkSingleFieldValidations = e => {
    if (e.target.value === undefined || e.target.value === '' || e.target.value === null) {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: true }));
    } else {
      setFieldError(oldValues => ({ ...oldValues, [e.target.name]: false }));
    }
  };

  const checkValidationsBeforeSubmit = () => {
    if (values['username'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['username']: true }));
    }
    if (values['password'] === undefined) {
      setFieldError(oldValues => ({ ...oldValues, ['password']: true }));
    }
  };

  const isAllowedToSubmit = () => {
    checkValidationsBeforeSubmit();
    if (!fieldError['username'] && !fieldError['password'] && values['username'] !== undefined && values['password'] !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  const setCheckBoxValues = e => {
    const { name, checked } = e.target;
    setValues(oldValues => ({
      ...oldValues,
      [name]: checked,
    }));
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      login();
    }
  };

  const login = () => {
    if (isAllowedToSubmit()) {
      handleLogin();
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const token = localStorage.getItem('jhi-authenticationToken')
    ? localStorage.getItem('jhi-authenticationToken')
    : sessionStorage.getItem('jhi-authenticationToken');
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`, // Ensure the URL is correct
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={'../../../content/logos/final-logo-02.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight="600" variant="h6">
              Login to pharmacy solutions
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Enter your details to sign in to your account
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }} onKeyDown={handleKeyDown}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                // label="Enter Email"
                placeholder={'Enter Email'}
                error={fieldError.username}
                name="username"
                autoComplete="username"
                autoFocus
                onChange={setFormValues}
                className={'field-height'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                // label="Enter Password"
                placeholder={'Enter Password'}
                error={fieldError['password']}
                type={showPassword ? 'text' : 'password'}
                id="password"
                value={values.password}
                onChange={setFormValues}
                className={'field-height'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={togglePasswordVisibility} sx={{ cursor: 'pointer' }}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <FormControlLabel
                control={<Checkbox value={true} name={'rememberMe'} onChange={setCheckBoxValues} color="primary" />}
                label="Remember me"
                sx={{ float: 'left' }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={'button-height'}
                sx={{ mt: 2, mb: 1, position: 'relative' }}
                onClick={login}
              >
                Sign in
                <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
              </Button>
              <Typography component="p" variant="body2" sx={{ mt: 2 }}>
                Having trouble signing in? <Link to={'forgotPassword'}>Forgot Password</Link>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default LoginModal;
