import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPharmacy, IPharmacyBranch } from 'app/shared/model/pharmacy.model';
import { ISession } from 'app/shared/model/session.model';
import { createEntitySlice, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import axios from 'axios';
import {
  IDeoOverdueSessionParam,
  IDeoOverviewSessionParam,
  IDeoSessionStatsParam,
  IDeoUploadedFileParam,
  IDeoManagePharmacyFileParam,
  IDeoRecordSessionParam,
  IDeoFileParam,
  ISessionParam,
  IStatsParam,
} from './interface';
import { IUpdateDeoPharmacy } from 'app/modules/deo-work-flow/interface';
const initialState = {
  count: 0,
  results: [],
  excelFileOverviewRecords: [],
  excelFileOverdueRecords: [],
  excelSingleFileRecords: [],
  excelManagePharmacyListingRecords: [],
  excelFilesListing: [],
  excelFileListingRecords: [],
  excelFilePharmacyStatsRecords: [],
  rows: 20,
  page: 0,
  allPharmacies: [],
  allPharmaciesBranches: [],
  allServicePharmacist: [],
  loading: false,
  loadingOverviewDownload: false,
  loadingOverdueDownload: false,
  loadingDeletedRecordDownload: false,
  error: false,
  errorMessage: null,
  entities: [],
  entity: null,
  updating: false,
  unSubmitRecordDeleteLoading: false,
  updateSuccess: false,
  stats: {},
  deoSessionStats: {},
  deoOverviewSession: [],
  deoOverdueSession: [],
  deoOverdueSessionCount: 0,
  deoUploadedFile: [],
  deoUploadedFileCount: 0,
  deoDeletedFileCount: 0,
  deoDeletedRecordCount: 0,
  deoManagePharmacyFile: [],
  deoManagePharmacyFileCount: 0,
  deoRecordSession: [],
  deoRecordCount: 0,
  deoFileSession: [],
  deoFileCount: 0,
  recentRoles: [],
  recentClients: [],
  roles: [],
  rolesListing: [],
  overviewCount: 0,
  overviewRows: 20,
  overviewPage: 0,
  overdueCount: 0,
  overdueRows: 20,
  overduePage: 0,
  uploadedFileCount: 0,
  uploadedFileRows: 20,
  uploadedFilePage: 0,
  managePharmacyFileCount: 0,
  managePharmacyFileRows: 20,
  managePharmacyFilePage: 0,
  userDetails: null,
  allUserPharmacies: [],
  allListPharmacies: [],
  listPharmacy: [],
  listAllServicePharmacist: [],
  pharmacyCount: 0,
  pharmacyRows: 20,
  pharmacyPage: 0,
  pharmacyDetails: null,
};

export interface IListingPharmacy {
  page?: number;
  size?: number;
  pharmacy?: string;
  from?: string;
  to?: string;
  searchQuery?: string;
}
// Actions
const apiUrlPharmacy = 'api/pharmacies';
const apiUrlDeoSessionsStats = 'api/sessions/deo/stats';
const apiUrlDeoOverviewSessions = 'api/sessions/overview/sessions';
const apiUrlDeoOverdueSessions = 'api/sessions/overdue/sessions';
const apiUrlDeoUploadedFileSessions = 'api/sessions/files';
const apiUrlDeoRecordsStats = 'api/sessions/records-stats-deo';
const apiUrlDeoManagePharmacyFileSessions = 'api/pharmacies';
const apiUrlDeoRecordSessions = 'api/sessions/deleted/sessions';
const apiUrlDeoFileSessions = 'api/pharmacyFileMetaData/deleted/files';
const apiUrlDeoDownloadFileSessions = 'api/sessions/download-single-file--excel-file-data';
const apiUrlPharmacySessionStatsDownload = 'api/sessions/download-excel-file-pharmacy-nms-data';
const apiUrlRestoreDeletedFile = 'api/pharmacyFileMetaData/restore-deleted-files';
const apiUrlSubmitFileFile = 'api/pharmacyFileMetaData/submit-file';
const apiUrlRestoreDeletedRecords = 'api/sessions/restore-deleted-session';
const apiUrlStats = 'api/admin/get-all-overview-stats';
const apiUrlDeleteUploadedFile = 'api/sessions/delete/file';
const apiUrlOverviewSessionDownload = 'api/sessions/download-overview-excel-file-data';
const apiUrlManagePharmacySessionDownload = 'api/pharmacies/download-pharmacy-listing-excel-data';
const apiUrlFilesListingDownload = 'api/sessions/download-files-excel-data';
const apiUrlDeletedFileListingDownload = 'api/pharmacyFileMetaData/download-deleted-files-listing-excel-data';
const apiUrlRecordListingDownload = 'api/sessions/download-deleted-session-excel-file-data';
const apiUrlOverdueSessionDownload = 'api/sessions/download-overdue-excel-file-data';
const apiUrlListAllPharmacies = 'api/admin/list-all-pharmacy';
const apiUrlGetPharmacyById = 'api/pharmacies';
const apiUrlUpdatePharmacy = 'api/pharmacies';
const apiPermanentDeleteFile = 'api/pharmacyFileMetaData';
const apiPermanentDeleteRecord = 'api/sessions';
const apiPatientConsent = 'api/admin';

export const listAllPharmacy = createAsyncThunk('manage_pharmacy/list_all_pharmacy', async (params: IListingPharmacy) => {
  const { searchQuery, pharmacy, from, to, page, size } = params;
  const requestUrl = `${apiUrlListAllPharmacies}?cacheBuster=${new Date().getTime()}
  &pharmacy=${pharmacy}&from=${from}&to=${to}&searchQuery=${searchQuery}&page=${page}&size=${size}`;
  return axios.get(requestUrl);
});

export const getAllDeoSessionsCurrentDateStats = createAsyncThunk(
  'pharmacy/fetch_all_deo_session_stats_current_date',
  async (params: IDeoSessionStatsParam) => {
    const { pharmacy, pharmacist, branch, group, month, from, to } = params;
    const requestUrl = `${apiUrlDeoSessionsStats}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${pharmacist}&group=${group}&month=${month}&from=${from}&to=${to}`;
    return axios.get(requestUrl);
  }
);

export const getAllDeoOverviewSessions = createAsyncThunk(
  'pharmacy/fetch_all_deo_overview_session',
  async (params: IDeoOverviewSessionParam) => {
    const { pharmacy, pharmacist, group, status, month, from, to, searchQuery, page, size } = params;
    const requestUrl = `${apiUrlDeoOverviewSessions}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${pharmacist}&group=${group}&status=${status}&month=${month}&from=${from}&to=${to}&q=${searchQuery}&page=${page}&size=${size}`;
    return axios.get(requestUrl);
  }
);

export const getAllDeoOverdueSessions = createAsyncThunk(
  'pharmacy/fetch_all_deo_overdue_session',
  async (params: IDeoOverdueSessionParam) => {
    const { pharmacy, pharmacist, group, status, from, to, searchQuery, page, size } = params;
    const requestUrl = `${apiUrlDeoOverdueSessions}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${pharmacist}&group=${group}&status=${status}&from=${from}&to=${to}&q=${searchQuery}&page=${page}&size=${size}`;
    return axios.get(requestUrl);
  }
);

export const getAllDeoUploadedFileSessions = createAsyncThunk(
  'pharmacy/fetch_all_deo_uploaded_file',
  async (params: IDeoUploadedFileParam) => {
    const { from, to, searchQuery, page, size } = params;
    const requestUrl = `${apiUrlDeoUploadedFileSessions}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}&q=${searchQuery}&page=${page}&size=${size}`;
    return axios.get(requestUrl);
  }
);
export const getRecordsStatsForDeo = createAsyncThunk('deo/fetch_all_deo_records_stats', async () => {
  const requestUrl = `${apiUrlDeoRecordsStats}?cacheBuster=${new Date().getTime()}`;
  return axios.get(requestUrl);
});

export const getDeletedSessions = createAsyncThunk('pharmacy/fetch_all_deo_record_session', async (params: IDeoRecordSessionParam) => {
  const { pharmacy, pharmacist, group, status, month, from, to, searchQuery, page, size } = params;
  const requestUrl = `${apiUrlDeoRecordSessions}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${pharmacist}&group=${group}&status=${status}&month=${month}&from=${from}&to=${to}&q=${searchQuery}&page=${page}&size=${size}`;
  return axios.get(requestUrl);
});

export const getManagePharmacyListing = createAsyncThunk(
  'pharmacy/fetch_all_deo_manage-pharmacy_file',
  async (params: IDeoManagePharmacyFileParam) => {
    const { from, to, searchQuery, page, size } = params;
    const requestUrl = `${apiUrlDeoManagePharmacyFileSessions}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}&q=${searchQuery}&page=${page}&size=${size}`;
    return axios.get(requestUrl);
  }
);

export const getDeletedSessionsFiles = createAsyncThunk('pharmacy/fetch_all_deo_files', async (params: IDeoFileParam) => {
  const { from, to, searchQuery, page, size } = params;
  const requestUrl = `${apiUrlDeoFileSessions}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}&q=${searchQuery}&page=${page}&size=${size}`;
  return axios.get(requestUrl);
});

export const downloadSingleFileExcelFileRecords = createAsyncThunk(
  'session/download-single-file--excel-file-data',
  async (payload: any) => {
    const requestUrl = `${apiUrlDeoDownloadFileSessions}?cacheBuster=${new Date().getTime()}&id=${payload.id}&month=${payload.month}`;
    return axios.get(requestUrl);
  }
);

export const getAllPharmacies = createAsyncThunk('pharmacy/fetch_all_pharmacies', async () => {
  const requestUrl = `${apiUrlPharmacy}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IPharmacy[]>(requestUrl);
});

export const deleteUploadedFile = createAsyncThunk(
  'session/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlDeleteUploadedFile}/${id}`;
    const result = await axios.delete(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const downloadExcelFileOverviewRecords = createAsyncThunk(
  'session/download-overview-excel-file-data',
  async (params: IDeoOverviewSessionParam) => {
    const { pharmacy, pharmacist, branch, group, status, month, from, to, searchQuery, page, size } = params;
    const requestUrl = `${apiUrlOverviewSessionDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&group=${group}&pharmacist=${pharmacist}&branch=${branch}&status=${status}&month=${month}&from=${from}&to=${to}&q=${searchQuery}`;
    return axios.get<ISession[]>(requestUrl);
  }
);
export const downloadPharmacyListingExcelData = createAsyncThunk(
  'pharmacies/download-pharmacy-listing-excel-data',
  async (params: IDeoManagePharmacyFileParam) => {
    const { from, to, searchQuery } = params;
    const requestUrl = `${apiUrlManagePharmacySessionDownload}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}&q=${searchQuery}`;
    return axios.get(requestUrl);
  }
);

export const downloadDeletedSessionListingDataForExcel = createAsyncThunk(
  'session/download-deleted-session-excel-file-data',
  async (params: IDeoRecordSessionParam) => {
    const { pharmacy, pharmacist, branch, status, group, month, from, to, searchQuery } = params;
    const requestUrl = `${apiUrlRecordListingDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${pharmacist}&branch=${branch}&group=${group}&status=${status}&month=${month}&from=${from}&to=${to}&q=${searchQuery}`;
    return axios.get(requestUrl);
  }
);
export const downloadExcelFilesListingRecords = createAsyncThunk(
  'session/download-files-excel-data',
  async (params: IDeoUploadedFileParam) => {
    const { from, to, searchQuery } = params;
    const requestUrl = `${apiUrlFilesListingDownload}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}&q=${searchQuery}`;
    return axios.get(requestUrl);
  }
);

export const downloadFilesListingExcelData = createAsyncThunk(
  'pharmacyFileMetaData/download-deleted-files-listing-excel-data',
  async (params: IDeoFileParam) => {
    const { from, to, searchQuery } = params;
    const requestUrl = `${apiUrlDeletedFileListingDownload}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}&q=${searchQuery}`;
    return axios.get(requestUrl);
  }
);

export const downloadExcelFileOverdueRecords = createAsyncThunk(
  'session/download-overdue-excel-file-data',
  async (params: IDeoOverdueSessionParam) => {
    const { pharmacy, pharmacist, branch, status, from, to, searchQuery } = params;
    const requestUrl = `${apiUrlOverdueSessionDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&pharmacist=${pharmacist}&branch=${branch}&status=${status}&from=${from}&to=${to}&q=${searchQuery}`;
    return axios.get<ISession[]>(requestUrl);
  }
);
export const downloadExcelPharmacyNMSRecords = createAsyncThunk(
  'session/download_excel_file_pharmacy_nms_records',
  async (params: ISessionParam) => {
    const { searchQuery, servicePharmacist, pharmacy, from, to } = params;
    const requestUrl = `${apiUrlPharmacySessionStatsDownload}?cacheBuster=${new Date().getTime()}
    &pharmacy=${pharmacy}&servicePharmacist=${servicePharmacist}&from=${from}&to=${to}&searchQuery=${searchQuery}`;
    return axios.get<ISession[]>(requestUrl);
  }
);
export const getPharmacyById = createAsyncThunk('manage_pharmacy/get_pharmacy_by_id', async (id: number) => {
  const requestUrl = `${apiUrlGetPharmacyById}/${id}`;
  return axios.get(requestUrl);
});
export const updatePharmacy = createAsyncThunk('manage_pharmacy/update_pharmacy', async (params: IUpdateDeoPharmacy) => {
  const { id, ...data } = params;
  const requestUrl = `${apiUrlUpdatePharmacy}/${Number(id)}`;
  return axios.put(requestUrl, params);
});

export const restoreDeletedFiles = createAsyncThunk(
  'pharmacyFileMetaData/restore-deleted-files',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlRestoreDeletedFile}/${id}`;
    const result = await axios.put(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const submitFile = createAsyncThunk(
  'pharmacyFileMetaData/submit-file',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlSubmitFileFile}/${id}`;
    const result = await axios.put(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const restoreDeletedRecords = createAsyncThunk(
  'session/restore-deleted-session',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlRestoreDeletedRecords}/${id}`;
    const result = await axios.put(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const getAllStats = createAsyncThunk('overviewScreen/get_All_Stats', async (params: IStatsParam) => {
  const { from, to } = params;
  const requestUrl = `${apiUrlStats}?cacheBuster=${new Date().getTime()}
    &from=${from}&to=${to}`;
  return axios.get(requestUrl);
});

export const permanentDeleteFile = createAsyncThunk(
  'pharmacyFileMetaData/permanent/delete/file',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiPermanentDeleteFile}/delete/file/${id}`;
    const result = await axios.delete(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const permanentDeleteUnSubmittedFiles = createAsyncThunk(
  'pharmacyFileMetaData/delete-not-submitted-files',
  async (id: string | number) => {
    const requestUrl = `${apiPermanentDeleteFile}/delete-not-submitted-files/${id}`;
    const result = await axios.delete(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const permanentDeleteRecord = createAsyncThunk(
  'session/permanent/delete/record',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiPermanentDeleteRecord}/permanent/delete/record/${id}`;
    const result = await axios.delete<ISession>(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);
export const updatePatientConsent = createAsyncThunk(
  'admin/update-patient-consent',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiPatientConsent}/update-patient-consent/${id}`;
    return await axios.post(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const DeoSlice = createEntitySlice({
  name: 'deo',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getAllPharmacies.fulfilled, (state, action) => {
        return {
          ...state,
          allPharmacies: action?.payload?.data['results'],
        };
      })
      .addCase(getAllPharmacies.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllPharmacies.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(downloadExcelFilesListingRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelFileListingRecords: action.payload.data,
        };
      })
      .addCase(downloadExcelFilesListingRecords.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(downloadExcelFilesListingRecords.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })

      .addCase(permanentDeleteUnSubmittedFiles.fulfilled, (state, action: any) => {
        return {
          ...state,
          unSubmitRecordDeleteLoading: false,
        };
      })
      .addCase(permanentDeleteUnSubmittedFiles.pending, state => {
        return {
          ...state,
          unSubmitRecordDeleteLoading: true,
        };
      })
      .addCase(permanentDeleteUnSubmittedFiles.rejected, state => {
        return {
          ...state,
          unSubmitRecordDeleteLoading: false,
          error: true,
        };
      })
      .addCase(downloadSingleFileExcelFileRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelSingleFileRecords: action.payload.data,
        };
      })
      .addCase(downloadSingleFileExcelFileRecords.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(downloadSingleFileExcelFileRecords.rejected, state => {
        return {
          ...state,
          loadingOverviewDownload: false,
          error: true,
        };
      })
      .addCase(downloadExcelFileOverviewRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
          loadingOverviewDownload: false,
          excelFileOverviewRecords: action.payload.data,
        };
      })
      .addCase(downloadExcelFileOverviewRecords.pending, state => {
        return {
          ...state,
          loadingOverviewDownload: true,
        };
      })
      .addCase(downloadExcelFileOverviewRecords.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(downloadExcelFileOverdueRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
          loadingOverdueDownload: false,
          excelFileOverdueRecords: action.payload.data,
        };
      })
      .addCase(downloadExcelFileOverdueRecords.pending, state => {
        return {
          ...state,
          loadingOverdueDownload: true,
        };
      })
      .addCase(downloadExcelFileOverdueRecords.rejected, state => {
        return {
          ...state,
          loadingOverdueDownload: false,
          error: true,
        };
      })
      .addCase(downloadPharmacyListingExcelData.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelManagePharmacyListingRecords: action.payload.data,
        };
      })
      .addCase(downloadPharmacyListingExcelData.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(downloadPharmacyListingExcelData.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(downloadFilesListingExcelData.fulfilled, (state, action: any) => {
        return {
          ...state,
          excelFilesListing: action.payload.data,
        };
      })
      .addCase(downloadFilesListingExcelData.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(downloadFilesListingExcelData.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(downloadDeletedSessionListingDataForExcel.fulfilled, (state, action: any) => {
        return {
          ...state,
          loadingDeletedRecordDownload: false,
          excelRecordListing: action.payload.data,
        };
      })
      .addCase(downloadDeletedSessionListingDataForExcel.pending, state => {
        return {
          ...state,
          loadingDeletedRecordDownload: true,
        };
      })
      .addCase(downloadDeletedSessionListingDataForExcel.rejected, state => {
        return {
          ...state,
          loadingDeletedRecordDownload: false,
          error: true,
        };
      })
      .addCase(downloadExcelPharmacyNMSRecords.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllStats.fulfilled, (state, action: any) => {
        return {
          ...state,
          stats: action.payload.data,
        };
      })
      .addCase(getAllStats.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllStats.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllDeoSessionsCurrentDateStats.fulfilled, (state, action: any) => {
        return {
          ...state,
          deoSessionStats: action.payload.data,
          loading: false,
        };
      })
      .addCase(getAllDeoSessionsCurrentDateStats.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllDeoSessionsCurrentDateStats.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllDeoOverviewSessions.fulfilled, (state, action: any) => {
        return {
          ...state,
          deoOverviewSession: action.payload.data,
          loading: false,
        };
      })
      .addCase(getAllDeoOverviewSessions.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllDeoOverviewSessions.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllDeoOverdueSessions.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: false,
          deoOverdueSession: action.payload.data,
          // deoOverdueSessionCount: action?.payload?.data?.count,
        };
      })
      .addCase(getAllDeoOverdueSessions.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllDeoOverdueSessions.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getAllDeoUploadedFileSessions.fulfilled, (state, action: any) => {
        return {
          ...state,
          deoUploadedFile: action.payload.data,
          // deoUploadedFileCount: action?.payload?.data?.count,
        };
      })
      .addCase(getAllDeoUploadedFileSessions.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getAllDeoUploadedFileSessions.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getRecordsStatsForDeo.fulfilled, (state, action: any) => {
        return {
          ...state,
          deoUploadedFileCount: action?.payload?.data.results?.uploadedFileCount,
          deoFileCount: action?.payload?.data.results?.deleteFileCount,
          deoRecordCount: action?.payload?.data.results?.deletedRecordCount,
          deoManagePharmacyFileCount: action?.payload?.data.results?.createdPharmacyCount,
        };
      })
      .addCase(getRecordsStatsForDeo.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getRecordsStatsForDeo.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(deleteUploadedFile.fulfilled, (state, action: any) => {
        return {
          ...state,
        };
      })
      .addCase(deleteUploadedFile.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteUploadedFile.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getManagePharmacyListing.fulfilled, (state, action: any) => {
        return {
          ...state,
          deoManagePharmacyFile: action.payload.data,
          // deoManagePharmacyFileCount: action?.payload?.data?.count,
        };
      })
      .addCase(getManagePharmacyListing.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getManagePharmacyListing.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getDeletedSessions.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: false,
          deoRecordSession: action.payload.data,
          // deoRecordCount: action?.payload?.data?.count,
        };
      })
      .addCase(getDeletedSessions.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getDeletedSessions.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(permanentDeleteFile.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(permanentDeleteFile.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(permanentDeleteFile.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getDeletedSessionsFiles.fulfilled, (state, action: any) => {
        return {
          ...state,
          deoFileSession: action.payload.data,
          // deoFileCount: action?.payload?.data?.count,
        };
      })
      .addCase(getDeletedSessionsFiles.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(getDeletedSessionsFiles.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(getPharmacyById.fulfilled, (state, action: any) => {
        return {
          ...state,
          pharmacyDetails: action.payload.data,
        };
      })
      .addCase(restoreDeletedFiles.fulfilled, (state, action: any) => {
        return {
          ...state,
        };
      })
      .addCase(restoreDeletedFiles.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(restoreDeletedFiles.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(restoreDeletedRecords.fulfilled, (state, action: any) => {
        return {
          ...state,
        };
      })
      .addCase(restoreDeletedRecords.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(restoreDeletedRecords.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(permanentDeleteRecord.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(permanentDeleteRecord.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(permanentDeleteRecord.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      })
      .addCase(updatePatientConsent.fulfilled, (state, action: any) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(updatePatientConsent.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updatePatientConsent.rejected, state => {
        return {
          ...state,
          loading: false,
          error: true,
        };
      });
  },
});
export const { reset } = DeoSlice.actions;
// Reducer
export default DeoSlice.reducer;
