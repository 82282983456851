import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionConsultationMethod from './session-consultation-method';
import SessionConsultationMethodDetail from './session-consultation-method-detail';
import SessionConsultationMethodUpdate from './session-consultation-method-update';
import SessionConsultationMethodDeleteDialog from './session-consultation-method-delete-dialog';

const SessionConsultationMethodRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionConsultationMethod />} />
    <Route path="new" element={<SessionConsultationMethodUpdate />} />
    <Route path=":id">
      <Route index element={<SessionConsultationMethodDetail />} />
      <Route path="edit" element={<SessionConsultationMethodUpdate />} />
      <Route path="delete" element={<SessionConsultationMethodDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionConsultationMethodRoutes;
