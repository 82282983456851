import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useCallback, useEffect, useState } from 'react';
import {
  IDeoFileParam,
  IListingPharmacy,
  IDeoOverviewSessionParam,
  IDeoOverdueSessionParam,
  IDeoUploadedFileParam,
  IDeoManagePharmacyFileParam,
  IDeoRecordSessionParam,
} from '../interface';
import {
  restoreDeletedFiles,
  getDeletedSessionsFiles,
  downloadFilesListingExcelData,
  getAllDeoOverviewSessions,
  getAllDeoOverdueSessions,
  getAllDeoUploadedFileSessions,
  getDeletedSessions,
  permanentDeleteFile,
  getRecordsStatsForDeo,
} from 'app/modules/deo-work-flow/deo-reducer';
import { isValidDate, s2ab, deletedFilesData } from 'app/shared/util/utits';
import * as XLSX from 'xlsx';
import moment from 'moment/moment';

const intialState: IListingPharmacy = {
  size: 20,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
};
const intialStateFile: IDeoFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
};

const intialStateOverview: IDeoOverviewSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};
const intialStateOverdue: IDeoOverdueSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};

const intialStateUploadedFile: IDeoUploadedFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
};

const intialStateManagePharmacyFile: IDeoManagePharmacyFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
};

const intialStateRecord: IDeoRecordSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  branch: '',
  group: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};

const FileHook = () => {
  const [searchCreteria, setsearchCreteria] = useState<IDeoFileParam>(intialStateFile);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [recordId, setRecordId] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const emptyRowCount = 7;
  const emptyCellCount = 3;
  const [disabled, setDisabled] = React.useState(false);

  const deoFileSessions = useAppSelector(state => state.deo.deoFileSession);

  const handleRowsChange = event => {
    const pagePerRow = event.target.value;
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    dispatch(getDeletedSessionsFiles(params));
  };

  const handlePageChange = (event, pageNum) => {
    setsearchCreteria({
      ...searchCreteria,
      page: pageNum,
    });
    const params = {
      ...searchCreteria,
      page: pageNum,
    };
    dispatch(getDeletedSessionsFiles(params));
  };

  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };
    dispatch(getDeletedSessionsFiles(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getDeletedSessionsFiles(params));
  };

  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getDeletedSessionsFiles(params));
  };

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(downloadFilesListingExcelData(params));
    if (response.type === 'pharmacyFileMetaData/download-deleted-files-listing-excel-data/fulfilled') {
      // setDownloadData(response.payload.data);
      const dataFiles = response?.payload?.data.map((elem, index) => {
        return deletedFilesData(elem, index);
      });

      // Create a worksheets
      const ws = XLSX.utils.json_to_sheet(dataFiles);

      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef]?.v?.toString() ?? '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'NMS');
      // XLSX.utils.book_append_sheet(wb, ws2, 'FollowUp');

      // Generate XLSX data in binary form
      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary data to a Blob
      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link for the Excel file
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Deleted Files - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria]);

  const getFileSession = useCallback(() => {
    dispatch(getDeletedSessionsFiles(searchCreteria));
  }, []);

  const handleClose = () => {
    setRecordId('');
    setOpen(false);
  };

  const handleClickOpen = id => {
    setRecordId(id);
    setOpen(true);
  };

  const handleRestoreDeletedFile = async id => {
    const res = await dispatch(restoreDeletedFiles(id));
    if (res.type === 'pharmacyFileMetaData/restore-deleted-files/fulfilled') {
      dispatch(getDeletedSessionsFiles(searchCreteria));
      dispatch(getRecordsStatsForDeo());
      // dispatch(getAllDeoOverviewSessions(intialStateOverview));
      // dispatch(getAllDeoOverdueSessions(intialStateOverdue));
      // dispatch(getAllDeoUploadedFileSessions(intialStateUploadedFile));
      // dispatch(getDeletedSessions(intialStateRecord));
    }
  };

  const handleDelete = async () => {
    if (recordId !== '') {
      setOpen(false);
      setLoading(true);
      const res = await dispatch(permanentDeleteFile(recordId));
      if (res.type === 'pharmacyFileMetaData/permanent/delete/file/fulfilled') {
        setDisabled(false);
        dispatch(getDeletedSessionsFiles(intialStateFile));
        dispatch(getRecordsStatsForDeo());
        // dispatch(getDeletedSessions(intialStateRecord));
        setLoading(false);
        handleClose();
      }
    }
  };

  useEffect(() => {
    getFileSession();
  }, []);

  const states = {
    emptyCellCount,
    emptyRowCount,
    searchCreteria,
    deoFileSessions,
    anchorEl,
    open,
    disabled,
    loading,
  };
  const handlers = {
    handlePageChange,
    handleRowsChange,
    handleClose,
    handleClickOpen,
    handleSearchChange,
    handledateFrom,
    handledateTill,
    getDownloadData,
    handleRestoreDeletedFile,
    handleDelete,
  };

  return {
    states,
    handlers,
  };
};

export default FileHook;
