import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  List,
  ListItemText,
} from '@mui/material';
import './login.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getMultiplePharmacy, logout, setPharmacy } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import { Search } from '@mui/icons-material';
import ListItem from '@mui/joy/ListItem';

const InstructionModal = props => {
  const dispatch = useAppDispatch();
  const { pharmacy_list, account } = useAppSelector(state => state.authentication);
  const [pharmacyArray, setPharmacyArray] = useState(pharmacy_list);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const agreement = () => {
    navigate('/');
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(logout());
    navigate('/login');
  };
  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/test-background.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid item xs={12} md={5} className={'form-background'}>
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
              <ArrowBackIcon onClick={logoutUser} />
            </Box>
            <List>
              <ListItem>
                <ListItemText primary="1. Please familiarise yourself with the Pharmacy name and address prior to calling the patients" />
              </ListItem>
              <ListItem>
                <ListItemText primary="2. I confirm I will adhere to NHS New Medicines Service Service specification" />
              </ListItem>
              <ListItem>
                <ListItemText primary="3. I confirm I will adhere to Pharmacy Solutions SOP's" />
              </ListItem>
              <ListItem>
                <ListItemText primary="4. I confirm I have appropriate Indemnity insurance in place to conduct the new medicines service" />
              </ListItem>
              <ListItem>
                <ListItemText primary="5. I confirm I have conducted the CPPE training for the New Medicines Service consultation" />
              </ListItem>
              <ListItem>
                <ListItemText primary="6. I confirm I will maintain patient confidentiality throughout my practice" />
              </ListItem>
              <ListItem>
                <ListItemText primary="7. Any NMS I can conduct not in line with the service specification will not be paid" />
              </ListItem>
              <ListItem>
                <ListItemText primary="8. Any NMS which are conducted fraudulently or do not adhere to the service specification will be reported" />
              </ListItem>
              <ListItem>
                <ListItemText primary="9. I confirm I will write accurate and detailed intervention notes" />
              </ListItem>
            </List>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default InstructionModal;
