import { IPharmacistAgreedActionType } from 'app/shared/model/pharmacist-agreed-action-type.model';
import { ISession } from 'app/shared/model/session.model';

export interface ISessionPharmacistAgreedAction {
  id?: number;
  description?: string | null;
  pharmacistAgreedActionType?: IPharmacistAgreedActionType | null;
  session?: ISession | null;
}

export const defaultValue: Readonly<ISessionPharmacistAgreedAction> = {};
