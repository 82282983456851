import { IUser } from 'app/shared/model/user.model';
import { IUserRole } from 'app/shared/model/user-role.model';
import { IPharmacy } from 'app/shared/model/pharmacy.model';

export interface IAppUser {
  id?: number;
  gphcNumber?: string | null;
  internalUser?: IUser | null;
  userRoles?: IUserRole[] | null;
  pharmacies?: IPharmacy[] | null;
}

export const defaultValue: Readonly<IAppUser> = {};
