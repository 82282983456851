import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { IDeoOverdueSessionParam, IDeoOverviewSessionParam, IDeoUploadedFileParam, IListingPharmacy } from '../interface';
import {
  deleteUploadedFile,
  downloadExcelFilesListingRecords,
  downloadSingleFileExcelFileRecords,
  getAllDeoUploadedFileSessions,
  getRecordsStatsForDeo,
} from 'app/modules/deo-work-flow/deo-reducer';
import { filesData, generateExcelData, interventionData, isValidDate, s2ab } from 'app/shared/util/utits';
import * as XLSX from 'xlsx';
import moment from 'moment/moment';

const intialState: IListingPharmacy = {
  size: 20,
  page: 0,
  searchQuery: '',
  pharmacy: '',
  from: '',
  to: '',
};
const intialStateUploadedFile: IDeoUploadedFileParam = {
  size: 20,
  page: 0,
  from: '',
  to: '',
  searchQuery: '',
  month: '',
};

const intialStateOverview: IDeoOverviewSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  group: '',
  branch: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};
const intialStateOverdue: IDeoOverdueSessionParam = {
  size: 20,
  page: 0,
  pharmacist: '',
  pharmacy: '',
  group: '',
  branch: '',
  status: '',
  from: '',
  to: '',
  searchQuery: '',
};

const UploadedHook = () => {
  const [searchCreteria, setsearchCreteria] = useState<IDeoUploadedFileParam>(intialStateUploadedFile);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [recordId, setRecordId] = useState('');
  const [downloadData, setDownloadData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const emptyRowCount = 7;
  const emptyCellCount = 3;
  const deoUploadedFiles = useAppSelector(state => state.deo.deoUploadedFile);
  const [progress, setProgress] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let timer;
    if (loading) {
      timer = setInterval(() => {
        setProgress(prevProgress => (prevProgress >= 100 ? 0 : prevProgress + 10));
      }, 800);
    } else {
      setProgress(0);
    }
    return () => {
      clearInterval(timer);
    };
  }, [loading]);

  const handleSelectMonth = event => {
    let value = event.target.value;
    value = value === 'All Months' ? '' : value;
    setsearchCreteria(prev => {
      const updated = { ...prev, month: value };
      return updated;
    });
  };

  const handleRowsChange = event => {
    const pagePerRow = event.target.value;
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    dispatch(getAllDeoUploadedFileSessions(params));
  };

  const handlePageChange = (event, pageNum) => {
    setsearchCreteria({
      ...searchCreteria,
      page: pageNum,
    });
    const params = {
      ...searchCreteria,
      page: pageNum,
    };
    dispatch(getAllDeoUploadedFileSessions(params));
  };

  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };
    dispatch(getAllDeoUploadedFileSessions(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);
    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(getAllDeoUploadedFileSessions(params));
  };

  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(getAllDeoUploadedFileSessions(params));
  };

  const handleClose = () => {
    setRecordId('');
    setOpen(false);
  };

  const handleClickOpen = id => {
    setRecordId(id);
    setOpen(true);
  };

  const handleFileDelete = () => {
    if (recordId !== '') {
      dispatch(deleteUploadedFile(recordId))
        .then(result => {
          handleClose();
          dispatch(getAllDeoUploadedFileSessions(searchCreteria));
          dispatch(getRecordsStatsForDeo());
          // dispatch(getDeletedSessionsFiles(searchCreteria));
          // dispatch(getDeletedSessions(intialStateOverview));
          // dispatch(getAllDeoOverviewSessions(intialStateOverview));
          // dispatch(getAllDeoOverdueSessions(intialStateOverdue));
        })
        .catch(error => {});
    }
  };

  const handleEdit = useCallback(id => {
    navigate(`/operator/edit/file/` + id);
    localStorage.setItem('lastActiveTabDeo', JSON.stringify({ activeItem: 'Uploaded' }));
  }, []);

  const getDownloadData = useCallback(async () => {
    const params = {
      ...searchCreteria,
    };
    const response: any = await dispatch(downloadExcelFilesListingRecords(params));
    if (response.type === 'session/download-files-excel-data/fulfilled') {
      // setDownloadData(response.payload.data);
      const dataFiles = response?.payload?.data.map((elem, index) => {
        return filesData(elem, index);
      });

      // Create a worksheets
      const ws = XLSX.utils.json_to_sheet(dataFiles);

      const totalColumns = XLSX.utils.decode_range(ws['!ref']).e.c + 1;
      ws['!cols'] = Array.from({ length: totalColumns }, (_, index) => {
        const columnTextLengths = [];
        for (let R = 0; R <= XLSX.utils.decode_range(ws['!ref']).e.r; R++) {
          const cellAddress = { c: index, r: R };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellText = ws[cellRef]?.v?.toString() ?? '';
          columnTextLengths.push(cellText.length);
        }
        const maxTextLength = Math.max(...columnTextLengths);
        return { wch: maxTextLength + 2 }; // Adding extra space for better readability
      });

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'NMS');
      // XLSX.utils.book_append_sheet(wb, ws2, 'FollowUp');

      // Generate XLSX data in binary form
      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary data to a Blob
      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link for the Excel file
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Files - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria]);

  const getUploadedFile = useCallback(() => {
    dispatch(getAllDeoUploadedFileSessions(searchCreteria));
  }, []);

  const handleExcelFileDownloadSingleFileRecords = useCallback(
    async (id: any) => {
      setLoading(true);
      const data = {
        ...searchCreteria,
        id,
        month: searchCreteria?.month,
      };
      const response: any = await dispatch(downloadSingleFileExcelFileRecords(data));
      if (response.type === 'session/download-single-file--excel-file-data/fulfilled') {
        const interventionDataList = response.payload.data;
        if (interventionDataList.length > 0) {
          const name = interventionDataList[0]['pharmacyFileMetaData']['name'];
          const groupedData = interventionDataList.reduce((acc, record) => {
            const status = record.sessionStatus.name === 'completed' ? 'Completed NMS' : record.sessionStatus.name;
            if (!acc[status]) {
              acc[status] = [];
            }
            acc[status].push(record);
            return acc;
          }, {});

          const wb = XLSX.utils.book_new();
          generateExcelData(groupedData, wb);
          const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
          const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${name} - ${moment().format('DD-MM-YYYY')}.xlsx`; // Set the file name here
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
      setLoading(false);
    },
    [searchCreteria]
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      // eslint-disable-next-line no-bitwise
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  }

  // useEffect(() => {
  //   getDownloadData();
  // }, [searchCreteria]);

  useEffect(() => {
    getUploadedFile();
  }, []);

  useEffect(() => {}, []);

  const states = {
    emptyCellCount,
    emptyRowCount,
    searchCreteria,
    deoUploadedFiles,
    anchorEl,
    open,
    loading,
    progress,
  };
  const handlers = {
    handlePageChange,
    handleRowsChange,
    handleEdit,
    handleFileDelete,
    handleClose,
    handleClickOpen,
    handleSearchChange,
    handledateFrom,
    handledateTill,
    getDownloadData,
    handleExcelFileDownloadSingleFileRecords,
    handleSelectMonth,
  };

  return {
    states,
    handlers,
  };
};

export default UploadedHook;
