import { IHealthyLivingAdviceType } from 'app/shared/model/healthy-living-advice-type.model';
import { ISession } from 'app/shared/model/session.model';

export interface ISessionHealthyLivingAdvice {
  id?: number;
  description?: string | null;
  healthyLivingAdviceType?: IHealthyLivingAdviceType | null;
  session?: ISession | null;
}

export const defaultValue: Readonly<ISessionHealthyLivingAdvice> = {};
