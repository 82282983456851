import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionType from './session-type';
import SessionTypeDetail from './session-type-detail';
import SessionTypeUpdate from './session-type-update';
import SessionTypeDeleteDialog from './session-type-delete-dialog';

const SessionTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionType />} />
    <Route path="new" element={<SessionTypeUpdate />} />
    <Route path=":id">
      <Route index element={<SessionTypeDetail />} />
      <Route path="edit" element={<SessionTypeUpdate />} />
      <Route path="delete" element={<SessionTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionTypeRoutes;
