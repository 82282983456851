import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AdviceToPatientType from './advice-to-patient-type';
import AdviceToPatientTypeDetail from './advice-to-patient-type-detail';
import AdviceToPatientTypeUpdate from './advice-to-patient-type-update';
import AdviceToPatientTypeDeleteDialog from './advice-to-patient-type-delete-dialog';

const AdviceToPatientTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AdviceToPatientType />} />
    <Route path="new" element={<AdviceToPatientTypeUpdate />} />
    <Route path=":id">
      <Route index element={<AdviceToPatientTypeDetail />} />
      <Route path="edit" element={<AdviceToPatientTypeUpdate />} />
      <Route path="delete" element={<AdviceToPatientTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AdviceToPatientTypeRoutes;
