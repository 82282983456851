import { IGP } from 'app/shared/model/gp.model';
import { IPrescriptionMedicine } from 'app/shared/model/prescription-medicine.model';
import { IPatient } from 'app/shared/model/patient.model';
import { ISession } from 'app/shared/model/session.model';

export interface IPrescription {
  id?: number;
  gP?: IGP | null;
  prescriptionMedicines?: IPrescriptionMedicine[] | null;
  patients?: IPatient[] | null;
  sessions?: ISession[] | null;
}

export const defaultValue: Readonly<IPrescription> = {};
