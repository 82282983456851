import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isValidDate, s2ab } from 'app/shared/util/utits';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import { deleteUserwithRoles, downloadUserRoleRecords, getAllRoles, listAllRoles } from '../admin-reducer';
import { IListingRoles } from '../interface';

const intialState: IListingRoles = {
  size: 20,
  page: 0,
  searchQuery: '',
  role: '',
  from: '',
  to: '',
};
const useManageRole = () => {
  const pageNo = useAppSelector(state => state.admin.rolesPage);
  const limit = useAppSelector(state => state.admin.rolesRows);
  const totalCount = useAppSelector(state => state.admin.rolesCount);

  const allRolesList = useAppSelector(state => state.admin.rolesListing);
  const allRoles = useAppSelector(state => state.admin.roles);

  const allRolesListing = useMemo(() => {
    return allRolesList.map((elem, index) => {
      const { internalUser } = elem;
      const { activated, isPasswordSet, authorities } = internalUser;
      const status = activated && isPasswordSet ? 'Active' : isPasswordSet ? (activated ? 'Active' : 'Inactive') : 'Invited';

      const updatedAuthorities = authorities.map(authority => {
        if (authority.name === 'CLIENT') {
          return { ...authority, name: 'Pharmacy Contractor' };
        } else if (authority.name === 'ROLE_ADMIN') {
          return { ...authority, name: 'Admin' };
        } else if (authority.name === 'MANAGER_DEO') {
          return { ...authority, name: 'Manager DEO' };
        } else if (authority.name === 'LEAD_DEO') {
          return { ...authority, name: 'Lead DEO' };
        }
        return authority;
      });

      const formattedAuthorities = updatedAuthorities.map(auth => auth.name).join(', ');

      return {
        ...elem,
        index: index + 1,
        internalUser: {
          ...internalUser,
          status,
          authorities: updatedAuthorities,
          formattedAuthorities, // Add formatted authorities
        },
      };
    });
  }, [allRolesList]);

  const formatAuthorities = authorities => {
    if (!authorities || !authorities.length) {
      return '';
    }
    return authorities.map(auth => auth.role).join(', ');
  };

  const [searchCreteria, setsearchCreteria] = useState<IListingRoles>(intialState);
  const [open, setOpen] = useState(false);
  const [userId, setUserID] = useState();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const emptyRowCount = 10;
  const emptyCellCount = 8;

  const setUserIdAndOpenStatus = useCallback(id => {
    setUserID(id);
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = useCallback(item => {
    navigate(`/admin/edit-role/${item.id}`);
    localStorage.setItem('lastActiveTabAdmin', JSON.stringify({ selectedColor: 'four', index: 4 }));
  }, []);

  const handleDelete = useCallback(async id => {
    const res = await dispatch(deleteUserwithRoles(id));
    if (res.type === 'manage_roles/delete_users/fulfilled') {
      toast.success('User deleted successfully');
      listAllRolesWithDateRange();
      setOpen(false);
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    const params = {
      ...searchCreteria,
      page: newPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: newPage,
    });
    dispatch(listAllRoles(params));
  };

  const handleChangeRowsPerPage = event => {
    const pagePerRow = event.target.value;
    const params = {
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    };
    setsearchCreteria({
      ...searchCreteria,
      page: 0,
      size: pagePerRow,
    });
    dispatch(listAllRoles(params));
  };

  // Your debounce function
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args); // Pass the arguments to the debounced function
      }, delay);
    };
  }, []);

  // Your search function
  const handleSearch = value => {
    // You can also perform your API call here with the value
    const params = {
      ...searchCreteria,
      searchQuery: value,
    };

    dispatch(listAllRoles(params));
  };

  const debouncedSearch = debounce(handleSearch, 1500);

  const handleSearchChange = event => {
    const { value } = event.target;
    setsearchCreteria({
      ...searchCreteria,
      searchQuery: value,
    });
    debouncedSearch(value); // Pass the input value to debouncedSearch
  };

  const handledateFrom = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      from: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      from: date,
    });
    dispatch(listAllRoles(params));
  };
  const handledateTill = e => {
    const date = isValidDate(e);

    const params = {
      ...searchCreteria,
      to: date,
    };
    setsearchCreteria({
      ...searchCreteria,
      to: date,
    });
    dispatch(listAllRoles(params));
  };

  const handleRole = event => {
    let value = event.target.value;
    value = value === 'All Role' ? '' : value;
    const params = {
      ...searchCreteria,
      role: value,
    };
    setsearchCreteria({
      ...searchCreteria,
      role: value,
    });
    dispatch(listAllRoles(params));
  };

  const handleRefresh = useCallback(() => {
    const params = {
      ...intialState,
    };

    setsearchCreteria({
      ...intialState,
      role: '',
      from: '',
      to: '',
      searchQuery: '',
    });

    dispatch(listAllRoles(params));
  }, []);

  const downloadRolesInExcelFile = useCallback(async () => {
    const res: any = await dispatch(downloadUserRoleRecords(searchCreteria));

    if (res.type === 'manage_roles/download_all_role_records/fulfilled') {
      const rolesRecord = res.payload.data.results;

      const data = rolesRecord.map(elem => {
        return {
          Name: elem.internalUser.firstName,
          Role: elem.internalUser.authorities.map(({ name }) => name).join(','),
          'Contact No.': elem.contact_number,
          'Email Address': elem.internalUser.email,
        };
      });

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Roles');

      // Generate XLSX data in binary form
      const xlsxData = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

      // Convert the binary data to a Blob
      const blob = new Blob([s2ab(xlsxData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      // Create a download link for the Excel file
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'manage_roles_record.xlsx'; // Set the file name here
      document.body.appendChild(a);
      a.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    }
  }, [searchCreteria]);

  const fetchAllRoles = useCallback(() => {
    dispatch(getAllRoles());
  }, []);

  const listAllRolesWithDateRange = useCallback(() => {
    dispatch(listAllRoles(searchCreteria));
  }, []);

  useEffect(() => {
    fetchAllRoles();
    listAllRolesWithDateRange();
  }, []);

  const states = {
    pageNo,
    limit,
    totalCount,
    allRolesListing,
    allRoles,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    open,
    userId,
  };
  const handlers = {
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    downloadRolesInExcelFile,
    handleClose,
    handleDelete,
    setOpen,
    setUserIdAndOpenStatus,
    handleEdit,
  };

  return {
    states,
    handlers,
  };
};

export default useManageRole;
