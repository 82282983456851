import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionPreviouslyDeliveredNoType from './session-previously-delivered-no-type';
import SessionPreviouslyDeliveredNoTypeDetail from './session-previously-delivered-no-type-detail';
import SessionPreviouslyDeliveredNoTypeUpdate from './session-previously-delivered-no-type-update';
import SessionPreviouslyDeliveredNoTypeDeleteDialog from './session-previously-delivered-no-type-delete-dialog';

const SessionPreviouslyDeliveredNoTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionPreviouslyDeliveredNoType />} />
    <Route path="new" element={<SessionPreviouslyDeliveredNoTypeUpdate />} />
    <Route path=":id">
      <Route index element={<SessionPreviouslyDeliveredNoTypeDetail />} />
      <Route path="edit" element={<SessionPreviouslyDeliveredNoTypeUpdate />} />
      <Route path="delete" element={<SessionPreviouslyDeliveredNoTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionPreviouslyDeliveredNoTypeRoutes;
