import { TranslatorContext, Storage } from 'react-jhipster';

import { setLocale } from 'app/shared/reducers/locale';

TranslatorContext.setDefaultLocale('en');
TranslatorContext.setRenderInnerTextForMissingKeys(false);

export const languages: any = {
  en: { name: 'English' },
  // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
};

const compareLocales = (a, b) => {
  const localeA = languages[a].name.toUpperCase();
  const localeB = languages[b].name.toUpperCase();

  if (localeA < localeB) {
    return 1; // Locale A should come after Locale B
  } else if (localeA > localeB) {
    return -1; // Locale A should come before Locale B
  } else {
    return 0; // They are equal
  }
};

export const locales = Object.keys(languages).sort(compareLocales);

export const registerLocale = store => {
  store.dispatch(setLocale(Storage.session.get('locale', 'en')));
};
