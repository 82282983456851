import { ISessionPharmacistAgreedAction } from 'app/shared/model/session-pharmacist-agreed-action.model';

export interface IPharmacistAgreedActionType {
  id?: number;
  name?: string | null;
  parent?: IPharmacistAgreedActionType | null;
  sessionPharmacistAgreedActions?: ISessionPharmacistAgreedAction[] | null;
}

export const defaultValue: Readonly<IPharmacistAgreedActionType> = {};
