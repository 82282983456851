import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPrescription } from 'app/shared/model/prescription.model';
import { getEntities as getPrescriptions } from 'app/entities/prescription/prescription.reducer';
import { IMedicine } from 'app/shared/model/medicine.model';
import { getEntities as getMedicines } from 'app/entities/medicine/medicine.reducer';
import { IPrescriptionMedicine } from 'app/shared/model/prescription-medicine.model';
import { getEntity, updateEntity, createEntity, reset } from './prescription-medicine.reducer';

export const PrescriptionMedicineUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const prescriptions = useAppSelector(state => state.prescription.entities);
  const medicines = useAppSelector(state => state.medicine.entities);
  const prescriptionMedicineEntity = useAppSelector(state => state.prescriptionMedicine.entity);
  const loading = useAppSelector(state => state.prescriptionMedicine.loading);
  const updating = useAppSelector(state => state.prescriptionMedicine.updating);
  const updateSuccess = useAppSelector(state => state.prescriptionMedicine.updateSuccess);

  const handleClose = () => {
    navigate('/prescription-medicine');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPrescriptions({}));
    dispatch(getMedicines({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...prescriptionMedicineEntity,
      ...values,
      prescription: prescriptions.find(it => it.id.toString() === values.prescription.toString()),
      medicine: medicines.find(it => it.id.toString() === values.medicine.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...prescriptionMedicineEntity,
          prescription: prescriptionMedicineEntity?.prescription?.id,
          medicine: prescriptionMedicineEntity?.medicine?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="pharmacySolutionsApp.prescriptionMedicine.home.createOrEditLabel" data-cy="PrescriptionMedicineCreateUpdateHeading">
            <Translate contentKey="pharmacySolutionsApp.prescriptionMedicine.home.createOrEditLabel">
              Create or edit a PrescriptionMedicine
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="prescription-medicine-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                id="prescription-medicine-prescription"
                name="prescription"
                data-cy="prescription"
                label={translate('pharmacySolutionsApp.prescriptionMedicine.prescription')}
                type="select"
              >
                <option value="" key="0" />
                {prescriptions
                  ? prescriptions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="prescription-medicine-medicine"
                name="medicine"
                data-cy="medicine"
                label={translate('pharmacySolutionsApp.prescriptionMedicine.medicine')}
                type="select"
              >
                <option value="" key="0" />
                {medicines
                  ? medicines.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/prescription-medicine" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default PrescriptionMedicineUpdate;
