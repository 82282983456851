import { IPrescription } from 'app/shared/model/prescription.model';
import { ISession } from 'app/shared/model/session.model';

export interface IGP {
  id?: number;
  name?: string;
  email?: string;
  telephone?: string;
  address?: string;
  prescriptions?: IPrescription[] | null;
  sessions?: ISession[] | null;
}

export const defaultValue: Readonly<IGP> = {};
