import { IPatientProvidedInformationType } from 'app/shared/model/patient-provided-information-type.model';
import { ISession } from 'app/shared/model/session.model';

export interface ISessionPatientProvidedInformation {
  id?: number;
  patientProvidedInformationType?: IPatientProvidedInformationType | null;
  session?: ISession | null;
}

export const defaultValue: Readonly<ISessionPatientProvidedInformation> = {};
