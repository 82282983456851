import { IAdviceToPatientType } from 'app/shared/model/advice-to-patient-type.model';
import { ISession } from 'app/shared/model/session.model';

export interface ISessionAdviceToPatient {
  id?: number;
  adviceToPatientType?: IAdviceToPatientType | null;
  session?: ISession | null;
}

export const defaultValue: Readonly<ISessionAdviceToPatient> = {};
