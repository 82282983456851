import React, { useState, useEffect } from 'react';
import { Grid, Tooltip, IconButton, Select, MenuItem, CircularProgress, Chip } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterHook from '../hooks/filterHook';
import moment from 'moment';
import OverviewHook from 'app/modules/deo-work-flow/hooks/overviewHook';
import overviewHook from 'app/modules/deo-work-flow/hooks/overviewHook';
import { generalMonthArray, sessionStatusesOverview } from 'app/shared/util/utits';
import { useNavigate } from 'react-router-dom';
// const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const SelectFilter = props => {
  const { states } = FilterHook();

  // const { statesSecondary, handlersSecondary } = overviewHook();
  // const { selectedMonth } = statesSecondary;
  // const { handleSelectMonth } = handlersSecondary;
  const {
    handleSelectPharmacy,
    handleSelectMonth,
    handleSelectBranch,
    handleSelectService,
    handleSelectStatus,
    searchCreteria,
    isOverview,
    selectedPharmacies,
  } = props;

  const { allServicesPharmacist, allPharmacies, allSessionStatuses, allGroupNames } = states;
  const [selectedGroup, setSelectedGroup] = useState('');
  const [pharmacyList, setPharmacyList] = useState([]);

  const sessionStatusesOverdue = [
    { id: 2, name: 'No Answered' },
    { id: 5, name: 'Intervention Pending' },
    { id: 6, name: 'Followup Pending' },
  ];

  useEffect(() => {
    getFilteredPharmacies(selectedGroup);
  }, [selectedGroup, allGroupNames]);

  const handleGroupChange = event => {
    const groupId = event.target.value;
    setSelectedGroup(groupId);
    handleSelectBranch(event);
  };

  const getFilteredPharmacies = groupId => {
    if (groupId === '' || groupId === 'All Groups') {
      setPharmacyList(allPharmacies);
    } else {
      const selectedGroupObj = allGroupNames.find(group => group.id === groupId);
      setPharmacyList(selectedGroupObj ? selectedGroupObj.pharmacy : []);
    }
  };

  return (
    <Grid lg={6.5} xs={6.5} style={{ backgroundColor: '', width: '100%', display: 'flex', height: '100%' }}>
      <Select
        id="demo-simple-select"
        value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
        onChange={handleGroupChange}
        style={{
          height: '40px',
          width: '59%',
          fontSize: '12px',
          backgroundColor: '#EFEFEF',
          color: '#393939',
          marginRight: '10px',
        }}
      >
        <MenuItem value={'All Groups'}>All Groups</MenuItem>
        {allGroupNames.map(elem => {
          return (
            <MenuItem key={elem.id} value={elem.id}>
              {elem.name}
            </MenuItem>
          );
        })}
      </Select>

      {/*<Select*/}
      {/*  id="demo-simple-select"*/}
      {/*  value={searchCreteria.pharmacy === '' ? 'All Pharmacies' : Number(searchCreteria.pharmacy)}*/}
      {/*  onChange={handleSelectPharmacy}*/}
      {/*  style={{*/}
      {/*    height: '40px',*/}
      {/*    width: '66%',*/}
      {/*    fontSize: '12px',*/}
      {/*    backgroundColor: '#EFEFEF',*/}
      {/*    color: '#393939',*/}
      {/*    marginRight: '10px',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>*/}
      {/*  {pharmacyList.map(elem => {*/}
      {/*    return (*/}
      {/*      <MenuItem key={elem.id} value={elem.id}>*/}
      {/*        {elem.name}*/}
      {/*      </MenuItem>*/}
      {/*    );*/}
      {/*  })}*/}
      {/*</Select>*/}
      <Select
        multiple
        value={selectedPharmacies}
        onChange={handleSelectPharmacy}
        displayEmpty
        renderValue={selected => {
          if (selected.length === 0) {
            return 'All Pharmacies';
          }
          const selectedPharmacyNames = selected.map(id => {
            const pharmacy = allPharmacies.find(pharma => pharma.id === id);
            return pharmacy ? pharmacy.name : '';
          });
          return (
            <div>
              {selectedPharmacyNames.map(name => (
                <Chip key={name} label={name} size="small" />
              ))}
            </div>
          );
        }}
        style={{
          height: '40px',
          width: '95%',
          fontSize: '12px',
          backgroundColor: '#EFEFEF',
          color: '#393939',
        }}
      >
        <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
        {pharmacyList.map(elem => (
          <MenuItem key={elem.id} value={elem.id}>
            {elem.name}
          </MenuItem>
        ))}
      </Select>

      <Select
        id="demo-simple-select"
        value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
        onChange={handleSelectService}
        style={{
          height: '40px',
          width: '92%',
          fontSize: '12px',
          backgroundColor: '#EFEFEF',
          color: '#393939',
          marginRight: '10px',
        }}
      >
        <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
        {allServicesPharmacist.map(elem => {
          return (
            <MenuItem key={elem.id} value={elem.id}>
              {elem.firstName}
            </MenuItem>
          );
        })}
      </Select>

      <Select
        id="demo-simple-select"
        value={searchCreteria.status === '' ? 'Status' : searchCreteria.status}
        onChange={handleSelectStatus}
        style={{
          height: '40px',
          width: '40%',
          fontSize: '12px',
          backgroundColor: '#EFEFEF',
          color: '#393939',
          marginRight: '10px',
        }}
      >
        <MenuItem value={'Status'}>Status</MenuItem>

        {isOverview
          ? sessionStatusesOverview.map(elem => {
              return (
                <MenuItem key={elem.id} value={elem.name}>
                  {elem.name}
                </MenuItem>
              );
            })
          : sessionStatusesOverdue.map(elem => {
              return (
                <MenuItem key={elem.id} value={elem.name}>
                  {elem.name}
                </MenuItem>
              );
            })}
      </Select>

      <Select
        id="demo-simple-select"
        disabled={
          searchCreteria.status !== 'Completed NMS' &&
          searchCreteria.status !== 'Completed Interventions' &&
          searchCreteria.status !== 'Completed Follow-ups'
        }
        value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
        onChange={handleSelectMonth}
        style={{
          height: '40px',
          width: '40%',
          fontSize: '12px',
          backgroundColor: '#EFEFEF',
          color: '#393939',
          marginRight: '10px',
        }}
      >
        {generalMonthArray.map((month, index) => (
          <MenuItem key={index} value={month}>
            {month}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export const DateFilter = props => {
  const { handledateFrom, handledateTill, searchCreteria, handleSelectMonth, selectedMonth } = props;
  return (
    <>
      <Grid lg={3.9} xs={3.9} style={{ backgroundColor: '', width: '100%', display: 'flex', height: '100%' }}>
        <Tooltip title="Date From">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker
              className="clock"
              format="DD/MM/YYYY"
              value={searchCreteria.from === '' ? null : searchCreteria.from}
              onChange={newValue => handledateFrom(newValue)}
              sx={{ marginRight: '10px', border: '', width: '100%', height: '80%' }}
            />
          </LocalizationProvider>
        </Tooltip>
        <Tooltip title="Date Till">
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <DatePicker
              value={searchCreteria.to === '' ? null : searchCreteria.to}
              className="clock"
              format="DD/MM/YYYY"
              onChange={newValue => handledateTill(newValue)}
              sx={{ marginRight: '20px', border: '', width: '100%', height: '100%' }}
            />
          </LocalizationProvider>
        </Tooltip>
      </Grid>
    </>
  );
};

export const ActionsFilter = (props: any) => {
  const { getDownloadData, loadingDownload } = props;
  const navigate = useNavigate();
  const handleFileUpload = () => {
    navigate('/operator/dashboard/session-deo-file-uploaded');
  };
  return (
    <Grid
      lg={2.2}
      xs={2.2}
      style={{ backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '2px', height: '100%' }}
    >
      <Tooltip title="Download">
        {loadingDownload ? (
          <CircularProgress />
        ) : (
          <IconButton
            onClick={getDownloadData}
            style={{
              backgroundColor: '#7CB6F8',
              marginLeft: 25,
              height: 35,
              width: 35,
              padding: '0px',
              justifyContent: 'center',
              borderRadius: 5,
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
          </IconButton>
        )}
      </Tooltip>

      <Tooltip title="Upload">
        <IconButton
          onClick={handleFileUpload}
          style={{
            backgroundColor: '#21AD64',
            height: 34,
            width: 100,
            padding: '0px',
            display: 'flex',
            borderRadius: 5,
            cursor: 'pointer',
            transition: 'background-color 0.3s',
            marginLeft: 10, // Add margin here
          }}
        >
          <h1 style={{ fontSize: 12, color: 'white', marginTop: 10 }}>Upload</h1>
        </IconButton>
      </Tooltip>
    </Grid>
  );
};
