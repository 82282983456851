import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionAdviceToPatient from './session-advice-to-patient';
import SessionAdviceToPatientDetail from './session-advice-to-patient-detail';
import SessionAdviceToPatientUpdate from './session-advice-to-patient-update';
import SessionAdviceToPatientDeleteDialog from './session-advice-to-patient-delete-dialog';

const SessionAdviceToPatientRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionAdviceToPatient />} />
    <Route path="new" element={<SessionAdviceToPatientUpdate />} />
    <Route path=":id">
      <Route index element={<SessionAdviceToPatientDetail />} />
      <Route path="edit" element={<SessionAdviceToPatientUpdate />} />
      <Route path="delete" element={<SessionAdviceToPatientDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionAdviceToPatientRoutes;
