import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PatientAgreedActionType from './patient-agreed-action-type';
import PatientAgreedActionTypeDetail from './patient-agreed-action-type-detail';
import PatientAgreedActionTypeUpdate from './patient-agreed-action-type-update';
import PatientAgreedActionTypeDeleteDialog from './patient-agreed-action-type-delete-dialog';

const PatientAgreedActionTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PatientAgreedActionType />} />
    <Route path="new" element={<PatientAgreedActionTypeUpdate />} />
    <Route path=":id">
      <Route index element={<PatientAgreedActionTypeDetail />} />
      <Route path="edit" element={<PatientAgreedActionTypeUpdate />} />
      <Route path="delete" element={<PatientAgreedActionTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PatientAgreedActionTypeRoutes;
