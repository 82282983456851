import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@mui/material';
import React from 'react';
import { IRichDataTable } from '../interface';
import { NMS_STATUS } from 'app/config/constants';

function RichDataTable(props: IRichDataTable) {
  const {
    handleEdit,
    handleDelete,
    handleAssignPharmacy,
    handleConsent,
    dataIndexs,
    dataSource,
    headers,
    showPagination = true,
    showEditDeletButton = false,
    showConsentIcon = false,
  } = props;

  const renderStatusCell = item => {
    const text = item.internalUser.status;

    const colorBlock = [
      {
        name: 'Inactive',
        color: '#ABABAB',
      },
      {
        name: 'Active',
        color: '#C5F9DE',
      },
      {
        name: 'Invited',
        color: '#FBF4B3',
      },
    ];
    const color = colorBlock.find(elem => elem.name === text).color;

    return (
      <div style={{ backgroundColor: color, justifyContent: 'center', borderRadius: '8px', display: 'flex', padding: '6px' }}>{text}</div>
    );
  };

  const renderAssignPharmacy = item => {
    let text = item.appUsers?.[0]?.internalUser.firstName;
    let color = text ? '#000' : '#32BDEF';
    let textDecoration = text ? 'none' : 'underline';
    let cursor = text ? 'auto' : 'pointer';

    if (!item.activated) {
      text = 'Deactivated';
      color = 'darkgrey';
      textDecoration = 'none';
      cursor = 'default';
    } else {
      text = text ? text : 'Assign Pharmacy';
    }

    return (
      <div
        onClick={!item.activated || text === 'Deactivated' ? null : () => handleAssignPharmacy(item.id)}
        style={{
          color,
          textDecoration,
          cursor,
          borderRadius: '8px',
          display: 'flex',
          padding: '6px',
        }}
      >
        {text}
      </div>
    );
  };

  const renderCells = (renderCell, item) => {
    if (renderCell === 'Status') {
      return renderStatusCell(item);
    }
    if (renderCell === 'AssignTo') {
      return renderAssignPharmacy(item);
    }
  };

  return (
    <Paper sx={{ width: '100%', paddingTop: '15px', border: 'none' }}>
      <TableContainer sx={{ height: '54vh', border: 'none' }}>
        <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
          <TableHead sx={{ height: 30, border: 'none' }}>
            <TableRow
              sx={{
                height: 30,
                border: 'none',
                borderTop: '0.1px solid #dadce1',
                borderBottom: '0.1px solid #dadce1',
              }}
            >
              {headers.map((elem, index) => {
                return (
                  <TableCell
                    key={index}
                    sx={{
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    {elem}
                  </TableCell>
                );
              })}

              <TableCell style={{ backgroundColor: '#F5F6FA' }}></TableCell>
              <TableCell style={{ backgroundColor: '#F5F6FA' }}></TableCell>
              {showEditDeletButton && <TableCell style={{ backgroundColor: '#F5F6FA' }}></TableCell>}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.dataSource &&
              props.dataSource.length > 0 &&
              props.dataSource.map((item: any, index) => (
                <TableRow
                  key={index}
                  hover
                  role="complementary"
                  style={{
                    border: 'none',
                    borderTop: '0.1px solid #dadce1',
                    borderBottom: '0.1px solid #dadce1',
                  }}
                >
                  {dataIndexs.map((dataIndex, i) => (
                    <TableCell key={item?.[dataIndex]} style={{ fontSize: 12 }}>
                      {dataIndex.includes('internalUser.status') || dataIndex.includes('appUsers.internalUser.firstName')
                        ? renderCells(dataIndex.includes('appUsers.internalUser.firstName') ? 'AssignTo' : 'Status', item)
                        : dataIndex.includes('.')
                        ? dataIndex.split('.').reduce((obj, key) => (obj && obj[key]) || (Array.isArray(obj) && obj?.[0]?.[key]), item)
                        : item?.[dataIndex]}
                    </TableCell>
                  ))}

                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {showEditDeletButton && (
                    <TableCell style={{ fontSize: 12 }}>
                      <div style={{ justifyContent: 'center', display: 'flex', alignSelf: 'center' }}>
                        <Tooltip title="Edit">
                          <IconButton
                            onClick={() => handleEdit(item)}
                            style={{
                              backgroundColor: '',
                              marginLeft: 0,
                              height: 25,
                              width: 25,
                              padding: '10px',
                              display: 'flex',
                              borderRadius: 100,
                              cursor: 'pointer',
                              transition: 'background-color 0.3s',
                            }}
                          >
                            <img
                              src={'../../../../content/assets/Edit.png'}
                              alt="logo"
                              style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                            />
                          </IconButton>
                        </Tooltip>
                        {showConsentIcon && item?.sessionStatus === 'No Consent' && (
                          <Tooltip title="Update Consent">
                            <IconButton
                              onClick={() => {
                                handleConsent(item.id);
                              }}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                marginRight: 5,
                                height: 25,
                                width: 25,
                                // border: '1px solid lightgray',
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                // justifyContent: 'space-around',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                className={'actions_css'}
                                src={'../../../../content/assets/noconcent.png'} // Use the image URL from the array
                                alt="logo"
                                style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        <Tooltip title="Delete">
                          <IconButton
                            onClick={() => handleDelete(item.id)}
                            style={{
                              marginLeft: 0,
                              height: 25,
                              width: 25,
                              padding: '0px',
                              display: 'flex',
                              borderRadius: 100,
                              cursor: 'pointer',
                              transition: 'background-color 0.3s',
                            }}
                          >
                            <img
                              src={'../../../../content/assets/Delete.png'}
                              alt="logo"
                              style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}

            {/* ??????????????????????????????????????????????     */}

            {Array.from({ length: props.emptyRowCount }).map((_, rowIndex) => (
              <TableRow
                key={rowIndex}
                hover
                role="complementary"
                style={{
                  height: 50,
                  border: 'none',
                  borderTop: '0.1px solid #dadce1',
                  borderBottom: '0.1px solid #dadce1',
                }}
              >
                {Array.from({ length: props.emptyCellCount }).map((_, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    style={{
                      backgroundColor: '',
                      fontSize: 12,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  ></TableCell>
                ))}
                <TableCell />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showPagination && (
        <TablePagination
          style={{ fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' }}
          rowsPerPageOptions={[20, 50, 100]}
          component="div"
          count={Number(props.count)}
          rowsPerPage={Number(props.limit)}
          page={Number(props.page)}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          labelRowsPerPage={
            <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
          }
          labelDisplayedRows={({ from, to, count }) => {
            return (
              <div style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}>
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            );
          }}
        />
      )}
    </Paper>
  );
}

export default React.memo(RichDataTable);
