import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Button, Container, FormControlLabel, Grid, TextField, Typography, InputAdornment } from '@mui/material';
import './login.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getMultiplePharmacy, logout, setPharmacy } from 'app/shared/reducers/authentication';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import { Done, KeyboardArrowLeft, KeyboardArrowRight, Search } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { isObject } from 'formik';
import { getAllDeoOverviewSessions } from 'app/modules/deo-work-flow/deo-reducer';

const PharmacyModal = props => {
  const dispatch = useAppDispatch();
  const { pharmacy_list, account } = useAppSelector(state => state.authentication);
  const [pharmacyArray, setPharmacyArray] = useState(pharmacy_list);
  const [selectedPharmacy, setSelectedPharmacy] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const id = account.id;
    const param = {
      id,
      search: '',
    };
    dispatch(getMultiplePharmacy(param));
  }, [account]);

  useEffect(() => {
    setPharmacyArray(pharmacy_list);
  }, [pharmacy_list]);

  const handleSelectedPharmacy = pharmacy => {
    setSelectedPharmacy(pharmacy);
  };
  const handleSearch = e => {
    // You can also perform your API call here with the value
    const param = {
      id: account.id,
      search: e.target.value,
    };
    dispatch(getMultiplePharmacy(param));
  };

  const setPharmacyToLocal = () => {
    if (Object.keys(selectedPharmacy).length === 0) {
      toast.error('Please select pharmacy.');
    } else {
      dispatch(setPharmacy(selectedPharmacy)).then(r => {});
      if (localStorage.getItem('pharmacySelected')) {
        navigate('/login/gphc');
      } else {
        navigate('/login/pharmacy');
      }
    }
  };
  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg.png')`, // Ensure the URL is correct
        backgroundColor: '#5a5a5a',
        backgroundBlendMode: 'overlay',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          justifyContent: 'centre',
          alignItems: 'center',
        }}
      >
        <Container component="main" maxWidth="lg" sx={{ height: '100%', alignItems: 'center', paddingTop: 20, justifyContent: 'center' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography component="h1" fontWeight="600" variant="h6" color={'white'}>
              Select Pharmacy
            </Typography>
            <Typography component="p" variant="body2" color="white" sx={{ mb: 2 }}>
              Enter your details to sign in to your account
            </Typography>

            {/* <TextField
              style={{
                border: 'none',
                borderRadius: '20px',
                width: '50%',
              }}
              margin="normal"
              fullWidth
              id="username"
              // label="Enter Email"
              placeholder={'Search Pharmacy'}
              onChange={handleSearch}
              name="search"
              autoComplete="search"
              autoFocus
              className={'field-height'}
              InputProps={{
                style: { border: 'none', backgroundColor: 'white', borderRadius: '20px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
          </Box>
          <Grid
            container
            spacing={3} // Adjust the space between the items
            sx={{
              mt: 3,
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center', // Align items to the left
            }}
          >
            {pharmacyArray && pharmacyArray.length > 0 ? (
              pharmacyArray.map((entity, i) => (
                <Grid
                  item
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleSelectedPharmacy(entity);
                  }}
                  key={i}
                >
                  <Grid
                    item
                    style={{
                      height: 80,
                      width: '283px', // Full width inside the container
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backdropFilter: 'brightness(0.5)',
                      borderRadius: '10px',
                      border: selectedPharmacy === entity ? '4px solid #1565c0' : 'none', // Border style
                      backgroundColor: selectedPharmacy === entity ? 'white' : 'white',
                      padding: '10px', // Inner padding
                    }}
                  >
                    <Grid item>
                      <img src={'../../../content/images/pharmacy-icon.png'} alt="Icon" style={{ height: '35px' }} />
                    </Grid>
                    <Grid item sx={{}}>
                      <Typography component="p" variant="body2" fontWeight="600" color="#1565c0">
                        {entity.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Typography component="p" variant="body2" fontWeight="600" color="white">
                Pharmacy not found!
              </Typography>
            )}
          </Grid>

          <Grid sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                logoutUser();
              }}
              fullWidth
              variant="contained"
              color={'inherit'}
              className={'button-height'}
              sx={{ mt: 2, mb: 1, mr: 1, position: 'relative', width: 100 }}
            >
              <KeyboardArrowLeft sx={{ position: 'absolute', left: 5 }} />
              Back
            </Button>
            <Button
              onClick={() => {
                setPharmacyToLocal();
              }}
              fullWidth
              variant="contained"
              color="primary"
              className={'button-height'}
              sx={{ mt: 2, mb: 1, position: 'relative', width: 100 }}
            >
              Next
              <KeyboardArrowRight sx={{ position: 'absolute', right: 5 }} />
            </Button>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default PharmacyModal;
