import React, { useEffect, useState } from 'react';
import {
  Grid,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  MenuItem,
  Select,
  CircularProgress,
  Box,
} from '@mui/material';
import UploadedHook from '../hooks/uploadedHook';
import RichDataTable from '../rich-data-table/RichDataTable';
import { ActionsFilter, DateFilter } from '../filter/filter';
import moment from 'moment';
import { generalMonthArray } from 'app/shared/util/utits';
import Paper from '@mui/material/Paper';

const Uploaded = props => {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const { states, handlers } = UploadedHook();
  // const currentMonth = moment().format('MMMM');
  // const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  // const handleSelectMonth = event => {
  //   setSelectedMonth(event.target.value);
  //   localStorage.setItem('month', event.target.value);
  // };
  // useEffect(() => {
  //   localStorage.setItem('month', currentMonth);
  // }, []);

  const { emptyCellCount, emptyRowCount, searchCreteria, deoUploadedFiles, anchorEl, open, loading, progress } = states;
  const {
    handlePageChange,
    handleRowsChange,
    handleFileDelete,
    handleClose,
    handleEdit,
    handleClickOpen,
    handleSearchChange,
    handledateFrom,
    handledateTill,
    handleSelectMonth,
    getDownloadData,
    handleExcelFileDownloadSingleFileRecords,
  } = handlers;

  return (
    <Grid
      lg={9.5}
      xs={8}
      container
      style={{ backgroundColor: 'transparent', marginTop: 20, marginLeft: 20, maxHeight: '100%', marginBottom: 20, overflow: 'hidden' }}
    >
      <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: '20px' }}>
        <Grid item lg={10} xs={10} style={{ display: 'flex', alignItems: 'center' }}>
          <DateFilter
            selectedMonth={searchCreteria}
            handleSelectMonth={handleSelectMonth}
            handledateFrom={handledateFrom}
            handledateTill={handledateTill}
            searchCreteria={searchCreteria}
          />
          {/*<Select*/}
          {/*  id="demo-simple-select"*/}
          {/*  value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}*/}
          {/*  onChange={handleSelectMonth}*/}
          {/*  style={{*/}
          {/*    height: '40px',*/}
          {/*    width: '8%',*/}
          {/*    fontSize: '12px',*/}
          {/*    backgroundColor: '#EFEFEF',*/}
          {/*    color: '#393939',*/}
          {/*    marginRight: '10px',*/}
          {/*    marginLeft: '10px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {generalMonthArray.map((month, index) => (*/}
          {/*    <MenuItem key={index} value={month}>*/}
          {/*      {month}*/}
          {/*    </MenuItem>*/}
          {/*  ))}*/}
          {/*</Select>*/}
        </Grid>
        <Grid item lg={2} xs={2} style={{ display: 'flex', justifyContent: 'center', marginRight: '-3.5%' }}>
          <ActionsFilter getDownloadData={getDownloadData} />
        </Grid>
      </Grid>

      <Grid lg={11.6} xs={11.6} container style={{ marginTop: 40, backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            {/*<Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>*/}
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
            {/*</Grid>*/}
          </div>
        </Grid>
        <Paper sx={{ width: '100%', paddingTop: '15px', border: 'none' }}>
          <TableContainer sx={{ height: '54vh', border: 'none' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none' }}>
              <TableHead sx={{ border: 'none' }}>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  ></TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Uploaded Date
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Uploaded By
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deoUploadedFiles?.results &&
                  deoUploadedFiles.results.map((item, index) => (
                    <TableRow key={index} hover role="complementary" style={{ height: 50 }}>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Tooltip title="Download">
                            <IconButton
                              onClick={() => {
                                handleExcelFileDownloadSingleFileRecords(item.id);
                              }}
                              style={{
                                backgroundColor: '',
                                marginLeft: 10,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/download3.png'}
                                alt="logo"
                                style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEdit(item.id)}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Edit.png'}
                                alt="logo"
                                style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => handleClickOpen(item.id)}
                              style={{
                                backgroundColor: '',
                                marginLeft: 0,
                                height: 25,
                                width: 25,
                                padding: '10px',
                                display: 'flex',
                                borderRadius: 100,
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              <img
                                src={'../../../../content/assets/Delete.png'}
                                alt="logo"
                                style={{ height: '20px', marginRight: 0, marginLeft: 0 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {item?.name}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {moment(item?.createdDate).format('DD-MM-YYYY') || moment().format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {item?.createdBy}
                      </TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ height: '12%', fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px' }}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={deoUploadedFiles ? deoUploadedFiles['count'] : 0}
            rowsPerPage={deoUploadedFiles?.pageRequest?.size}
            page={deoUploadedFiles?.pageRequest?.page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsChange}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div
                data-page={deoUploadedFiles?.pageRequest?.page}
                style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}
              >
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </Paper>
      </Grid>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 0.02)', // Lighter color for backdrop
            backdropFilter: 'blur(0.5px)', // Apply the blur effect
          },
        }}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(255, 255, 255, 1)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Add a subtle box shadow
            border: 'none', // Remove the border
            borderRadius: 8, // Optionally, adjust the border radius
          },
        }}
      >
        <div
          style={{
            width: '450px',
            height: '240px',
            display: 'grid',
            alignItems: 'center',
            justifyItems: 'center',
            padding: '30px',
          }}
        >
          <DialogTitle style={{ color: '#3c6498', fontWeight: 600, display: 'flex', justifyContent: 'center' }}>Confirm Delete</DialogTitle>{' '}
          <DialogContent>
            <DialogContentText style={{ textAlign: 'center', color: '#393939' }}>Are you sure you want to delete?</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button
              onClick={handleClose}
              style={{
                color: '#000',
                backgroundColor: '#f4f4f4',
                width: '170px',
                height: '40px',
                border: '1px solid #d8d5d5',
              }}
            >
              <img src="../../../content/assets/Group236.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
              <span style={{ textTransform: 'capitalize' }}>cancel</span>
            </Button>

            <Button
              onClick={handleFileDelete}
              style={{
                color: '#FFF',
                backgroundColor: '#D43434',
                width: '170px',
                height: '40px',
                border: '1px solid #c13232',
              }}
              autoFocus
            >
              <img src="../../../content/assets/circle-tick-svgrepo-com.png" alt="Icon" style={{ width: '20px', marginRight: 6 }} />
              <span style={{ textTransform: 'capitalize' }}>yes, delete</span>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 1300,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};
export default Uploaded;
