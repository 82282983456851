import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import HealthyLivingAdviceType from './healthy-living-advice-type';
import HealthyLivingAdviceTypeDetail from './healthy-living-advice-type-detail';
import HealthyLivingAdviceTypeUpdate from './healthy-living-advice-type-update';
import HealthyLivingAdviceTypeDeleteDialog from './healthy-living-advice-type-delete-dialog';

const HealthyLivingAdviceTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<HealthyLivingAdviceType />} />
    <Route path="new" element={<HealthyLivingAdviceTypeUpdate />} />
    <Route path=":id">
      <Route index element={<HealthyLivingAdviceTypeDetail />} />
      <Route path="edit" element={<HealthyLivingAdviceTypeUpdate />} />
      <Route path="delete" element={<HealthyLivingAdviceTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default HealthyLivingAdviceTypeRoutes;
