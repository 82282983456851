import React, { useEffect, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Divider, Grid, IconButton, Typography, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { purple } from '@mui/material/colors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Login from 'app/modules/login/login';
import { IRootState } from 'app/shared/reducers';
import { getAllSessionsByPharmacy, partialUpdateEntity } from 'app/entities/session/session.reducer';
import { connect, useDispatch } from 'react-redux';
import { getServiceEntities } from 'app/entities/service/service.reducer';
import { NMS_STATUS, NMS_TYPES } from 'app/config/constants';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  borderRadius: 20,

  width: 70,
  backgroundColor: '#D61449',
  '&:hover': {
    backgroundColor: '#ff1744',
  },
}));

const labelOptions = {
  female: 'Patient has given his/her consent',
  male: 'Patient has not given his/her consent',
  other: 'Not contacted',
};

export const ConsentComponent = () => {
  const [index, setIndex] = React.useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const [selectedOption, setSelectedOption] = React.useState('');
  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleSessionConsent = () => {
    const data = {
      id: param.id,
      consent: true,
      sessionStatus: NMS_STATUS.IN_PROGRESS,
      sessionType: NMS_TYPES.INTERVENTION,
    };
    // @ts-ignore
    const result = dispatch(partialUpdateEntity(data));
    if (result.data.id) {
      handleRedirect();
    }
  };
  const handleFailSessionConsent = () => {
    const data = {
      id: param.id,
      consent: false,
      sessionStatus: NMS_STATUS.NO_CONSENT,
      sessionType: null,
    };
    // @ts-ignore
    const result = dispatch(partialUpdateEntity(data));
    if (result.data.id) {
      handleRedirect();
    }
  };

  const handleRedirect = () => {
    navigate(`patient/session/${param.id}`);
  };

  return (
    <Grid container justifyContent="center" style={{ marginTop: 50 }}>
      <Grid
        lg={10}
        item
        justifyContent="center"
        style={{ backgroundColor: 'white', height: 450, alignSelf: 'center', justifyContent: 'center' }}
      >
        <Grid lg={6} item justifyContent="center" sx={{ flexDirection: 'column', height: 300, marginLeft: 35, marginTop: 10 }}>
          <span style={{ fontSize: 20, fontWeight: 'bold' }}>Consent</span>
          <Divider style={{ backgroundColor: 'lightgray', height: 2, width: 500 }} />
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={selectedOption}
                onChange={handleOptionChange}
                name="radio-buttons-group"
                style={{ marginTop: 10 }}
              >
                {Object.keys(labelOptions).map(key => (
                  <FormControlLabel key={key} value={key} control={<Radio />} label={labelOptions[key]} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          {labelOptions[selectedOption] === 'Patient has given his/her consent' ? (
            <Stack spacing={2} direction="row" style={{ marginTop: 10 }}>
              <ColorButton variant="contained" onClick={handleSessionConsent}>
                Next
              </ColorButton>
            </Stack>
          ) : labelOptions[selectedOption] === 'Patient has not given his/her consent' ? (
            <Stack spacing={2} direction="row" style={{ marginTop: 10 }}>
              <Link
                to="/"
                style={{
                  textDecoration: 'none', // Remove underline
                  display: 'inline-block', // Ensures the link behaves like a block element
                }}
              >
                <ColorButton variant="contained" onClick={handleFailSessionConsent}>
                  Finish
                </ColorButton>
              </Link>
            </Stack>
          ) : labelOptions[selectedOption] === 'Not contacted' ? (
            <Stack spacing={2} direction="row" style={{ marginTop: 10 }}>
              <Link
                to="/"
                style={{
                  textDecoration: 'none', // Remove underline
                  display: 'inline-block', // Ensures the link behaves like a block element
                }}
              >
                <ColorButton variant="contained" onClick={handleFailSessionConsent}>
                  Finish
                </ColorButton>
              </Link>
            </Stack>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConsentComponent;
