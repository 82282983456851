import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PatientProvidedInformationType from './patient-provided-information-type';
import PatientProvidedInformationTypeDetail from './patient-provided-information-type-detail';
import PatientProvidedInformationTypeUpdate from './patient-provided-information-type-update';
import PatientProvidedInformationTypeDeleteDialog from './patient-provided-information-type-delete-dialog';

const PatientProvidedInformationTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PatientProvidedInformationType />} />
    <Route path="new" element={<PatientProvidedInformationTypeUpdate />} />
    <Route path=":id">
      <Route index element={<PatientProvidedInformationTypeDetail />} />
      <Route path="edit" element={<PatientProvidedInformationTypeUpdate />} />
      <Route path="delete" element={<PatientProvidedInformationTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PatientProvidedInformationTypeRoutes;
