import React, { useCallback, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ISessionParam } from './interface';
import RichDataTable from './rich-data-table/RichDataTable';
import useListingTable from './hooks/useListingTable';
import './listing-table.scss';
import { useAppSelector } from 'app/config/store';
import { Form, Formik } from 'formik';
import { generalMonthArray, sessionStatusesOverview } from 'app/shared/util/utits';

interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
interface ISessionConsent {
  id: any;
  consent: boolean;
  sessionStatus: any;
  sessionType: any;
}

export const AdminInterventionsTable = props => {
  const { states, handlers } = useListingTable();
  const loadingDownload = useAppSelector(state => state.admin.loadingAdminExcelDownload);

  const {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allServicesPharmacist,
    allSessionListing,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    allGroupNames,
    filteredPharmacies,
    selectedMonth,
    userId,
    open,
    openEditModal,
    initialStateFollow,
    selectedPharmacies,
  } = states;
  const {
    getDownloadData,
    handleExcelPharmacyNMSRecords,
    handleRefresh,
    handleSelectService,
    handleSelectPharmacy,
    handleSelectStatus,
    handleSelectMonth,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handledateFrom,
    handledateTill,
    setUserIdAndOpenDialogue,
    handleEdit,
    handleDelete,
    handleClose,
    handleCloseEditModal,
    onSubmit,
    handleConsent,
  } = handlers;

  const formatNumber = number => {
    return new Intl.NumberFormat().format(number);
  };

  return (
    <Grid
      lg={9.5}
      xs={8}
      container
      style={{ backgroundColor: 'transparent', marginTop: 20, marginLeft: 20, maxHeight: '100%', marginBottom: 20 }}
    >
      <Grid lg={11.8} xs={12} style={{ display: 'flex', marginTop: '2%', marginBottom: '1%', backgroundColor: '' }}>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.group === '' ? 'All Groups' : Number(searchCreteria.group)}
            onChange={handleSelectGroup}
            // displayEmpty
            style={{
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
              width: '95%',
              height: '40px',
            }}
          >
            <MenuItem value={'All Groups'}>All Groups</MenuItem>
            {allGroupNames.map((elem, index) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          {/*<Select*/}
          {/*  id="demo-simple-select"*/}
          {/*  value={searchCreteria.pharmacy === '' ? 'All Pharmacies' : Number(searchCreteria.pharmacy)}*/}
          {/*  onChange={handleSelectPharmacy}*/}
          {/*  // displayEmpty*/}
          {/*  style={{*/}
          {/*    fontSize: '12px',*/}
          {/*    backgroundColor: '#EFEFEF',*/}
          {/*    color: '#393939',*/}
          {/*    width: '95%',*/}
          {/*    height: '40px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>*/}
          {/*  {filteredPharmacies.map((elem, index) => {*/}
          {/*    return (*/}
          {/*      <MenuItem key={elem.id} value={elem.id}>*/}
          {/*        {elem.name}*/}
          {/*      </MenuItem>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Select>*/}
          <Select
            multiple
            value={selectedPharmacies}
            onChange={handleSelectPharmacy}
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return 'All Pharmacies';
              }
              const selectedPharmacyNames = selected.map(id => {
                const pharmacy = allPharmacies.find(pharma => pharma.id === id);
                return pharmacy ? pharmacy.name : '';
              });
              return (
                <div>
                  {selectedPharmacyNames.map(name => (
                    <Chip key={name} label={name} size="small" />
                  ))}
                </div>
              );
            }}
            style={{
              height: '40px',
              width: '95%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
            }}
          >
            <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
            {filteredPharmacies.map(elem => (
              <MenuItem key={elem.id} value={elem.id}>
                {elem.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.servicePharmacist === '' ? 'All Service Pharmacist' : searchCreteria.servicePharmacist}
            onChange={handleSelectService}
            // displayEmpty
            style={{
              width: '95%',
              height: '40px',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
            }}
          >
            <MenuItem value={'All Service Pharmacist'}>All Service Pharmacist</MenuItem>
            {allServicesPharmacist.map((elem, index) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.firstName}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.status === '' ? 'Status' : searchCreteria.status}
            onChange={handleSelectStatus}
            style={{
              height: '40px',
              width: '95%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
            }}
          >
            <MenuItem value={'Status'}>Status</MenuItem>
            {sessionStatusesOverview.map(elem => {
              return (
                <MenuItem key={elem.id} value={elem.name}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            disabled={
              searchCreteria.status !== 'Completed NMS' &&
              searchCreteria.status !== 'Completed Interventions' &&
              searchCreteria.status !== 'Completed Follow-ups'
            }
            value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
            onChange={handleSelectMonth}
            style={{
              height: '40px',
              width: '95%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
              marginRight: '10px',
            }}
          >
            {generalMonthArray.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid lg={3} xs={3} style={{ backgroundColor: '', width: '100%' }}>
          <Tooltip title="Date From">
            <div style={{ display: 'flex' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  value={searchCreteria.from === '' ? null : searchCreteria.from}
                  // className="clock"
                  format="DD/MM/YYYY"
                  onChange={newValue => handledateFrom(newValue)}
                  sx={{ marginTop: 0, marginLeft: 0, border: '', width: '90%', height: '80%' }}
                />
              </LocalizationProvider>
            </div>
          </Tooltip>
        </Grid>

        <Grid lg={3} xs={3} style={{ backgroundColor: '', width: '100%' }}>
          <Tooltip title="Date Till">
            <div style={{ display: 'flex', marginLeft: '-6%' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  value={searchCreteria.to === '' ? null : searchCreteria.to}
                  className="clock"
                  format="DD/MM/YYYY"
                  onChange={newValue => handledateTill(newValue)}
                  sx={{ marginTop: 0, marginLeft: 0, border: 'black', width: '90%', height: '80%' }}
                />
              </LocalizationProvider>
            </div>
          </Tooltip>
        </Grid>
        <Tooltip title="Total NMS by pharmacy">
          <IconButton
            onClick={() => handleExcelPharmacyNMSRecords()}
            style={{
              backgroundColor: '#7CB6F8',
              marginLeft: 5,
              height: 35,
              width: 35,
              padding: '0px',
              justifyContent: 'center',
              borderRadius: 5,
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}
          >
            <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
          </IconButton>
        </Tooltip>
        <Grid lg={3} xs={3} style={{ backgroundColor: '', width: '100%', marginLeft: '5px' }}>
          <Tooltip title="Total NMS">
            <div style={{ display: 'flex', width: '74px' }}>
              <Typography variant="h6">Total:</Typography>
              <Typography variant="h6" style={{ marginLeft: '2px' }}>
                {formatNumber(totalCount)}
              </Typography>
            </div>
          </Tooltip>
        </Grid>

        <Grid lg={4} xs={4} style={{ backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Refresh">
            <IconButton
              onClick={handleRefresh}
              style={{
                backgroundColor: '#7CB6F8',
                marginLeft: 5,
                height: 35,
                width: 35,
                padding: '0px',
                justifyContent: 'center',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
            </IconButton>
          </Tooltip>
          {loadingDownload ? (
            <CircularProgress />
          ) : (
            <Tooltip title="Download">
              <IconButton
                onClick={() => getDownloadData()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 35,
                  width: 35,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  src={'../../../../content/assets/download2.png'}
                  alt="logo"
                  style={{ height: '17px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>

        <RichDataTable
          limit={limit}
          page={pageNo}
          count={totalCount}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          emptyRowCount={emptyRowCount}
          emptyCellCount={emptyCellCount}
          dataIndexs={['index', 'patient.firstName', 'patient.telephone', 'patient.address', 'pharmacy.name', 'sessionStatus']}
          dataSource={allSessionListing}
          headers={['ID', 'Patient Name', 'Primary Contact', 'Address', 'Pharmacy', 'Status']}
          showEditDeletButton={true}
          handleDelete={setUserIdAndOpenDialogue}
          handleEdit={handleEdit}
          showConsentIcon={true}
          handleConsent={handleConsent}
        />
      </Grid>
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Formik enableReinitialize initialValues={initialStateFollow} onSubmit={onSubmit}>
          {({ values, handleSubmit, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit} noValidate>
                <div
                  style={{
                    width: 550,
                    height: 300,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '12%',
                  }}
                >
                  <h1
                    style={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: '#325C92',
                      marginTop: '10px',
                      textAlign: 'center',
                    }}
                  >
                    Update Follow-up Date
                  </h1>
                  <h1
                    style={{
                      fontSize: '0.8rem',
                      color: 'black',
                      marginTop: '12px',
                      textAlign: 'center',
                    }}
                  ></h1>
                  <div
                    style={{
                      marginTop: '6%',
                      width: '75%',
                      height: '12%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                      <DatePicker
                        className="clock"
                        format="DD/MM/YYYY"
                        value={values.followUpdDate}
                        onChange={newValue => setFieldValue('followUpdDate', newValue)}
                        sx={{ marginRight: '10px', border: '', width: '100%', height: '80%' }}
                      />
                    </LocalizationProvider>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: '8%',
                      marginBottom: '5%',
                      backgroundColor: '',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <IconButton
                      style={{
                        backgroundColor: '#EFEFEF',
                        height: '100%',
                        width: '28%',
                        border: '1px solid lightgray',
                        padding: '10px',
                        display: 'flex',
                        borderRadius: 3,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                      onClick={handleCloseEditModal}
                      // disabled={disabled}
                    >
                      <img
                        src={'../../../../content/assets/cancel2.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          fontWeight: 400,
                          marginLeft: 10,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          color: 'black',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Cancel
                      </h1>
                    </IconButton>

                    <IconButton
                      style={{
                        backgroundColor: '#21AD64',
                        marginLeft: '2%',
                        height: '100%',
                        width: '28%',
                        border: '1px solid lightgray',
                        padding: '10px',
                        display: 'flex',
                        borderRadius: 3,
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                      }}
                      type="submit"
                      // onClick={handleSessionSubmit}
                    >
                      <img
                        src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                      <h1
                        style={{
                          fontSize: 15,
                          marginTop: 8,
                          fontWeight: 400,
                          marginLeft: 10,
                          alignSelf: 'center',
                          justifyContent: 'center',
                          color: 'white',
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        Update
                      </h1>
                    </IconButton>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={() => handleDelete(userId)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default AdminInterventionsTable;
