import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import './login.scss';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Navigate, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { logout, setCurrentUserRole } from 'app/shared/reducers/authentication';
import { deleteUserwithRoles } from 'app/modules/admin-work-flow/admin-reducer';
import { toast } from 'react-toastify';
import { KeyboardArrowRight } from '@mui/icons-material';
import AdminIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import LeadIcon from '@mui/icons-material/SupervisorAccountOutlined';
import ManagerIcon from '@mui/icons-material/ManageAccountsOutlined';
import PharmacistIcon from '@mui/icons-material/VaccinesOutlined';
import ClientIcon from '@mui/icons-material/ContactsOutlined';
import UserIcon from '@mui/icons-material/Person2Outlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const RoleModal = props => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(state => state.authentication);
  const [roleArray, setRoleArray] = useState([]);
  const [value, setValue] = useState('');
  const navigate = useNavigate();

  const { isAuthenticated, isPharmacist, loginError, showModalLogin, isDEO, isLeadDEO, isManagerDEO, isAdmin, isClient } = useAppSelector(
    state => state.authentication
  );

  useEffect(() => {
    setRoleArray(account?.authorities);
  }, [account]);

  const handleSelectedRole = event => {
    setValue(event.target.value);
  };
  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };
  const handleRole = () => {
    if (value !== 'Select Role') {
      dispatch(setCurrentUserRole(value));
    } else {
      dispatch(setCurrentUserRole(null));
    }
    if (isAuthenticated && (isDEO || isLeadDEO || isManagerDEO) && (value === 'DEO' || value === 'LEAD_DEO' || value === 'MANAGER_DEO')) {
      navigate('/operator/dashboard');
    }
    if (isAuthenticated && isPharmacist && value === 'PHARMACIST') {
      navigate('/login/pharmacy');
    }

    if (isAuthenticated && isAdmin && value === 'ROLE_ADMIN') {
      navigate('/admin/dashboard');
    }

    if (isAuthenticated && isClient && value === 'CLIENT') {
      navigate('/client/dashboard');
    }
  };
  const formatRole = elem => {
    if (elem === 'ROLE_ADMIN') {
      return { label: 'Admin', icon: <AdminIcon fontSize="small" /> };
    } else if (elem === 'ROLE_USER') {
      return { label: 'User', icon: <UserIcon fontSize="small" /> };
    } else if (elem === 'MANAGER_DEO') {
      return { label: 'Manager Deo', icon: <ManagerIcon fontSize="small" /> };
    } else if (elem === 'LEAD_DEO') {
      return { label: 'Lead Deo', icon: <LeadIcon fontSize="small" /> };
    } else if (elem === 'DEO') {
      return { label: 'Deo', icon: <AccountBoxIcon fontSize="small" /> };
    } else if (elem === 'CLIENT') {
      return { label: 'Pharmacy Contractor', icon: <ClientIcon fontSize="small" /> };
    } else if (elem === 'PHARMACIST') {
      return { label: 'Pharmacist', icon: <PharmacistIcon fontSize="small" /> };
    } else {
      return { label: elem, icon: null };
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleRole();
    }
  };

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={5.8}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`, // Ensure the URL is correct
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
              <ArrowBackIcon onClick={logoutUser} />
            </Box>
            <img src={'../../../content/logos/final-logo.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight="600" variant="h6">
              Select Role
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              Select your designated role
            </Typography>
            <Grid item onKeyDown={handleKeyDown} sx={{ mt: 1 }}>
              <Select
                sx={{ textAlign: 'start' }}
                fullWidth
                id="demo-simple-select"
                onChange={handleSelectedRole}
                value={value === '' ? 'Select Role' : value}
                className={'field-height'}
              >
                <MenuItem value={'Select Role'}>Select Role</MenuItem>

                {roleArray?.map((elem, index) => {
                  const { label, icon } = formatRole(elem);
                  return (
                    <MenuItem value={elem} key={index}>
                      <Box display="flex" alignItems="center">
                        {icon && <Box mr={1}>{icon}</Box>}
                        {label}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={'button-height'}
                sx={{ mt: 2, mb: 1, position: 'relative' }}
                onClick={handleRole}
              >
                Next
                <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
              </Button>
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default RoleModal;
