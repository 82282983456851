import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PatientReportedIssueType from './patient-reported-issue-type';
import PatientReportedIssueTypeDetail from './patient-reported-issue-type-detail';
import PatientReportedIssueTypeUpdate from './patient-reported-issue-type-update';
import PatientReportedIssueTypeDeleteDialog from './patient-reported-issue-type-delete-dialog';

const PatientReportedIssueTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PatientReportedIssueType />} />
    <Route path="new" element={<PatientReportedIssueTypeUpdate />} />
    <Route path=":id">
      <Route index element={<PatientReportedIssueTypeDetail />} />
      <Route path="edit" element={<PatientReportedIssueTypeUpdate />} />
      <Route path="delete" element={<PatientReportedIssueTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PatientReportedIssueTypeRoutes;
