import './home.scss';
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { getAllSessionsByPharmacy, partialUpdateEntity, reset } from 'app/entities/session/session.reducer';
import { getServiceEntities } from 'app/entities/service/service.reducer';
import { TableCommponent } from '../pharmacist-work-flow/listing';
import { DashboardMenuCommp } from '../PharmacistSideMenu/dashboardMenuCommp';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { logout } from 'app/shared/reducers/authentication';

export const Home = props => {
  const role =
    localStorage.getItem('role') === null || localStorage.getItem('role') === '' || localStorage.getItem('role') === undefined
      ? ''
      : JSON.parse(localStorage.getItem('role'));
  const dispatch = useAppDispatch();
  const [sessionStatusValueSideBar, setSessionStatusValueSideBar] = useState('');
  const isAdmin =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN])) && role === 'ROLE_ADMIN';
  const isClient =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.CLIENT])) && role === 'CLIENT';
  const isPharmacist =
    useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.PHARMACIST])) && role === 'PHARMACIST';

  const { sessionByPharmacyEntities, sessionCounts, session_status, totalCount, session_type, rows, pageNo } = useAppSelector(
    state => state.session
  );
  const pageNum = pageNo;
  const rowNumber = rows;
  const { serviceEntities } = useAppSelector(state => state.service);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const data = {
      service_id: 1,
      session_status: '',
      session_type: '',
      pageNo: pageNum,
      rows: rowNumber,
      q: '',
      sort: {
        address: false,
        fullName: false,
        nextSession: false,
      },
    };
    dispatch(getServiceEntities({}));
    dispatch(getAllSessionsByPharmacy(data));
  }, []);

  const handleSessionStatusValue = status => {
    console.log({ status, session_status });
    if (status === null) {
      setSessionStatusValueSideBar(session_status);
    } else {
      setSessionStatusValueSideBar(status);
    }
  };

  return (
    <>
      <Grid lg={12} xs={12} sm={12} container>
        <DashboardMenuCommp
          sessionCounts={sessionCounts}
          sessionByPharmacyEntities={sessionByPharmacyEntities}
          getAllSessionsByPharmacy={getAllSessionsByPharmacy}
          partialUpdateEntity={partialUpdateEntity}
          reset={reset}
          pageNumber={pageNo}
          isAdmin={isAdmin}
          isClient={isClient}
          isPharmacist={isPharmacist}
          rowsPer={rows}
          totalCount={totalCount}
          sessionTypeValue={session_type}
          sessionStatusValue={session_status}
          handleSessionStatusValue={handleSessionStatusValue}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />

        <TableCommponent
          sessionByPharmacyEntities={sessionByPharmacyEntities}
          getAllSessionsByPharmacy={getAllSessionsByPharmacy}
          reset={reset}
          pageNumber={pageNo}
          rowsPer={rows}
          totalCount={totalCount}
          sessionTypeValue={session_type}
          sessionStatusValue={session_status}
          showMenu={showMenu}
        />
      </Grid>
    </>
  );
};

export default Home;
