import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  FormHelperText,
  FormControl,
} from '@mui/material';
import './set-password.scss';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useAppDispatch } from 'app/config/store';
import { logout, setUserPassword } from 'app/shared/reducers/authentication';
import { toast } from 'react-toastify';
import { KeyboardArrowRight } from '@mui/icons-material';

const SetPasswordModal = props => {
  const [initialState, setInitalState] = useState({ password: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validationSchema = () => {
    return yup.object().shape({
      password: yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = useCallback(
    async params => {
      const { password } = params;
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      const data = {
        token,
        password,
      };
      const res = await dispatch(setUserPassword(data));
      if (res.type === 'authentication/set_password/fulfilled') {
        toast.success('Password Set successfully');
        dispatch(logout());
        navigate('/login');
      }
    },
    [location]
  );

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`, // Ensure the URL is correct
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={'../../../content/logos/final-logo-02.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight={'600'} variant="h6">
              Set Your Password
            </Typography>
            <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                    <TextField
                      margin="normal"
                      required
                      autoFocus
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      label="Password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={togglePasswordVisibility}>
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.password && Boolean(errors.password)}
                    />
                    {touched.password && <FormHelperText error={true}>{(errors as any).password}</FormHelperText>}
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Confirm Password"
                      type={showConfirmPassword ? 'text' : 'password'}
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      id="confirmPassword"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" onClick={toggleConfirmPasswordVisibility}>
                            {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    />
                    {touched.confirmPassword && <FormHelperText error={true}>{(errors as any).confirmPassword}</FormHelperText>}
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={'button-height'}
                      sx={{ mt: 2, mb: 1, position: 'relative' }}
                      type={'submit'}
                    >
                      Submit
                      <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
                    </Button>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default SetPasswordModal;
