import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionStatus from './session-status';
import SessionStatusDetail from './session-status-detail';
import SessionStatusUpdate from './session-status-update';
import SessionStatusDeleteDialog from './session-status-delete-dialog';

const SessionStatusRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionStatus />} />
    <Route path="new" element={<SessionStatusUpdate />} />
    <Route path=":id">
      <Route index element={<SessionStatusDetail />} />
      <Route path="edit" element={<SessionStatusUpdate />} />
      <Route path="delete" element={<SessionStatusDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionStatusRoutes;
