import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AppUser from './app-user';
import UserRole from './user-role';
import Pharmacy from './pharmacy';
import Patient from './patient';
import GP from './gp';
import Service from './service';
import SessionType from './session-type';
import SessionStatus from './session-status';
import Session from './session';
import SessionPreviouslyDeliveredNoType from './session-previously-delivered-no-type';
import SessionConsultationMethod from './session-consultation-method';
import Medicine from './medicine';
import Prescription from './prescription';
import PrescriptionMedicine from './prescription-medicine';
import SessionMedicineUsedAsPrescribedNoType from './session-medicine-used-as-prescribed-no-type';
import PatientReportedIssueType from './patient-reported-issue-type';
import SessionPatientReportedIssue from './session-patient-reported-issue';
import PatientProvidedInformationType from './patient-provided-information-type';
import SessionPatientProvidedInformation from './session-patient-provided-information';
import AdviceToPatientType from './advice-to-patient-type';
import SessionAdviceToPatient from './session-advice-to-patient';
import PatientAgreedActionType from './patient-agreed-action-type';
import SessionPatientAgreedAction from './session-patient-agreed-action';
import PharmacistAgreedActionType from './pharmacist-agreed-action-type';
import SessionPharmacistAgreedAction from './session-pharmacist-agreed-action';
import HealthyLivingAdviceType from './healthy-living-advice-type';
import SessionHealthyLivingAdvice from './session-healthy-living-advice';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="app-user/*" element={<AppUser />} />
        <Route path="user-role/*" element={<UserRole />} />
        <Route path="pharmacy/*" element={<Pharmacy />} />
        <Route path="patient/*" element={<Patient />} />
        <Route path="gp/*" element={<GP />} />
        <Route path="service/*" element={<Service />} />
        <Route path="session-type/*" element={<SessionType />} />
        <Route path="session-status/*" element={<SessionStatus />} />
        <Route path="session/*" element={<Session />} />
        <Route path="session-previously-delivered-no-type/*" element={<SessionPreviouslyDeliveredNoType />} />
        <Route path="session-consultation-method/*" element={<SessionConsultationMethod />} />
        <Route path="medicine/*" element={<Medicine />} />
        <Route path="prescription/*" element={<Prescription />} />
        <Route path="prescription-medicine/*" element={<PrescriptionMedicine />} />
        <Route path="session-medicine-used-as-prescribed-no-type/*" element={<SessionMedicineUsedAsPrescribedNoType />} />
        <Route path="patient-reported-issue-type/*" element={<PatientReportedIssueType />} />
        <Route path="session-patient-reported-issue/*" element={<SessionPatientReportedIssue />} />
        <Route path="patient-provided-information-type/*" element={<PatientProvidedInformationType />} />
        <Route path="session-patient-provided-information/*" element={<SessionPatientProvidedInformation />} />
        <Route path="advice-to-patient-type/*" element={<AdviceToPatientType />} />
        <Route path="session-advice-to-patient/*" element={<SessionAdviceToPatient />} />
        <Route path="patient-agreed-action-type/*" element={<PatientAgreedActionType />} />
        <Route path="session-patient-agreed-action/*" element={<SessionPatientAgreedAction />} />
        <Route path="pharmacist-agreed-action-type/*" element={<PharmacistAgreedActionType />} />
        <Route path="session-pharmacist-agreed-action/*" element={<SessionPharmacistAgreedAction />} />
        <Route path="healthy-living-advice-type/*" element={<HealthyLivingAdviceType />} />
        <Route path="session-healthy-living-advice/*" element={<SessionHealthyLivingAdvice />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
