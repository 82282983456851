import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISession } from 'app/shared/model/session.model';
import { getEntities } from './session.reducer';

export const Session = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const sessionList = useAppSelector(state => state.session.entities);
  const loading = useAppSelector(state => state.session.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div>
      <h2 id="session-heading" data-cy="SessionHeading">
        <Translate contentKey="pharmacySolutionsApp.session.home.title">Sessions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="pharmacySolutionsApp.session.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/session/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="pharmacySolutionsApp.session.home.createLabel">Create new Session</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {sessionList && sessionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.consent">Consent</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.previouslyDelivered">Previously Delivered</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.medicineUsedAsPrescribed">Medicine Used As Prescribed</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.service">Service</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.sessionConsultationMethod">Session Consultation Method</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.sessionStatus">Session Status</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.sessionType">Session Type</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.pharmacy">Pharmacy</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.gP">G P</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.prescription">Prescription</Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.sessionPreviouslyDeliveredNoType">
                    Session Previously Delivered No Type
                  </Translate>
                </th>
                <th>
                  <Translate contentKey="pharmacySolutionsApp.session.sessionMedicineUsedAsPrescribedNoType">
                    Session Medicine Used As Prescribed No Type
                  </Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sessionList.map((session, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/session/${session.id}`} color="link" size="sm">
                      {session.id}
                    </Button>
                  </td>
                  <td>{session.consent ? 'true' : 'false'}</td>
                  <td>{session.previouslyDelivered ? 'true' : 'false'}</td>
                  <td>{session.medicineUsedAsPrescribed ? 'true' : 'false'}</td>
                  <td>{session.service ? <Link to={`/service/${session.service.id}`}>{session.service.id}</Link> : ''}</td>
                  <td>
                    {session.sessionConsultationMethod ? (
                      <Link to={`/session-consultation-method/${session.sessionConsultationMethod.id}`}>
                        {session.sessionConsultationMethod.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {session.sessionStatus ? (
                      <Link to={`/session-status/${session.sessionStatus.id}`}>{session.sessionStatus.id}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>{session.sessionType ? <Link to={`/session-type/${session.sessionType.id}`}>{session.sessionType.id}</Link> : ''}</td>
                  <td>{session.pharmacy ? <Link to={`/pharmacy/${session.pharmacy.id}`}>{session.pharmacy.id}</Link> : ''}</td>
                  <td>{session.gP ? <Link to={`/gp/${session.gP.id}`}>{session.gP.id}</Link> : ''}</td>
                  <td>
                    {session.prescription ? <Link to={`/prescription/${session.prescription.id}`}>{session.prescription.id}</Link> : ''}
                  </td>
                  <td>
                    {session.sessionPreviouslyDeliveredNoType ? (
                      <Link to={`/session-previously-delivered-no-type/${session.sessionPreviouslyDeliveredNoType.id}`}>
                        {session.sessionPreviouslyDeliveredNoType.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {session.sessionMedicineUsedAsPrescribedNoType ? (
                      <Link to={`/session-medicine-used-as-prescribed-no-type/${session.sessionMedicineUsedAsPrescribedNoType.id}`}>
                        {session.sessionMedicineUsedAsPrescribedNoType.id}
                      </Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/session/${session.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/session/${session.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button tag={Link} to={`/session/${session.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="pharmacySolutionsApp.session.home.notFound">No Sessions found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Session;
