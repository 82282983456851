import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionHealthyLivingAdvice from './session-healthy-living-advice';
import SessionHealthyLivingAdviceDetail from './session-healthy-living-advice-detail';
import SessionHealthyLivingAdviceUpdate from './session-healthy-living-advice-update';
import SessionHealthyLivingAdviceDeleteDialog from './session-healthy-living-advice-delete-dialog';

const SessionHealthyLivingAdviceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionHealthyLivingAdvice />} />
    <Route path="new" element={<SessionHealthyLivingAdviceUpdate />} />
    <Route path=":id">
      <Route index element={<SessionHealthyLivingAdviceDetail />} />
      <Route path="edit" element={<SessionHealthyLivingAdviceUpdate />} />
      <Route path="delete" element={<SessionHealthyLivingAdviceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionHealthyLivingAdviceRoutes;
