import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PrescriptionMedicine from './prescription-medicine';
import PrescriptionMedicineDetail from './prescription-medicine-detail';
import PrescriptionMedicineUpdate from './prescription-medicine-update';
import PrescriptionMedicineDeleteDialog from './prescription-medicine-delete-dialog';

const PrescriptionMedicineRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PrescriptionMedicine />} />
    <Route path="new" element={<PrescriptionMedicineUpdate />} />
    <Route path=":id">
      <Route index element={<PrescriptionMedicineDetail />} />
      <Route path="edit" element={<PrescriptionMedicineUpdate />} />
      <Route path="delete" element={<PrescriptionMedicineDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PrescriptionMedicineRoutes;
