import { IPatientAgreedActionType } from 'app/shared/model/patient-agreed-action-type.model';
import { ISession } from 'app/shared/model/session.model';

export interface ISessionPatientAgreedAction {
  id?: number;
  description?: string | null;
  patientAgreedActionType?: IPatientAgreedActionType | null;
  session?: ISession | null;
}

export const defaultValue: Readonly<ISessionPatientAgreedAction> = {};
