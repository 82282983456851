import { IPatientReportedIssueType } from 'app/shared/model/patient-reported-issue-type.model';
import { ISession } from 'app/shared/model/session.model';

export interface ISessionPatientReportedIssue {
  id?: number;
  description?: string | null;
  patientReportedIssueType?: IPatientReportedIssueType | null;
  session?: ISession | null;
}

export const defaultValue: Readonly<ISessionPatientReportedIssue> = {};
