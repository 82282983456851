import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import './login.scss';
import { useAppDispatch } from 'app/config/store';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { KeyboardArrowRight } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { resendOtp, verifyAndLogin } from 'app/shared/reducers/authentication';

interface LocationState {
  username?: string;
}

const OtpModal = props => {
  const dispatch = useAppDispatch();
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false); // Track whether OTP is sent
  const [resendDisabled, setResendDisabled] = useState(false); // Initially, allow sending OTP
  const [resendTimer, setResendTimer] = useState(30); // Timer countdown for 30 seconds
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = location.state as LocationState;

  useEffect(() => {
    // Start the countdown timer when resendDisabled is true
    if (resendDisabled && otpSent) {
      const intervalId = setInterval(() => {
        setResendTimer(prev => {
          if (prev === 1) {
            clearInterval(intervalId);
            setResendDisabled(false); // Enable resend after countdown ends
            return 30;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(intervalId); // Clean up the interval on component unmount
    }
  }, [resendDisabled, otpSent]);

  const handleOtpVerification = async () => {
    try {
      await dispatch(verifyAndLogin(username, otp));
      navigate('/role');
    } catch (error) {
      console.error('Error during OTP verification:', error);
    }
  };

  const handleSendOtp = async () => {
    await dispatch(resendOtp(username)); // Send OTP
    toast.success('Email sent, please check your inbox/spam.');
    setOtpSent(true); // Mark OTP as sent
    setResendDisabled(true); // Disable button to prevent resending
  };

  return (
    <Grid
      container
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
        <ArrowBackIcon onClick={() => navigate('/login')} />
      </Box>
      <Grid
        item
        xs={5.8}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={'../../../content/logos/final-logo.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight="600" variant="h6">
              Verify OTP
            </Typography>
            <Typography component="p" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              <strong>{username}</strong> please verify your login.
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              autoFocus
              name="otp"
              placeholder="Enter OTP"
              onChange={e => setOtp(e.target.value)}
              type="text"
              id="otp"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={8}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleOtpVerification}
                  sx={{ height: 48 }} // Set consistent height
                >
                  Verify OTP
                  <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={handleSendOtp}
                  disabled={resendDisabled}
                  sx={{ height: 48 }} // Set consistent height
                >
                  {otpSent ? (resendDisabled ? `Resend ${resendTimer}s` : 'Resend OTP') : 'Get OTP'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default OtpModal;
