import React from 'react';
import useListingTable from 'app/modules/deo-work-flow/hooks/useListingTable';
import moment from 'moment/moment';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Chip,
  OutlinedInput,
  InputLabel,
  TablePagination,
  Tooltip,
  Box,
  CircularProgress,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DailySummaryHook from 'app/modules/deo-work-flow/hooks/dailySummaryHook';
import { useAppSelector } from 'app/config/store';
import { useState } from 'react';
import { generalMonthArray } from 'app/shared/util/utits';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useNavigate } from 'react-router-dom';

export const DailySummary = props => {
  const { states, handlers } = DailySummaryHook();
  const {
    allPharmacies,
    allServicesPharmacist,
    selectedPharmacies,
    searchCreteria,
    filteredPharmacies,
    allDeoSessionStatsCurrentDate,
    allGroupNames,
    checkedTabs,
  } = states;
  const {
    handleSelectService,
    handleSelectPharmacy,
    handleSelectBranch,
    handleExcelFileStats,
    handleRefresh,
    handleSelectMonth,
    handledateFrom,
    handledateTill,
    handleTabClick,
  } = handlers;
  const role = localStorage.getItem('role');
  const loading = useAppSelector(state => state.deo.unSubmitRecordDeleteLoading);
  const loadingStats = useAppSelector(state => state.deo.loading);
  const navigate = useNavigate();

  const formatDate = date => {
    if (date) {
      return moment(date).format('DD-MM-YYYY');
    } else {
      return moment().format('DD-MM-YYYY');
    }
  };
  const gridData = [
    {
      icon: '../../../../content/assets/Total Clients.png',
      heading: 'Completed NMS',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_nms
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/Total Clients.png',
      heading: 'Completed Interventions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_intervention
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/Total Clients.png',
      heading: 'Completed Follow-ups',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_up
        : // allDeoSessionStatsCurrentDate?.results?.results?.[0].completed_follow_ups
          0,
    },
    {
      icon: '../../../../content/assets/Total Roles.png',
      heading: 'Pending Interventions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].intervention_pending
        : 0,
    },
    {
      icon: '../../../../content/assets/Follow Ups.png',
      heading: 'Pending Follow-ups',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].follow_up_pending
        : 0,
    },
    {
      icon: '../../../../content/assets/Total Roles.png',
      heading: 'Intervention Not Delivered',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].intervention_not_delivered
        : 0,
    },
    {
      icon: '../../../../content/assets/Total Roles.png',
      heading: 'No Answers',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].no_answers : 0,
    },
    {
      icon: '../../../../content/assets/Follow Ups.png',
      heading: 'No Consents',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].no_consents : 0,
    },
    {
      icon: '../../../../content/assets/Follow Ups.png',
      heading: 'Not New Medicine',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].not_new_medicine
        : 0,
    },
    {
      icon: '../../../../content/assets/Total Roles.png',
      heading: 'Not Eligible Conditions',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].not_eligible
        : 0,
    },
    {
      icon: '../../../../content/assets/Total Roles.png',
      heading: 'Patient Left Pharmacy',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].patient_left
        : 0,
    },
    {
      icon: '../../../../content/assets/Total Roles.png',
      heading: 'Incorrect Number',
      heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0]
        ? allDeoSessionStatsCurrentDate?.results?.results?.[0].incorrect_number
        : 0,
    },
    // {
    //   icon: '../../../../content/assets/Total Roles.png',
    //   heading: 'InEligible',
    //   heading2: allDeoSessionStatsCurrentDate?.results?.results?.[0] ? allDeoSessionStatsCurrentDate?.results?.results?.[0].in_eligible : 0,
    // },
  ];
  const handleFileUpload = () => {
    navigate('/operator/dashboard/session-deo-file-uploaded');
  };

  return (
    <Grid lg={9.7} item style={{ width: '100%', padding: '0 5%', backgroundColor: '#F5F6FA', marginTop: '0.4%' }}>
      <Grid lg={12} container style={{ width: '100%', backgroundColor: '#F5F6FA', marginTop: 35 }}>
        <Grid item lg={3} xs={3} style={{ display: 'flex', marginTop: 10 }}>
          <h1 style={{ fontSize: 14, color: 'black' }}>{'Today - ' + moment().format('DD MMMM, YYYY')}</h1>
        </Grid>
        <Grid item lg={6} xs={6} style={{ display: 'flex' }} />
        <Grid item lg={3} xs={3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 30 }}>
          <Tooltip title="Refresh">
            <IconButton
              onClick={handleRefresh}
              style={{
                backgroundColor: '#7CB6F8',
                height: 34,
                width: 35,
                padding: '0px',
                justifyContent: 'center',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download Today Stats">
            <IconButton
              onClick={() => handleExcelFileStats()}
              style={{
                backgroundColor: '#7CB6F8',
                marginLeft: 5,
                height: 34,
                width: 35,
                justifyContent: 'center',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginLeft: '1px' }} />
            </IconButton>
          </Tooltip>
          {(role === '"MANAGER_DEO"' || role === '"LEAD_DEO"' || role === '"DEO"') && (
            <Tooltip title="Upload">
              <IconButton
                onClick={handleFileUpload}
                style={{
                  backgroundColor: '#21AD64',
                  height: 34,
                  width: 100,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                  marginLeft: 10, // Add margin here
                }}
              >
                <h1 style={{ fontSize: 12, color: 'white', marginTop: 10 }}>Upload</h1>
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid lg={12} xs={12} style={{ display: 'flex', height: '9%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
            onChange={handleSelectBranch}
            style={{
              width: '20%',
              height: '80%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
              marginRight: '10px',
            }}
          >
            <MenuItem value={'All Groups'}>All Groups</MenuItem>
            {allGroupNames.map(elem => (
              <MenuItem key={elem.id} value={elem.id}>
                {elem.name}
              </MenuItem>
            ))}
          </Select>

          {(role === '"MANAGER_DEO"' || role === '"LEAD_DEO"' || role === '"DEO"') && (
            <Select
              id="demo-simple-select"
              value={searchCreteria.pharmacy === '' ? ['All Pharmacies'] : [Number(searchCreteria.pharmacy)]}
              onChange={handleSelectPharmacy}
              renderValue={selected => {
                if (selected[0] === 'All Pharmacies') {
                  return 'All Pharmacies';
                } else {
                  const selectedPharmacy = filteredPharmacies.find(pharmacy => pharmacy.id === selected[0]);
                  return selectedPharmacy ? selectedPharmacy.name : '';
                }
              }}
              style={{
                width: '20%',
                height: '80%',
                fontSize: '12px',
                backgroundColor: '#EFEFEF',
                color: '#393939',
                marginRight: '10px',
              }}
            >
              <MenuItem value={['All Pharmacies']}>All Pharmacies</MenuItem>
              {filteredPharmacies.map(elem => (
                <MenuItem key={elem.id} value={[elem.id]}>
                  {elem.name}
                </MenuItem>
              ))}
            </Select>
          )}

          <Select
            id="demo-simple-select"
            value={searchCreteria.pharmacist === '' ? 'All Service Pharmacists' : searchCreteria.pharmacist}
            onChange={handleSelectService}
            style={{
              width: '21%',
              height: '80%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
              marginRight: '10px',
            }}
          >
            <MenuItem value={'All Service Pharmacists'}>All Service Pharmacists</MenuItem>
            {allServicesPharmacist.map(elem => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.firstName}
                </MenuItem>
              );
            })}
          </Select>
          {role === '"ROLE_ADMIN"' && (
            <>
              <Select
                id="demo-simple-select"
                value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
                onChange={handleSelectMonth}
                style={{
                  width: '21%',
                  height: '80%',
                  fontSize: '12px',
                  backgroundColor: '#EFEFEF',
                  color: '#393939',
                  marginRight: '10px',
                }}
              >
                {generalMonthArray.map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              <Tooltip title="Date From">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    className="clock"
                    format="DD/MM/YYYY"
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    onChange={newValue => handledateFrom(newValue)}
                    sx={{
                      width: '15%',
                      fontSize: '12px',
                      backgroundColor: '#EFEFEF',
                      color: '#393939',
                      marginRight: '10px',
                    }}
                  />
                </LocalizationProvider>
              </Tooltip>
              <Tooltip title="Date Till">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock"
                    format="DD/MM/YYYY"
                    onChange={newValue => handledateTill(newValue)}
                    sx={{
                      width: '15%',
                      fontSize: '12px',
                      backgroundColor: '#EFEFEF',
                      color: '#393939',
                      marginRight: '10px',
                    }}
                  />
                </LocalizationProvider>
              </Tooltip>
            </>
          )}
        </Grid>
      </Grid>
      {role === '"ROLE_ADMIN"' && (
        <Grid lg={12} container style={{ width: '100%', backgroundColor: '#F5F6FA' }}>
          <FormControl style={{ marginTop: '10px', width: '100%' }}>
            <Select
              multiple
              value={selectedPharmacies}
              onChange={handleSelectPharmacy}
              displayEmpty
              renderValue={selected => {
                if (selected.length === 0) {
                  return 'All Pharmacies';
                }
                const selectedPharmacyNames = selected.map(id => {
                  const pharmacy = allPharmacies.find(pharmacy => pharmacy.id === id);
                  return pharmacy ? pharmacy.name : '';
                });
                return (
                  <div>
                    {selectedPharmacyNames.map(name => (
                      <Chip key={name} label={name} size="small" />
                    ))}
                  </div>
                );
              }}
              style={{
                height: '100%',
                width: '42%',
                fontSize: '12px',
                backgroundColor: '#EFEFEF',
                color: '#393939',
              }}
            >
              <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
              {filteredPharmacies.map(elem => (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid className={'daily-overview-tile'} lg={12} xs={4} style={{ width: '100%', marginBottom: 10, backgroundColor: '#F5F6FA' }}>
        {gridData.map((item, index) =>
          index % 4 === 0 ? (
            <Grid container key={index} style={{ width: '100%', justifyContent: 'start' }}>
              {gridData.slice(index, index + 4).map((subItem, subIndex) => {
                const isChecked = checkedTabs.includes(subItem.heading);
                return (
                  <Grid
                    key={subIndex}
                    lg={2.7}
                    style={{
                      height: 130,
                      justifyContent: 'center',
                      backgroundColor: isChecked ? 'lightgray' : 'white', // Change background if checked
                      marginTop: 40,
                      cursor: 'pointer', // Make it clickable
                      ...(subIndex !== 3 ? { marginRight: 20 } : {}),
                    }}
                    onClick={() => handleTabClick(subItem.heading)} // Pass the heading on click
                  >
                    <div
                      style={{
                        width: '100%',
                        height: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20,
                      }}
                    >
                      <img src={subItem.icon} alt="logo" style={{ height: '40px' }} />
                      <h1 style={{ fontSize: 13, color: '#707070', marginTop: 10 }}>{subItem.heading}</h1>
                      <h1 style={{ fontSize: 17, marginTop: 5 }}>{loadingStats ? <CircularProgress /> : subItem.heading2}</h1>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          ) : null
        )}
      </Grid>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: 1300,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Grid>
  );
};
export default DailySummary;
