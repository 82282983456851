import React, { useCallback, useEffect, useState } from 'react';
import { IDeoSessionListingParam, IDeoSessionStatsParam } from 'app/modules/deo-work-flow/interface';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getDeoEntities } from 'app/entities/session/session.reducer';
import {
  deleteUploadedFile,
  permanentDeleteFile,
  permanentDeleteRecord,
  permanentDeleteUnSubmittedFiles,
  submitFile,
} from 'app/modules/deo-work-flow/deo-reducer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const intialState = {
  isValid: '',
  fileId: '',
  pageNo: '',
  rows: '',
  q: '',
};

const SessionDeoListingHook = () => {
  const deoSessionEntities = useAppSelector(state => state.session.deoSessionEntities);
  const page = useAppSelector(state => state.session.pageNo);
  const rowsPerPage = useAppSelector(state => state.session.rows);
  const invalidRecordCount = useAppSelector(state => state.session.invalidRecordCount);
  const validRecordCount = useAppSelector(state => state.session.validRecordCount);
  const payloadStatus = useAppSelector(state => state.session.payloadStatus);
  const [searchCreteria, setsearchCreteria] = useState<IDeoSessionListingParam>(intialState);
  const dispatch = useAppDispatch();
  const [recordId, setRecordId] = useState('');
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [checked, setChecked] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openSubmitFilePopup, setOpenSubmitFilePopup] = React.useState(false);
  const [isFile, setIsFile] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const fileID = localStorage.getItem('fileId');
  const fileNAME = localStorage.getItem('fileName');
  const pharmacyNAME = localStorage.getItem('pharmacyName');
  const pharmacyNUMBER = localStorage.getItem('pharmacyNumber');
  const location = useLocation();
  const { file_id } = useParams();

  const handleChange = event => {
    if (event.target.checked) {
      setChecked(event.target.checked);
    } else {
      setChecked('');
    }
    const params = {
      ...searchCreteria,
      isValid: event.target.checked ? false : '',
    };
    setsearchCreteria({
      ...searchCreteria,
      isValid: event.target.checked ? false : '',
    });
    dispatch(getDeoEntities(params));
  };

  const handlePageChange = (e, pageNum) => {
    const params = {
      ...searchCreteria,
      pageNo: pageNum,
    };
    setsearchCreteria({
      ...searchCreteria,
      pageNo: pageNum,
    });
    dispatch(getDeoEntities(params));
  };

  const handleRowsChanges = e => {
    const params = {
      ...searchCreteria,
      rows: e.target.value,
    };
    setsearchCreteria({
      ...searchCreteria,
      rows: e.target.value,
    });
    dispatch(getDeoEntities(params));
  };

  const handleSearch = e => {
    const params = {
      ...searchCreteria,
      q: e.target.value,
    };
    setsearchCreteria({
      ...searchCreteria,
      q: e.target.value,
    });
    dispatch(getDeoEntities(params));
  };

  const handleClickOpen = (id, isFileClick) => {
    setIsFile(isFileClick);
    setRecordId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setRecordId('');
    setOpen(false);
  };

  const handleCloseSubmitPopup = () => {
    setOpenSubmitFilePopup(false);
    setIsFile(true);
    setRecordId(fileID);
    setOpen(true);
  };

  const showUploadRecordToast = () => {
    if (fileID) {
      setOpenSubmitFilePopup(true);
      // const updatedCount = Number(localStorage.getItem('uploadedFileCount')) - Number(localStorage.getItem('uploadedFileDeleteCount'));
      // toast.success(`${updatedCount}` + ' Records uploaded successfully.');
    }
  };

  const fileSubmitRedirect = async id => {
    const res = await dispatch(submitFile(id));
    if (res.type === 'pharmacyFileMetaData/submit-file/fulfilled') {
      navigate('/operator/dashboard');
    }
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleRedirect = id => {
    if (file_id !== undefined) {
      navigate('/operator/edit-patient/' + id + '/' + file_id);
    } else {
      navigate('/operator/edit-patient/' + id);
    }
  };

  const handleDelete = () => {
    if (recordId !== '') {
      setDeleteLoader(true);
      dispatch(permanentDeleteRecord(recordId))
        .then(result => {
          dispatch(getDeoEntities(searchCreteria));

          setDeleteLoader(false);
        })
        .catch(error => {});
      handleClose();
    }
  };

  const handleFileDelete = () => {
    if (recordId !== '') {
      setDeleteLoader(true);
      dispatch(permanentDeleteFile(recordId))
        .then(result => {
          setDeleteLoader(false);
          navigate('/operator/dashboard');
        })
        .catch(error => {});
      handleClose();
    }
  };

  const getAllDeoEntities = useCallback(() => {
    const params = {
      ...searchCreteria,
      fileId: fileID,
      pageNo: page,
      rows: rowsPerPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      fileId: fileID,
      pageNo: page,
      rows: rowsPerPage,
    });
    dispatch(getDeoEntities(params));
  }, []);
  const editAllDeoEntities = useCallback(() => {
    const params = {
      ...searchCreteria,
      fileId: file_id,
      pageNo: page,
      rows: rowsPerPage,
    };
    setsearchCreteria({
      ...searchCreteria,
      fileId: file_id,
      pageNo: page,
      rows: rowsPerPage,
    });
    dispatch(getDeoEntities(params));
  }, []);

  useEffect(() => {
    if (file_id !== undefined) {
      editAllDeoEntities();
    } else {
      getAllDeoEntities();
    }
  }, []);

  const states = {
    deoSessionEntities,
    page,
    searchCreteria,
    rowsPerPage,
    invalidRecordCount,
    validRecordCount,
    payloadStatus,
    fileID,
    fileNAME,
    pharmacyNAME,
    pharmacyNUMBER,
    isFile,
    anchorEl,
    open,
    openSubmitFilePopup,
    deleteLoader,
  };
  const handlers = {
    handleChange,
    handlePageChange,
    handleRowsChanges,
    handleSearch,
    handleDelete,
    handleFileDelete,
    handleClickOpen,
    handleClose,
    handleClick,
    handleClosed,
    handleCloseSubmitPopup,
    showUploadRecordToast,
    fileSubmitRedirect,
    handleRedirect,
  };
  return {
    states,
    handlers,
  };
};
export default SessionDeoListingHook;
