import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PharmacistAgreedActionType from './pharmacist-agreed-action-type';
import PharmacistAgreedActionTypeDetail from './pharmacist-agreed-action-type-detail';
import PharmacistAgreedActionTypeUpdate from './pharmacist-agreed-action-type-update';
import PharmacistAgreedActionTypeDeleteDialog from './pharmacist-agreed-action-type-delete-dialog';

const PharmacistAgreedActionTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PharmacistAgreedActionType />} />
    <Route path="new" element={<PharmacistAgreedActionTypeUpdate />} />
    <Route path=":id">
      <Route index element={<PharmacistAgreedActionTypeDetail />} />
      <Route path="edit" element={<PharmacistAgreedActionTypeUpdate />} />
      <Route path="delete" element={<PharmacistAgreedActionTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default PharmacistAgreedActionTypeRoutes;
