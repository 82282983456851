/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TablePagination,
  Tooltip,
  Dialog,
  FormHelperText,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import useManagePharmacy from '../hooks/useManagePharmay';
import { Formik, Form } from 'formik';
import { TextField, Tab, Tabs, Checkbox, List, ListItem, ListItemText, ListItemIcon, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import RichDataTablePharmacy from '../rich-data-table/RichDataTable-pharmacy';
interface ISessionListParams {
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}

export const AdminManagePharmacy = props => {
  const { states, handlers } = useManagePharmacy();
  const {
    pharmacyIdToDeleted,
    open,
    pageNo,
    modalOpen,
    allServicePharmacist,
    limit,
    totalCount,
    // allListPharmacy,
    allPharmacies,
    searchCreteria,
    emptyCellCount,
    emptyRowCount,
    initialState,
    validationSchema,
    allGroupNames,
    pharmaciesGroupDialog,
    searchTerm,
    tabValue,
    selectedPharmacyIds,
    selectedPharmacyData,
    openGroups,
    enrichedDataSource,
    filteredPharmacies,
    filteredGroups2,
    assignedPharmacies,
  } = states;
  const {
    handleModalClose,
    downloadPharmacyInExcelFile,
    handleSearchChange,
    handleRole,
    handleRefresh,
    handledateFrom,
    handledateTill,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClose,
    setOpen,
    setPharmacyIdAndOpenStatus,
    handleEdit,
    handlePharmacy,
    onSubmit,
    handleOpenPharmaciesGroupDialog,
    handleClosePharmaciesGroupDialog,
    handleTabChangePharmaciesGroupDialog,
    handleSearchChangePharmaciesGroupDialog,
    handleSelect,
    handleSelectPharmacy,
    handleToggleGroup,
    handleOkay,
    areAllPharmaciesSelectedInGroup,
    setSelectedPharmacyData,
    setSelectedPharmacyIds,
    handlePharmacySelection,
    handleEditSubmit,
  } = handlers;

  const navigate = useNavigate();
  return (
    <>
      <Grid
        lg={9.5}
        xs={8}
        container
        style={{ backgroundColor: 'trasnparent', marginTop: 20, marginLeft: 20, maxHeight: '100%', marginBottom: 20 }}
      >
        <Grid lg={12} xs={12} style={{ display: 'flex', marginTop: '2%', marginBottom: '1%', backgroundColor: '' }}>
          <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
            <Select
              id="demo-simple-select"
              onChange={handleRole}
              value={searchCreteria.group === '' ? 'All Groups' : searchCreteria.group}
              displayEmpty
              style={{
                height: '40px',
                width: '95%',
                fontSize: '12px',
                backgroundColor: '#EFEFEF',
                color: '#393939',
              }}
            >
              <MenuItem value={'All Groups'}>All Groups</MenuItem>
              {allGroupNames.map((item, index) => {
                return (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>

          <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
            <Tooltip title="Date From">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    className="clock"
                    onChange={newValue => handledateFrom(newValue)}
                    format="DD/MM/YYYY"
                    value={searchCreteria.from === '' ? null : searchCreteria.from}
                    sx={{ marginTop: 0, marginLeft: 0, border: '', width: '95%', height: '80%' }}
                    disabled={true} // Disabling the DatePicker
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>

          <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
            <Tooltip title="Date Till">
              <div style={{ display: 'flex' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                  <DatePicker
                    value={searchCreteria.to === '' ? null : searchCreteria.to}
                    className="clock"
                    onChange={newValue => handledateTill(newValue)}
                    format="DD/MM/YYYY"
                    sx={{ marginTop: 0, marginLeft: 0, border: '', width: '95%', height: '80%' }}
                    disabled={true} // Disabling the DatePicker
                  />
                </LocalizationProvider>
              </div>
            </Tooltip>
          </Grid>

          <Grid lg={6} xs={6} style={{ backgroundColor: '', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip title="Refresh">
              <IconButton
                onClick={() => handleRefresh()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 35,
                  width: 35,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Download">
              <IconButton
                onClick={() => downloadPharmacyInExcelFile()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginRight: 18,
                  marginLeft: 5,
                  height: 35,
                  width: 35,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  src={'../../../../content/assets/download2.png'}
                  alt="logo"
                  style={{ height: '17px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
          <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
              <FormControl sx={{ width: '100%', height: '11%' }}>
                <OutlinedInput
                  style={{ borderRadius: 50, height: '100%', width: '100%' }}
                  placeholder="Search here"
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                        <img
                          src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                          alt="logo"
                          style={{ height: '15px' }}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                  inputProps={{ style: { fontSize: 13 } }}
                  classes={{ notchedOutline: 'white-outline' }}
                />
              </FormControl>
            </div>
          </Grid>
          <RichDataTablePharmacy
            limit={limit}
            page={pageNo}
            count={totalCount}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            emptyRowCount={emptyRowCount}
            emptyCellCount={emptyCellCount}
            dataIndexs={['id', 'firstName', 'gphcNumber', 'contact_number', 'pharmacies', 'asset']}
            dataSource={enrichedDataSource}
            headers={['ID', 'Pharmacist', 'Gphc Number', 'Contact Number', 'Assigned Pharmacy', 'Assigned Assets']}
            showEditDeletButton={true}
            handleEdit={handleEdit}
            handleAssignPharmacy={handlePharmacy}
            handlePharmacySelection={handlePharmacySelection} // Pass this function to the table component
          />
        </Grid>

        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          fullWidth
          maxWidth={selectedPharmacyData.length > 0 ? 'lg' : 'sm'}
          PaperProps={{
            style: {
              height: selectedPharmacyData.length > 0 ? '80vh' : '40vh', // Change height based on the selection
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flow-root',
            },
          }}
        >
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <Form onSubmit={handleSubmit} noValidate>
                    <div
                      style={{
                        width: 550,
                        height: 300,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: '30px',
                      }}
                    >
                      <h1
                        style={{
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          color: '#325C92',
                          marginTop: '10px',
                          textAlign: 'center',
                        }}
                      >
                        Assign Pharmacy
                      </h1>
                      <h1
                        style={{
                          fontSize: '0.8rem',
                          color: 'black',
                          marginTop: '12px',
                          textAlign: 'center',
                        }}
                      >
                        Select <span style={{ color: '#325C92' }}>Pharmacies</span> to assign
                        {/* <span style={{ color: '#325C92' }}> Muhammad Owais Ajmal</span> */}
                      </h1>
                      <div
                        style={{
                          marginTop: '6%',
                          width: '75%',
                          height: '12%',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleOpenPharmaciesGroupDialog}
                          style={{
                            height: '100%',
                            width: '100%',
                            fontSize: '12px',
                            backgroundColor: '#EFEFEF',
                            color: '#393939',
                          }}
                        >
                          Select Pharmacies or Groups
                        </Button>
                        {(touched as any)?.userId && <FormHelperText error={true}>{(errors as any)?.userId}</FormHelperText>}
                      </div>

                      <div style={{ display: 'block ruby', justifyContent: 'center', alignItems: 'center' }}>
                        {selectedPharmacyData.length > 0 && (
                          <TableContainer
                            component={Paper}
                            style={{ marginTop: '20px', maxWidth: '900px', maxHeight: '400px', overflowY: 'auto' }}
                          >
                            <Table stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#ebe6e6', textAlign: 'center' }}>
                                    Pharmacy Name
                                  </TableCell>
                                  <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#ebe6e6', textAlign: 'center' }}>
                                    Target
                                  </TableCell>
                                  <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#ebe6e6', textAlign: 'center' }}>
                                    Completed Intervention
                                  </TableCell>
                                  <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#ebe6e6', textAlign: 'center' }}>
                                    Pending Interventions
                                  </TableCell>
                                  <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#ebe6e6', textAlign: 'center' }}>
                                    Pending Follow-ups
                                  </TableCell>
                                  <TableCell style={{ border: '1px solid #ccc', backgroundColor: '#ebe6e6', textAlign: 'center' }}>
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {selectedPharmacyData.map((pharmacy, index) => (
                                  <TableRow key={index}>
                                    <TableCell style={{ border: '1px solid #ccc' }}>{pharmacy.name}</TableCell>
                                    <TableCell style={{ border: '1px solid #ccc' }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '8px' }}>{pharmacy.target !== null ? pharmacy.target : 0}</span>
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid #ccc' }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '8px' }}>{pharmacy.completedInterventions}</span>
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid #ccc' }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '8px' }}>{pharmacy.interventions?.count}</span>
                                        <Checkbox
                                          checked={pharmacy.interventions?.selected}
                                          onChange={() => {
                                            setSelectedPharmacyData(prev => {
                                              const newData = [...prev];
                                              newData[index].interventions.selected = !newData[index].interventions.selected;
                                              return newData;
                                            });
                                          }}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid #ccc' }}>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ marginRight: '8px' }}>{pharmacy.followup?.count}</span>
                                        <Checkbox
                                          checked={pharmacy.followup?.selected}
                                          onChange={() => {
                                            setSelectedPharmacyData(prev => {
                                              const newData = [...prev];
                                              newData[index].followup.selected = !newData[index].followup.selected;
                                              return newData;
                                            });
                                          }}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ border: '1px solid #ccc' }}>
                                      <Button
                                        variant="contained"
                                        color={assignedPharmacies.has(pharmacy.id) ? 'primary' : 'success'}
                                        onClick={() => {
                                          if (assignedPharmacies.has(pharmacy.id)) {
                                            // Un-assign logic
                                            handleEditSubmit(pharmacy);
                                          } else {
                                            // Assign logic
                                            onSubmit(pharmacy); // This is the existing assign logic
                                          }
                                        }}
                                        style={{ textTransform: 'none' }} // Override text-transform here
                                      >
                                        <span style={{ padding: 2 }}>{assignedPharmacies.has(pharmacy.id) ? 'Update' : 'Assign'}</span>
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </div>

                      <Dialog open={pharmaciesGroupDialog} onClose={handleClosePharmaciesGroupDialog} fullWidth maxWidth="sm">
                        <DialogTitle>Select Pharmacies or Group</DialogTitle>
                        <IconButton
                          aria-label="close"
                          onClick={handleClosePharmaciesGroupDialog}
                          style={{ position: 'absolute', right: 8, top: 8 }}
                        >
                          <CloseIcon />
                        </IconButton>
                        <DialogContent>
                          <TextField
                            label="Search"
                            fullWidth
                            value={searchTerm}
                            onChange={handleSearchChangePharmaciesGroupDialog}
                            variant="outlined"
                            margin="normal"
                          />

                          <Tabs
                            value={tabValue}
                            onChange={handleTabChangePharmaciesGroupDialog}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="fullWidth"
                          >
                            <Tab label="All Pharmacies" />
                            <Tab label="All Groups" />
                          </Tabs>

                          <List>
                            {tabValue === 0 ? (
                              // Pharmacies Tab
                              filteredPharmacies?.map(pharmacy => (
                                <ListItem key={pharmacy.id} button onClick={() => handleSelect(pharmacy.id)}>
                                  <ListItemIcon>
                                    <Checkbox
                                      checked={selectedPharmacyIds.includes(pharmacy.id)}
                                      onChange={() => handleSelectPharmacy(pharmacy.id)}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={pharmacy.name} />
                                </ListItem>
                              ))
                            ) : (
                              <List>
                                {filteredGroups2?.map(group => (
                                  <div key={group.id}>
                                    <ListItem button onClick={() => handleToggleGroup(group.id)} style={{ backgroundColor: '#f0f0f0' }}>
                                      <ListItemIcon>
                                        <Checkbox
                                          checked={areAllPharmaciesSelectedInGroup(group.id)}
                                          onChange={() => {
                                            const groupPharmacies = group.pharmacy.map(pharmacy => pharmacy.id);
                                            if (areAllPharmaciesSelectedInGroup(group.id)) {
                                              setSelectedPharmacyIds(selectedPharmacyIds.filter(id => !groupPharmacies.includes(id)));
                                            } else {
                                              setSelectedPharmacyIds([...new Set([...selectedPharmacyIds, ...groupPharmacies])]);
                                            }
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText>
                                        <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                          {group.name}
                                        </Typography>
                                      </ListItemText>
                                      <ListItemIcon>
                                        <IconButton edge="end" onClick={() => handleToggleGroup(group.id)}>
                                          {openGroups.includes(group.id) ? <ExpandLess /> : <ExpandMore />}
                                        </IconButton>
                                      </ListItemIcon>
                                    </ListItem>

                                    <Collapse in={openGroups.includes(group.id)} timeout="auto" unmountOnExit>
                                      <List component="div" disablePadding>
                                        {group.pharmacy.map(pharmacy => (
                                          <ListItem key={pharmacy.id} button style={{ paddingLeft: 32 }}>
                                            <ListItemIcon>
                                              <Checkbox
                                                edge="start"
                                                checked={selectedPharmacyIds.includes(pharmacy.id)}
                                                onChange={() => handleSelectPharmacy(pharmacy.id)}
                                                tabIndex={-1}
                                                disableRipple
                                              />
                                            </ListItemIcon>
                                            <ListItemText primary={pharmacy.name} />
                                          </ListItem>
                                        ))}
                                      </List>
                                    </Collapse>
                                  </div>
                                ))}
                              </List>
                            )}
                          </List>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleOkay} color="primary">
                            Next
                          </Button>
                        </DialogActions>
                      </Dialog>

                      <div
                        style={{
                          display: 'flex',
                          marginTop: '8%',
                          marginBottom: '5%',
                          backgroundColor: '',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <IconButton
                          style={{
                            backgroundColor: '#EFEFEF',
                            height: '100%',
                            width: '28%',
                            border: '1px solid lightgray',
                            padding: '10px',
                            display: 'flex',
                            borderRadius: 3,
                            justifyContent: 'center',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                          }}
                          onClick={handleModalClose}
                        >
                          <img
                            src={'../../../../content/assets/in-progress-svgrepo-com.png'} // Use the image URL from the array
                            alt="logo"
                            style={{ height: '15px', filter: 'invert(1)' }}
                          />
                          <h1
                            style={{
                              fontSize: 15,
                              marginTop: 8,
                              fontWeight: 400,
                              marginLeft: 10,
                              alignSelf: 'center',
                              justifyContent: 'center',
                              color: 'black',
                              fontFamily: 'Roboto, sans-serif',
                            }}
                          >
                            Done
                          </h1>
                        </IconButton>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Dialog>
      </Grid>
    </>
  );
};
export default AdminManagePharmacy;
