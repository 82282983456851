import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPharmacistAgreedActionType } from 'app/shared/model/pharmacist-agreed-action-type.model';
import { getEntities as getPharmacistAgreedActionTypes } from 'app/entities/pharmacist-agreed-action-type/pharmacist-agreed-action-type.reducer';
import { ISession } from 'app/shared/model/session.model';
import { getEntities as getSessions } from 'app/entities/session/session.reducer';
import { ISessionPharmacistAgreedAction } from 'app/shared/model/session-pharmacist-agreed-action.model';
import { getEntity, updateEntity, createEntity, reset } from './session-pharmacist-agreed-action.reducer';

export const SessionPharmacistAgreedActionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const pharmacistAgreedActionTypes = useAppSelector(state => state.pharmacistAgreedActionType.entities);
  const sessions = useAppSelector(state => state.session.entities);
  const sessionPharmacistAgreedActionEntity = useAppSelector(state => state.sessionPharmacistAgreedAction.entity);
  const loading = useAppSelector(state => state.sessionPharmacistAgreedAction.loading);
  const updating = useAppSelector(state => state.sessionPharmacistAgreedAction.updating);
  const updateSuccess = useAppSelector(state => state.sessionPharmacistAgreedAction.updateSuccess);

  const handleClose = () => {
    navigate('/session-pharmacist-agreed-action');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getPharmacistAgreedActionTypes({}));
    dispatch(getSessions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...sessionPharmacistAgreedActionEntity,
      ...values,
      pharmacistAgreedActionType: pharmacistAgreedActionTypes.find(it => it.id.toString() === values.pharmacistAgreedActionType.toString()),
      session: sessions.find(it => it.id.toString() === values.session.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...sessionPharmacistAgreedActionEntity,
          pharmacistAgreedActionType: sessionPharmacistAgreedActionEntity?.pharmacistAgreedActionType?.id,
          session: sessionPharmacistAgreedActionEntity?.session?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="pharmacySolutionsApp.sessionPharmacistAgreedAction.home.createOrEditLabel"
            data-cy="SessionPharmacistAgreedActionCreateUpdateHeading"
          >
            <Translate contentKey="pharmacySolutionsApp.sessionPharmacistAgreedAction.home.createOrEditLabel">
              Create or edit a SessionPharmacistAgreedAction
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="session-pharmacist-agreed-action-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('pharmacySolutionsApp.sessionPharmacistAgreedAction.description')}
                id="session-pharmacist-agreed-action-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                id="session-pharmacist-agreed-action-pharmacistAgreedActionType"
                name="pharmacistAgreedActionType"
                data-cy="pharmacistAgreedActionType"
                label={translate('pharmacySolutionsApp.sessionPharmacistAgreedAction.pharmacistAgreedActionType')}
                type="select"
              >
                <option value="" key="0" />
                {pharmacistAgreedActionTypes
                  ? pharmacistAgreedActionTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="session-pharmacist-agreed-action-session"
                name="session"
                data-cy="session"
                label={translate('pharmacySolutionsApp.sessionPharmacistAgreedAction.session')}
                type="select"
              >
                <option value="" key="0" />
                {sessions
                  ? sessions.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/session-pharmacist-agreed-action"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SessionPharmacistAgreedActionUpdate;
