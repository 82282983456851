import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './session-patient-reported-issue.reducer';

export const SessionPatientReportedIssueDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const sessionPatientReportedIssueEntity = useAppSelector(state => state.sessionPatientReportedIssue.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sessionPatientReportedIssueDetailsHeading">
          <Translate contentKey="pharmacySolutionsApp.sessionPatientReportedIssue.detail.title">SessionPatientReportedIssue</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{sessionPatientReportedIssueEntity.id}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="pharmacySolutionsApp.sessionPatientReportedIssue.description">Description</Translate>
            </span>
          </dt>
          <dd>{sessionPatientReportedIssueEntity.description}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.sessionPatientReportedIssue.patientReportedIssueType">
              Patient Reported Issue Type
            </Translate>
          </dt>
          <dd>
            {sessionPatientReportedIssueEntity.patientReportedIssueType
              ? sessionPatientReportedIssueEntity.patientReportedIssueType.id
              : ''}
          </dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.sessionPatientReportedIssue.session">Session</Translate>
          </dt>
          <dd>{sessionPatientReportedIssueEntity.session ? sessionPatientReportedIssueEntity.session.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/session-patient-reported-issue" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/session-patient-reported-issue/${sessionPatientReportedIssueEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SessionPatientReportedIssueDetail;
