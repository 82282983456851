import './header.scss';

import * as React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  Button,
  MenuItem,
  Divider,
  FormControl,
  Select,
  FormHelperText,
  Popover,
  Box,
  Typography,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import { LoadingBar } from 'react-redux-loading-bar';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMultiplePharmacy, logout, setPharmacy } from 'app/shared/reducers/authentication';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { isSet } from 'lodash';
import { isNumber } from 'react-jhipster';
import { getAllSessionsByPharmacy } from 'app/entities/session/session.reducer';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import { SwitchAccessShortcut, SwitchAccessShortcutAddOutlined } from '@mui/icons-material';

const role = localStorage.getItem('role') === null ? '' : localStorage.getItem('role');
const pages = ['Dashboard', 'New Medicine Service ', 'User Guide', 'Contact Us'];
const pageImages = [
  '../../../../content/assets/dashboard-svgrepo-com.png',
  '../../../../content/assets/services-svgrepo-com.png',
  '../../../../content/assets/guide-svgrepo-com.png',
  '../../../../content/assets/email-1-svgrepo-com.png',
];
interface ISessionListParams {
  sort: any;
  service_id: any;
  session_status: any;
  session_type: any;
  pageNo: any;
  searchParams: any;
  rows: any;
  q: any;
}
export interface IHeaderProps {
  isAuthenticated: boolean;
  isLocumDetailsVerify: boolean;
  isDEO: boolean;
  isLeadDEO: boolean;
  isManagerDEO: boolean;
  isAdmin: boolean;
  isClient: boolean;
  isPharmacist: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
  serviceEntities: Array<any>;
}

const Header = (props: IHeaderProps) => {
  const pharmacyName = JSON.parse(localStorage.getItem('pharmacy_name'));
  const { isAuthenticated, isLocumDetailsVerify, isPharmacist, isDEO, isAdmin, isClient, isLeadDEO, isManagerDEO } = props;
  const [locumVerify, setLocumVerify] = useState(false);
  const [setAnchorElNav] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { serviceEntities } = props;
  const dispatch = useAppDispatch();
  const { pharmacy_list, account } = useAppSelector(state => state.authentication);
  const [pharmacyValue, setPharmacyValue] = useState(pharmacyName);
  const [dialogOpen, setDialogOpen] = useState(false); // State for dialog open/close
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    const id = account?.id;
    const params = { id, search: '' };
    dispatch(getMultiplePharmacy(params));
  }, []);
  useEffect(() => {
    if (localStorage.getItem('locumVerified')) {
      setLocumVerify(true);
    }
  }, []);

  const handleProfileIconClick = () => {
    setDialogOpen(true); // Open the dialog when profile icon is clicked
  };

  const handleCloseDialog = () => {
    setDialogOpen(false); // Close the dialog
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectPage = pageId => {
    setSelectedPage(pageId);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const handleDashboardRedirect = () => {
    const lastActiveTabAdmin = localStorage.getItem('lastActiveTabAdmin');
    const tabStateAdmin = lastActiveTabAdmin ? JSON.parse(lastActiveTabAdmin) : { selectedColor: 'one', index: 1 };
    const lastActiveTabDeo = localStorage.getItem('lastActiveTabDeo');
    const tabStateDeo = lastActiveTabDeo ? JSON.parse(lastActiveTabDeo) : { activeItem: 'Daily-Summary' };
    if (isPharmacist) {
      navigate('/');
    } else if (isDEO || isLeadDEO || isManagerDEO) {
      navigate('operator/dashboard', { state: JSON.stringify(tabStateDeo) });
      localStorage.setItem('lastActiveTabDeo', JSON.stringify(tabStateDeo));
    } else if (isAdmin) {
      navigate('/admin/dashboard', { state: tabStateAdmin });
      localStorage.setItem('lastActiveTabAdmin', JSON.stringify({ selectedColor: 'one', index: 1 }));
    } else if (isClient) {
      navigate('client/dashboard');
    }
  };
  const handleSelectedPharmacy = event => {
    const pharmacy = event.target.value;
    setPharmacyValue(pharmacy);
    dispatch(setPharmacy(pharmacy)).then(r => {
      const data = {
        service_id: 1,
        session_status: '',
        session_type: '',
        pageNo: 0,
        rows: 20,
        q: '',
      };
      dispatch(getAllSessionsByPharmacy(data as ISessionListParams));
      navigate('/');
    });
  };
  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  return (
    <AppBar position="static" sx={{ backgroundColor: '#FFFFFF', height: '55px' }}>
      <LoadingBar className="loading-bar" />

      {isAuthenticated && (
        <Toolbar>
          <img
            onClick={handleDashboardRedirect}
            src={'../../../../content/assets/pharmacy-solution-logo.png'}
            alt="Logo"
            style={{
              width: 'auto',
              height: '45px',
              marginLeft: 10,
              marginBottom: 10,
              cursor: 'pointer', // Add this line to change cursor on hover
              zIndex: 3,
            }}
          />
          {isPharmacist && (locumVerify || isLocumDetailsVerify) && (
            <div style={{ flexGrow: 1, display: 'flex', alignSelf: 'center', justifyContent: 'flex-end', marginLeft: '150px' }}>
              <Button
                onClick={() => selectPage(1)}
                style={{
                  color: selectedPage === 1 ? 'white' : 'black',
                  backgroundColor: selectedPage === 1 ? '#99D7FF' : 'transparent',
                  borderRadius: '20px',
                  marginLeft: 5,
                  padding: '15px 25px', // Add padding to make it pill-shaped
                  transition: 'background-color 0.3s ease', // Smooth transition
                  fontSize: '11px',
                  fontWeight: 500,
                  marginRight: 5,
                }}
              >
                <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                  <AppsOutlinedIcon style={{ color: selectedPage === 1 ? 'white' : '#99D7FF', height: '18px' }} />
                  <span style={{ color: selectedPage === 1 ? '#004CB2' : 'black', padding: 4 }}>{pages[1]}</span>
                  <img
                    src={'../../../../content/assets/headerdownarrow.png'} // Use the image URL from the array
                    alt="logo"
                    style={{ width: 'auto', height: '8px' }}
                  />
                </div>
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                style={{ marginTop: '32px' }} // Adjust the positioning as needed
              >
                {serviceEntities &&
                  serviceEntities.length > 0 &&
                  serviceEntities.map((item, index) => (
                    <MenuItem key={index} style={{ width: 100 }}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Menu>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Divider style={{ backgroundColor: 'gray', width: 1, height: 17, marginRight: 7, marginLeft: 2 }} />
                <Button
                  onClick={() => selectPage(2)}
                  style={{
                    color: selectedPage === 2 ? 'white' : 'black',
                    backgroundColor: selectedPage === 2 ? '#99D7FF' : 'transparent',
                    borderRadius: '20px',
                    marginLeft: 5,
                    padding: '15px 25px', // Add padding to make it pill-shaped
                    transition: 'background-color 0.3s ease', // Smooth transition
                    fontSize: '11px',
                    fontWeight: 500,
                  }}
                >
                  <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                    <AutoStoriesIcon style={{ color: selectedPage === 2 ? 'white' : '#99D7FF', height: '18px' }} />
                    <span style={{ color: selectedPage === 2 ? '#004CB2' : 'black', padding: 4 }}>{pages[2]}</span>
                  </div>
                </Button>
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Divider style={{ backgroundColor: 'gray', width: 1, height: 17, marginRight: 7, marginLeft: 10 }} />
                <Button
                  onClick={() => selectPage(3)}
                  style={{
                    color: selectedPage === 3 ? 'white' : 'black',
                    backgroundColor: selectedPage === 3 ? '#99D7FF' : 'transparent',
                    borderRadius: '20px',
                    marginLeft: 5,
                    padding: '15px 25px', // Add padding to make it pill-shaped
                    transition: 'background-color 0.3s ease', // Smooth transition
                    fontSize: '11px',
                    fontWeight: 500,
                  }}
                >
                  <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                    <EmailOutlinedIcon style={{ color: selectedPage === 3 ? 'white' : '#99D7FF', height: '18px' }} />
                    <span style={{ color: selectedPage === 3 ? '#004CB2' : 'black', padding: 4 }}>{pages[3]}</span>
                  </div>
                </Button>
              </div>
            </div>
          )}

          {isClient && (
            <>
              <div style={{ flexGrow: 1, display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => selectPage(2)}
                    style={{
                      color: selectedPage === 2 ? 'white' : 'black',
                      backgroundColor: selectedPage === 2 ? '#99D7FF' : 'transparent',
                      borderRadius: '20px',
                      marginLeft: 5,
                      padding: '15px 25px', // Add padding to make it pill-shaped
                      transition: 'background-color 0.3s ease', // Smooth transition
                      fontSize: '11px',
                      fontWeight: 500,
                    }}
                  >
                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                      <AutoStoriesIcon style={{ color: selectedPage === 2 ? 'white' : '#99D7FF', height: '18px' }} />
                      <span style={{ color: selectedPage === 2 ? '#004CB2' : 'black', padding: 4 }}>{pages[2]}</span>
                    </div>
                  </Button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Divider style={{ backgroundColor: 'gray', width: 1, height: 17, marginRight: 7, marginLeft: 10 }} />
                  <Button
                    onClick={() => selectPage(3)}
                    style={{
                      color: selectedPage === 3 ? 'white' : 'black',
                      backgroundColor: selectedPage === 3 ? '#99D7FF' : 'transparent',
                      borderRadius: '20px',
                      marginLeft: 5,
                      padding: '15px 25px', // Add padding to make it pill-shaped
                      transition: 'background-color 0.3s ease', // Smooth transition
                      fontSize: '11px',
                      fontWeight: 500,
                    }}
                  >
                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                      <EmailOutlinedIcon style={{ color: selectedPage === 3 ? 'white' : '#99D7FF', height: '18px' }} />
                      <span style={{ color: selectedPage === 3 ? '#004CB2' : 'black', padding: 4 }}>{pages[3]}</span>
                    </div>
                  </Button>
                </div>
              </div>
            </>
          )}

          {(isDEO || isLeadDEO || isManagerDEO) && (
            <>
              <div style={{ flexGrow: 1, display: 'flex', alignSelf: 'center', justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Button
                    onClick={() => selectPage(2)}
                    style={{
                      color: selectedPage === 2 ? 'white' : 'black',
                      backgroundColor: selectedPage === 2 ? '#99D7FF' : 'transparent',
                      borderRadius: '20px',
                      marginLeft: 5,
                      padding: '15px 25px', // Add padding to make it pill-shaped
                      transition: 'background-color 0.3s ease', // Smooth transition
                      fontSize: '11px',
                      fontWeight: 500,
                    }}
                  >
                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                      <AutoStoriesIcon style={{ color: selectedPage === 2 ? 'white' : '#99D7FF', height: '18px' }} />
                      <span style={{ color: selectedPage === 2 ? '#004CB2' : 'black', padding: 4 }}>{pages[2]}</span>
                    </div>
                  </Button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Divider style={{ backgroundColor: 'gray', width: 1, height: 17, marginRight: 7, marginLeft: 10 }} />
                  <Button
                    onClick={() => selectPage(3)}
                    style={{
                      color: selectedPage === 3 ? 'white' : 'black',
                      backgroundColor: selectedPage === 3 ? '#99D7FF' : 'transparent',
                      borderRadius: '20px',
                      marginLeft: 5,
                      padding: '15px 25px', // Add padding to make it pill-shaped
                      transition: 'background-color 0.3s ease', // Smooth transition
                      fontSize: '11px',
                      fontWeight: 500,
                    }}
                  >
                    <div style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 5, paddingBottom: 5 }}>
                      <EmailOutlinedIcon style={{ color: selectedPage === 3 ? 'white' : '#99D7FF', height: '18px' }} />
                      <span style={{ color: selectedPage === 3 ? '#004CB2' : 'black', padding: 4 }}>{pages[3]}</span>
                    </div>
                  </Button>
                </div>
              </div>
            </>
          )}

          <div style={{ flexGrow: 1, display: 'flex', alignSelf: 'center', justifyContent: 'flex-end', marginRight: -8, marginBottom: 10 }}>
            {((isPharmacist && (locumVerify || isLocumDetailsVerify)) || isAdmin) && (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                {isPharmacist && (locumVerify || isLocumDetailsVerify) && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <SwitchAccessShortcutAddOutlined style={{ height: '19px', color: '#99D7FF', marginRight: 5 }} />
                    <span style={{ marginRight: '10px', color: 'black', fontSize: '11px', fontWeight: 500 }}>SWITCH PHARMACY:</span>
                    <FormControl>
                      <Select
                        className="outline"
                        id="demo-simple-select"
                        name="pharmacy"
                        value={pharmacyValue || ''}
                        displayEmpty
                        onChange={handleSelectedPharmacy}
                        placeholder={'Switch Pharmacy'}
                        style={{
                          height: '40px',
                          fontSize: '12px',
                          maxWidth: '300px',
                          backgroundColor: '#F3F3F3',
                          color: '#4E4E4E',
                          borderColor: '#DBDBDB',
                          fontWeight: 'bold',
                        }}
                        renderValue={selected => <span style={{ marginLeft: 5 }}>{selected?.name || pharmacyName}</span>}
                      >
                        <MenuItem disabled value="">
                          Select Pharmacy
                        </MenuItem>
                        {pharmacy_list?.map((elem, index) => (
                          <MenuItem key={index} value={elem}>
                            {elem?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            )}
          </div>
          <div style={{ marginBottom: 7 }}>
            <img
              src={'../../../../content/assets/profile1.png'}
              alt="Profile"
              style={{ height: '35px', cursor: 'pointer', marginLeft: '20px' }}
              onClick={handleProfileIconClick} // Open the Popover when clicked
            />

            {/* Popover for Profile Details */}
            <Popover
              open={dialogOpen}
              anchorReference="anchorPosition"
              anchorPosition={{ top: 20, left: 2000 }} // Center the popover horizontally
              onClose={handleCloseDialog}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                sx: {
                  width: { xs: '30%', sm: '22%', md: '10%' }, // Responsive width for different breakpoints
                  padding: 2, // Padding around the content
                  borderRadius: 2,
                },
              }}
            >
              <Box>
                <Typography variant="h6" sx={{ textAlign: 'left', color: '#236EBA' }}>
                  {account?.login}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'left', color: '#7E7E7E', fontSize: 10 }}>
                  Logged in as {role}
                </Typography>
              </Box>
              <Divider style={{ marginTop: 5 }} />
              <Button
                fullWidth
                onClick={handleLogout}
                sx={{
                  justifyContent: 'flex-start',
                  color: 'red',
                  margin: '10px 0',
                  padding: 1,
                  paddingLeft: 2,
                }}
              >
                Logout
                <Box
                  component="img"
                  src={'../../../../content/assets/logout-logo.png'}
                  alt="logo"
                  sx={{ marginLeft: 'auto' }} // Adjust size and alignment for responsiveness
                />
              </Button>
            </Popover>
          </div>
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
