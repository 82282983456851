import React, { useEffect, useState } from 'react';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import { listItemDecoratorClasses } from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemButton from '@mui/joy/ListItemButton';
import Typography from '@mui/joy/Typography';
import { Divider, Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { logout } from 'app/shared/reducers/authentication';
import { useLocation, useNavigate } from 'react-router-dom';
import '../admin-work-flow/rich-data-table/admin-dashboard.scss';
import Contractor from './contractorWorkflow';

export const ContractorMenu = props => {
  const role = localStorage.getItem('role');
  const [showMenu, setShowMenu] = useState(false);
  const [index, setIndex] = useState(0);
  const [selectedColor, setSelectedColor] = useState('one');
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(state => state.authentication.account);
  const { state }: any = useLocation();

  useEffect(() => {
    if (state) {
      setSelectedColor(state);
    }
  }, [state]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    handleClose();
    navigate('/login');
  };

  return (
    <Grid lg={12} container style={{ height: '92.5%', flex: 1, width: '100%', marginBottom: '5%', position: 'fixed' }}>
      <Grid lg={2.3} xs={12} sm={3} container style={{ backgroundColor: '#325C92', justifyContent: 'center' }}>
        {showMenu === false ? (
          <Grid
            lg={10}
            xs={10}
            sm={10}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              height: '100%',
              backgroundColor: '',
            }}
          >
            <List
              aria-label="Sidebar"
              sx={{
                '--ListItem-paddingLeft': '0px',
                '--ListItemDecorator-size': '20px',
                '--ListItem-minHeight': '10px',
                '--List-nestedInsetStart': '10px',
                [`& .${listItemDecoratorClasses.root}`]: {
                  justifyContent: 'space-between',
                  pr: '18px',
                },
                '& [role="button"]': {
                  borderRadius: '0 2px 2px 0',
                },
                height: 300,
              }}
            >
              <ListItem onClick={() => {}} style={{ height: 40, marginTop: '30px' }}>
                <ListItemButton
                  data-index={1}
                  selected={index === 1}
                  style={{ backgroundColor: selectedColor === 'one' ? '#7CB6F8' : '#325C92' }}
                  onClick={() => {
                    setSelectedColor('one');
                  }}
                >
                  <img
                    data-index={1}
                    src={'../../../../content/assets/overview.png'}
                    alt="logo"
                    style={{ height: '19px', marginRight: 10, marginLeft: 20 }}
                  />
                  <ListItemContent
                    data-index={1}
                    sx={{
                      fontWeight: '',
                      color: selectedColor === 'four' ? '#4877B1' : undefined,
                      fontSize: 13,
                      marginLeft: 0.7,
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <span style={{ color: 'white' }} data-index={1}>
                      Overview
                    </span>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            </List>
            <div className="logout_div" style={{ marginBottom: '70px', width: '16%' }}>
              <img src={'../../../../content/assets/profile1.png'} alt="logo" style={{ height: '25px' }} onClick={() => {}} />

              <div
                style={{
                  justifyContent: 'space-between',
                  marginLeft: 5,
                  width: '70%',
                  overflow: 'auto',
                  alignSelf: 'center',
                  marginTop: 8,
                }}
              >
                <div>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </div>
                <h1 className="logout_text">
                  {' '}
                  {role === null || role === 'null'
                    ? ''
                    : role === '"CLIENT"'
                    ? 'Pharmacy Contractor'
                    : role.replace(/"/g, '').replace(/_/g, ' ')}
                </h1>
                <h1 className="logout_text">{currentUser && currentUser['login']}</h1>
              </div>
              <IconButton
                onClick={handleClick}
                style={{
                  backgroundColor: '',
                  marginRight: 5,
                  height: 30,
                  width: 20,
                  padding: '0px',
                  display: 'flex',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img src={'../../../../content/assets/threedots.png'} alt="logo" style={{ height: '25px' }} />
              </IconButton>
            </div>
          </Grid>
        ) : (
          []
        )}
      </Grid>

      <Contractor />
    </Grid>
  );
};
export default ContractorMenu;
