import useListingTable from 'app/modules/admin-work-flow/hooks/useListingTable';
import { useAppSelector } from 'app/config/store';
import {
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { generalMonthArray } from 'app/shared/util/utits';
import RichDataTable from 'app/modules/admin-work-flow/rich-data-table/RichDataTable';
import React from 'react';
import storeTotalHook from 'app/modules/admin-work-flow/hooks/storeTotalHook';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const AdminStoreTotalTab = props => {
  const { states, handlers } = storeTotalHook();
  const loadingDownload = useAppSelector(state => state.admin.loadingAdminStoreTotalDownload);

  const {
    pageNo,
    limit,
    totalCount,
    allPharmacies,
    allPharmaciesListing,
    allPharmaciesOverallStats,
    searchCreteria,
    emptyRowCount,
    emptyCellCount,
    allGroupNames,
    filteredPharmacies,
    selectedPharmacies,
    columns,
    selectedColumns,
    anchorEl,
  } = states;
  const {
    getDownloadData,
    handleRefresh,
    handleSelectPharmacy,
    handleSelectMonth,
    handleSelectGroup,
    handleSearchChange,
    handleChangeRowsPerPage,
    handleChangePage,
    handleOpenMenu,
    handleCloseMenu,
    handleCheckboxChange,
  } = handlers;

  const formatNumber = number => {
    return new Intl.NumberFormat().format(number);
  };
  return (
    <Grid
      lg={9.5}
      xs={8}
      container
      style={{ backgroundColor: 'transparent', marginTop: 20, marginLeft: 20, maxHeight: '100%', marginBottom: 20 }}
    >
      <Grid lg={11.8} xs={12} style={{ display: 'flex', marginTop: '2%', marginBottom: '1%', backgroundColor: '' }}>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.group === '' ? 'All Groups' : Number(searchCreteria.group)}
            onChange={handleSelectGroup}
            // displayEmpty
            style={{
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
              width: '95%',
              height: '40px',
            }}
          >
            <MenuItem value={'All Groups'}>All Groups</MenuItem>
            {allGroupNames.map((elem, index) => {
              return (
                <MenuItem key={elem.id} value={elem.id}>
                  {elem.name}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          {/*<Select*/}
          {/*  id="demo-simple-select"*/}
          {/*  value={searchCreteria.pharmacy === '' ? 'All Pharmacies' : Number(searchCreteria.pharmacy)}*/}
          {/*  onChange={handleSelectPharmacy}*/}
          {/*  // displayEmpty*/}
          {/*  style={{*/}
          {/*    fontSize: '12px',*/}
          {/*    backgroundColor: '#EFEFEF',*/}
          {/*    color: '#393939',*/}
          {/*    width: '95%',*/}
          {/*    height: '40px',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>*/}
          {/*  {filteredPharmacies.map((elem, index) => {*/}
          {/*    return (*/}
          {/*      <MenuItem key={elem.id} value={elem.id}>*/}
          {/*        {elem.name}*/}
          {/*      </MenuItem>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</Select>*/}
          <Select
            multiple
            value={selectedPharmacies}
            onChange={handleSelectPharmacy}
            displayEmpty
            renderValue={selected => {
              if (selected.length === 0) {
                return 'All Pharmacies';
              }
              const selectedPharmacyNames = selected.map(id => {
                const pharmacy = allPharmacies.find(pharma => pharma.id === id);
                return pharmacy ? pharmacy.name : '';
              });
              return (
                <div>
                  {selectedPharmacyNames.map(name => (
                    <Chip key={name} label={name} size="small" />
                  ))}
                </div>
              );
            }}
            style={{
              height: '40px',
              width: '95%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
            }}
          >
            <MenuItem value={'All Pharmacies'}>All Pharmacies</MenuItem>
            {filteredPharmacies.map(elem => (
              <MenuItem key={elem.id} value={elem.id}>
                {elem.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        {/*<Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>*/}
        {/*  <Select*/}
        {/*    id="demo-simple-select"*/}
        {/*    value={searchCreteria.servicePharmacist === '' ? 'All Service Pharmacist' : searchCreteria.servicePharmacist}*/}
        {/*    onChange={handleSelectService}*/}
        {/*    // displayEmpty*/}
        {/*    style={{*/}
        {/*      width: '95%',*/}
        {/*      height: '40px',*/}
        {/*      fontSize: '12px',*/}
        {/*      backgroundColor: '#EFEFEF',*/}
        {/*      color: '#393939',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <MenuItem value={'All Service Pharmacist'}>All Service Pharmacist</MenuItem>*/}
        {/*    {allServicesPharmacist.map((elem, index) => {*/}
        {/*      return (*/}
        {/*        <MenuItem key={elem.id} value={elem.id}>*/}
        {/*          {elem.firstName}*/}
        {/*        </MenuItem>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Select>*/}
        {/*</Grid>*/}
        {/*<Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>*/}
        {/*  <Select*/}
        {/*    id="demo-simple-select"*/}
        {/*    value={searchCreteria.status === '' ? 'Status' : searchCreteria.status}*/}
        {/*    onChange={handleSelectStatus}*/}
        {/*    style={{*/}
        {/*      height: '40px',*/}
        {/*      width: '95%',*/}
        {/*      fontSize: '12px',*/}
        {/*      backgroundColor: '#EFEFEF',*/}
        {/*      color: '#393939',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <MenuItem value={'Status'}>Status</MenuItem>*/}
        {/*    {sessionStatusesOverview.map(elem => {*/}
        {/*      return (*/}
        {/*        <MenuItem key={elem.id} value={elem.name}>*/}
        {/*          {elem.name}*/}
        {/*        </MenuItem>*/}
        {/*      );*/}
        {/*    })}*/}
        {/*  </Select>*/}
        {/*</Grid>*/}
        <Grid lg={2} xs={2} style={{ backgroundColor: '', width: '100%' }}>
          <Select
            id="demo-simple-select"
            value={searchCreteria.month === '' ? 'All Months' : searchCreteria.month}
            onChange={handleSelectMonth}
            style={{
              height: '40px',
              width: '95%',
              fontSize: '12px',
              backgroundColor: '#EFEFEF',
              color: '#393939',
              marginRight: '10px',
            }}
          >
            {generalMonthArray.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid lg={3} xs={3} style={{ backgroundColor: '' }}>
          <div style={{ width: 'max-content', display: 'flex', alignItems: 'center', fontSize: '11px' }}>
            <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '13px' }}>
              Total Interventions:
            </Typography>
            <Typography style={{ marginLeft: '5px', fontSize: '12px' }}>
              {formatNumber(allPharmaciesOverallStats?.totalCompletedInterventions)}
            </Typography>

            <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '13px', marginLeft: '15px' }}>
              Total Target:
            </Typography>
            <Typography style={{ marginLeft: '5px', fontSize: '12px' }}>{formatNumber(allPharmaciesOverallStats?.totalTarget)}</Typography>

            <Typography variant="h6" style={{ fontWeight: 'bold', fontSize: '13px', marginLeft: '15px' }}>
              Overall Status:
            </Typography>
            <Typography style={{ marginLeft: '5px', fontSize: '12px' }}>{allPharmaciesOverallStats?.overallPercentage}</Typography>
          </div>
        </Grid>

        {/*<Grid lg={3} xs={3} style={{ backgroundColor: '', width: '100%' }}>*/}
        {/*  <Tooltip title="Date From">*/}
        {/*    <div style={{ display: 'flex' }}>*/}
        {/*      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">*/}
        {/*        <DatePicker*/}
        {/*          value={searchCreteria.from === '' ? null : searchCreteria.from}*/}
        {/*          // className="clock"*/}
        {/*          format="DD/MM/YYYY"*/}
        {/*          onChange={newValue => handledateFrom(newValue)}*/}
        {/*          sx={{ marginTop: 0, marginLeft: 0, border: '', width: '90%', height: '80%' }}*/}
        {/*        />*/}
        {/*      </LocalizationProvider>*/}
        {/*    </div>*/}
        {/*  </Tooltip>*/}
        {/*</Grid>*/}

        {/*<Grid lg={3} xs={3} style={{ backgroundColor: '', width: '100%' }}>*/}
        {/*  <Tooltip title="Date Till">*/}
        {/*    <div style={{ display: 'flex', marginLeft: '-6%' }}>*/}
        {/*      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">*/}
        {/*        <DatePicker*/}
        {/*          value={searchCreteria.to === '' ? null : searchCreteria.to}*/}
        {/*          className="clock"*/}
        {/*          format="DD/MM/YYYY"*/}
        {/*          onChange={newValue => handledateTill(newValue)}*/}
        {/*          sx={{ marginTop: 0, marginLeft: 0, border: 'black', width: '90%', height: '80%' }}*/}
        {/*        />*/}
        {/*      </LocalizationProvider>*/}
        {/*    </div>*/}
        {/*  </Tooltip>*/}
        {/*</Grid>*/}
        {/*<Tooltip title="Total NMS by pharmacy">*/}
        {/*  <IconButton*/}
        {/*    onClick={() => handleExcelPharmacyNMSRecords()}*/}
        {/*    style={{*/}
        {/*      backgroundColor: '#7CB6F8',*/}
        {/*      marginLeft: 5,*/}
        {/*      height: 35,*/}
        {/*      width: 35,*/}
        {/*      padding: '0px',*/}
        {/*      justifyContent: 'center',*/}
        {/*      borderRadius: 5,*/}
        {/*      cursor: 'pointer',*/}
        {/*      transition: 'background-color 0.3s',*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <img src={'../../../../content/assets/download2.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />*/}
        {/*  </IconButton>*/}
        {/*</Tooltip>*/}

        <Grid lg={4} xs={4} style={{ backgroundColor: '', display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title="Refresh">
            <IconButton
              onClick={handleRefresh}
              style={{
                backgroundColor: '#7CB6F8',
                marginLeft: 5,
                height: 35,
                width: 35,
                padding: '0px',
                justifyContent: 'center',
                borderRadius: 5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              <img src={'../../../../content/assets/refresh.png'} alt="logo" style={{ height: '17px', marginRight: 0, marginLeft: 0 }} />
            </IconButton>
          </Tooltip>
          {loadingDownload ? (
            <CircularProgress />
          ) : (
            <Tooltip title="Download">
              <IconButton
                onClick={() => getDownloadData()}
                style={{
                  backgroundColor: '#7CB6F8',
                  marginLeft: 5,
                  height: 35,
                  width: 35,
                  padding: '0px',
                  justifyContent: 'center',
                  borderRadius: 5,
                  cursor: 'pointer',
                  transition: 'background-color 0.3s',
                }}
              >
                <img
                  src={'../../../../content/assets/download2.png'}
                  alt="logo"
                  style={{ height: '17px', marginRight: 0, marginLeft: 0 }}
                />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>

      <Grid lg={11.8} xs={12} container style={{ backgroundColor: 'white' }}>
        <Grid lg={12} xs={12} item style={{ height: 30, marginTop: 5 }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <FormControl sx={{ width: '100%', height: '11%' }}>
              <OutlinedInput
                style={{ borderRadius: 50, height: '100%', width: '100%' }}
                placeholder="Search here"
                onChange={handleSearchChange}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton color="inherit" style={{ height: 30, width: 30 }}>
                      <img
                        src={'../../../../content/assets/search-alt-svgrepo-com (1).png'} // Use the image URL from the array
                        alt="logo"
                        style={{ height: '15px' }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{ style: { fontSize: 13 } }}
                classes={{ notchedOutline: 'white-outline' }}
              />
            </FormControl>
          </div>
        </Grid>

        {/*<RichDataTable*/}
        {/*  limit={limit}*/}
        {/*  page={pageNo}*/}
        {/*  count={totalCount}*/}
        {/*  handleChangePage={handleChangePage}*/}
        {/*  handleChangeRowsPerPage={handleChangeRowsPerPage}*/}
        {/*  emptyRowCount={emptyRowCount}*/}
        {/*  emptyCellCount={emptyCellCount}*/}
        {/*  dataIndexs={['index', 'pharmacyName', 'completedNMS', 'target', 'status', 'performance']}*/}
        {/*  dataSource={allPharmaciesListing}*/}
        {/*  headers={['ID', 'Pharmacy Name', 'Completed NMS', 'Target', 'Status', 'Performance']}*/}
        {/*  showEditDeletButton={false}*/}
        {/*  showConsentIcon={false}*/}
        {/*/>*/}
        <Paper sx={{ width: '100%', paddingTop: '15px', border: 'none' }}>
          <TableContainer sx={{ height: '54vh', border: 'none' }}>
            <Table stickyHeader aria-label="sticky table" style={{ border: 'none', borderTop: '0.1px solid #dadce1' }}>
              <TableHead sx={{ border: 'none' }}>
                <TableRow sx={{ border: 'none' }}>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Pharmacy Name
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Completed Interventions
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Target
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      height: 30,
                      fontFamily: 'Roboto, sans-serif',
                      paddingLeft: '40px',
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '10%',
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Performance
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Pending Follow-ups
                  </TableCell>
                  <TableCell
                    sx={{
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    Completed Follow-ups
                  </TableCell>
                  <TableCell
                    sx={{
                      width: '5%',
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                      backgroundColor: '#F5F6FA',
                      fontSize: 13,
                      fontWeight: 'bold',
                      fontFamily: 'Roboto, sans-serif',
                    }}
                  >
                    <IconButton onClick={handleOpenMenu}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                      {columns.map(col => (
                        <MenuItem key={col.key}>
                          <FormControlLabel
                            control={
                              <Checkbox checked={selectedColumns.includes(col.name)} onChange={() => handleCheckboxChange(col.name)} />
                            }
                            label={col.name}
                          />
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPharmaciesListing &&
                  allPharmaciesListing?.map((item, index) => (
                    <TableRow key={index} hover role="complementary" style={{ height: 50 }}>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {Number(index) + Number(1)}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.pharmacyName}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.completedInterventions}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.target}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                          paddingLeft: '40px',
                        }}
                      >
                        {item?.status}
                      </TableCell>
                      <TableCell
                        style={{
                          color:
                            item?.performance === 'Excellent'
                              ? '#00B10C'
                              : item?.performance === 'Good'
                              ? '#FFD966'
                              : item?.performance === 'Fair'
                              ? '#F4B084'
                              : item?.performance === 'Average'
                              ? '#FFC000'
                              : item?.performance === 'Extremely Low'
                              ? '#FF0000'
                              : '#FFFFFF',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {item?.performance}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {item?.pending_follow_ups}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      >
                        {item?.completed_follow_ups}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    </TableRow>
                  ))}
                {Array.from({ length: emptyRowCount }).map((_, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    hover
                    role="complementary"
                    style={{
                      height: 50,
                      border: 'none',
                      borderTop: '0.1px solid #dadce1',
                      borderBottom: '0.1px solid #dadce1',
                    }}
                  >
                    {Array.from({ length: emptyCellCount }).map((_, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        style={{
                          backgroundColor: '',
                          fontSize: 12,
                          fontFamily: 'Roboto, sans-serif',
                        }}
                      ></TableCell>
                    ))}
                    <TableCell />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ fontSize: '13px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', paddingRight: '20px', height: '12%' }}
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={limit}
            page={pageNo}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={
              <h1 style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 20, fontFamily: 'Roboto, sans-serif' }}>Rows per page:</h1>
            }
            labelDisplayedRows={({ from, to, count }) => (
              <div data-page={pageNo} style={{ fontSize: '13px', fontWeight: 'bold', marginTop: 15, fontFamily: 'Roboto, sans-serif' }}>
                {`${from}        -      ${to}        of       ${count}`}
              </div>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
export default AdminStoreTotalTab;
