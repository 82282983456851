import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Button,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Modal,
  InputAdornment,
  FormHelperText,
  FormControl,
} from '@mui/material';
import './set-password.scss';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useAppDispatch } from 'app/config/store';
import { forgotUserPassword, setUserPassword } from 'app/shared/reducers/authentication';
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { KeyboardArrowRight } from '@mui/icons-material';

const ForgotPasswordModal = props => {
  const [initialState, setInitalState] = useState({ username: '' });
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validationSchema = () => {
    return yup.object().shape({
      username: yup.string().required('Username is required'),
    });
  };

  const onSubmit = useCallback(async params => {
    const { username } = params;
    const data = {
      username,
    };
    const res = await dispatch(forgotUserPassword(data));
    if (res.type === 'authentication/forgot_password/fulfilled') {
      toast.success('Password reset link sent to your email please check.');
      navigate('/login');
    }
  }, []);
  const back = () => {
    navigate('/login');
  };
  return (
    <Grid
      container
      className={'overlay-background'}
      sx={{
        height: '100vh',
        backgroundImage: `url('../../../content/logos/login-bg-2.png')`, // Ensure the URL is correct
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        className={'form-background'}
        sx={{
          backgroundImage: `url('../../../content/images/shape-2.png')`, // Ensure the URL is correct
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Box style={{ position: 'absolute', top: 0, left: 0, padding: '10px', cursor: 'pointer' }}>
              <ArrowBackIcon onClick={back} />
            </Box>
            <img src={'../../../content/logos/final-logo-02.png'} alt="Logo" style={{ height: 150, width: 150, marginBottom: 24 }} />
            <Typography component="h1" fontWeight={'600'} variant="h6">
              Change your Password
            </Typography>
            <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => {
                return (
                  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                    <TextField
                      margin="normal"
                      required
                      autoFocus
                      fullWidth
                      type={'text'}
                      id="username"
                      label="Username"
                      name="username"
                      value={values.password}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.username && Boolean(errors.username)}
                    />
                    {touched.username && <FormHelperText error={true}>{(errors as any).username}</FormHelperText>}
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={'button-height'}
                      sx={{ mt: 2, mb: 1, position: 'relative' }}
                      type={'submit'}
                    >
                      Submit
                      <KeyboardArrowRight sx={{ position: 'absolute', right: 16 }} />
                    </Button>
                  </Box>
                );
              }}
            </Formik>
          </Box>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordModal;
