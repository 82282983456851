import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionPharmacistAgreedAction from './session-pharmacist-agreed-action';
import SessionPharmacistAgreedActionDetail from './session-pharmacist-agreed-action-detail';
import SessionPharmacistAgreedActionUpdate from './session-pharmacist-agreed-action-update';
import SessionPharmacistAgreedActionDeleteDialog from './session-pharmacist-agreed-action-delete-dialog';

const SessionPharmacistAgreedActionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionPharmacistAgreedAction />} />
    <Route path="new" element={<SessionPharmacistAgreedActionUpdate />} />
    <Route path=":id">
      <Route index element={<SessionPharmacistAgreedActionDetail />} />
      <Route path="edit" element={<SessionPharmacistAgreedActionUpdate />} />
      <Route path="delete" element={<SessionPharmacistAgreedActionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionPharmacistAgreedActionRoutes;
