import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './session.reducer';

export const SessionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const sessionEntity = useAppSelector(state => state.session.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="sessionDetailsHeading">
          <Translate contentKey="pharmacySolutionsApp.session.detail.title">Session</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{sessionEntity.id}</dd>
          <dt>
            <span id="consent">
              <Translate contentKey="pharmacySolutionsApp.session.consent">Consent</Translate>
            </span>
          </dt>
          <dd>{sessionEntity.consent ? 'true' : 'false'}</dd>
          <dt>
            <span id="previouslyDelivered">
              <Translate contentKey="pharmacySolutionsApp.session.previouslyDelivered">Previously Delivered</Translate>
            </span>
          </dt>
          <dd>{sessionEntity.previouslyDelivered ? 'true' : 'false'}</dd>
          <dt>
            <span id="medicineUsedAsPrescribed">
              <Translate contentKey="pharmacySolutionsApp.session.medicineUsedAsPrescribed">Medicine Used As Prescribed</Translate>
            </span>
          </dt>
          <dd>{sessionEntity.medicineUsedAsPrescribed ? 'true' : 'false'}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.service">Service</Translate>
          </dt>
          <dd>{sessionEntity.service ? sessionEntity.service.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.sessionConsultationMethod">Session Consultation Method</Translate>
          </dt>
          <dd>{sessionEntity.sessionConsultationMethod ? sessionEntity.sessionConsultationMethod.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.sessionStatus">Session Status</Translate>
          </dt>
          <dd>{sessionEntity.sessionStatus ? sessionEntity.sessionStatus.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.sessionType">Session Type</Translate>
          </dt>
          <dd>{sessionEntity.sessionType ? sessionEntity.sessionType.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.pharmacy">Pharmacy</Translate>
          </dt>
          <dd>{sessionEntity.pharmacy ? sessionEntity.pharmacy.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.gP">G P</Translate>
          </dt>
          <dd>{sessionEntity.gP ? sessionEntity.gP.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.prescription">Prescription</Translate>
          </dt>
          <dd>{sessionEntity.prescription ? sessionEntity.prescription.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.sessionPreviouslyDeliveredNoType">
              Session Previously Delivered No Type
            </Translate>
          </dt>
          <dd>{sessionEntity.sessionPreviouslyDeliveredNoType ? sessionEntity.sessionPreviouslyDeliveredNoType.id : ''}</dd>
          <dt>
            <Translate contentKey="pharmacySolutionsApp.session.sessionMedicineUsedAsPrescribedNoType">
              Session Medicine Used As Prescribed No Type
            </Translate>
          </dt>
          <dd>{sessionEntity.sessionMedicineUsedAsPrescribedNoType ? sessionEntity.sessionMedicineUsedAsPrescribedNoType.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/session" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/session/${sessionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SessionDetail;
