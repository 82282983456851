import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SessionPatientProvidedInformation from './session-patient-provided-information';
import SessionPatientProvidedInformationDetail from './session-patient-provided-information-detail';
import SessionPatientProvidedInformationUpdate from './session-patient-provided-information-update';
import SessionPatientProvidedInformationDeleteDialog from './session-patient-provided-information-delete-dialog';

const SessionPatientProvidedInformationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SessionPatientProvidedInformation />} />
    <Route path="new" element={<SessionPatientProvidedInformationUpdate />} />
    <Route path=":id">
      <Route index element={<SessionPatientProvidedInformationDetail />} />
      <Route path="edit" element={<SessionPatientProvidedInformationUpdate />} />
      <Route path="delete" element={<SessionPatientProvidedInformationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SessionPatientProvidedInformationRoutes;
