import { IAppUser } from 'app/shared/model/app-user.model';
import { ISession } from 'app/shared/model/session.model';

export interface IPharmacy {
  id?: number;
  name?: string | null;
  address?: string | null;
  appUsers?: IAppUser[] | null;
  sessions?: ISession[] | null;
}
export interface IPharmacyBranch {
  id?: number;
  name?: string | null;
}

export const defaultValue: Readonly<IPharmacy> = {};
